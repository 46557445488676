import React, { useRef } from 'react';

import { Avatar, AvatarProps } from '@mui/material';
import { useIntersectionObserver } from 'usehooks-ts';

export const LazyAvatar: React.FC<AvatarProps> = ({ src, ...rest }) => {
  const ref = useRef<HTMLDivElement | null>(null),
    isVisible = !!useIntersectionObserver(ref, { freezeOnceVisible: true })
      ?.isIntersecting;
  return <Avatar ref={ref} src={isVisible ? src : undefined} {...rest} />;
};
