import { Chip, alpha, lighten, useTheme } from '@mui/material';
import { blueGrey, brown, green, teal, yellow } from '@mui/material/colors';
import { ItemTagKey } from 'types';

export const ItemTagLabelsMap = {
  Boots: 'Boots',
  ManaRegen: 'Mana Regen',
  HealthRegen: 'Health Regen',
  Health: 'Max Health',
  CriticalStrike: 'Crit',
  SpellDamage: 'AP',
  Mana: 'Mana',
  Armor: 'Armor',
  SpellBlock: 'MR',
  LifeSteal: 'Life Steal',
  SpellVamp: 'Spell Vamp.',
  Jungle: 'Jungle',
  Damage: 'AD',
  Lane: 'Lane',
  AttackSpeed: 'Att. Speed',
  OnHit: 'On-hit',
  Trinket: 'Trinket',
  Active: 'Active',
  Consumable: 'Consumable',
  CooldownReduction: 'CDR',
  ArmorPenetration: 'Armor Pen',
  AbilityHaste: 'Haste',
  Stealth: 'Stealth',
  Vision: 'Vision',
  NonbootsMovement: 'Move Speed',
  Tenacity: 'Tenacity',
  MagicPenetration: 'Magic Pen',
  Aura: 'Aura',
  Slow: 'Slow',
  MagicResist: 'MR',
  GoldPer: 'Gold',
} as { [K in ItemTagKey]: string };

const useItemColors = () => {
  const { palette } = useTheme();
  return {
    Boots: brown[500],
    ManaRegen: palette.magic.main,
    HealthRegen: palette.health.main,
    Health: palette.health.main,
    CriticalStrike: palette.crit.main,
    SpellDamage: palette.ap.main,
    Mana: palette.magic.main,
    Armor: palette.physical.main,
    SpellBlock: palette.cc.main,
    LifeSteal: palette.crit.main,
    SpellVamp: palette.crit.main,
    Jungle: green[600],
    Damage: palette.physical.main,
    Lane: brown[500],
    AttackSpeed: blueGrey[300],
    OnHit: palette.crit.main,
    Trinket: yellow[300],
    Active: teal[300],
    Consumable: teal[300],
    CooldownReduction: blueGrey[300],
    ArmorPenetration: palette.physical.main,
    AbilityHaste: blueGrey[300],
    Stealth: blueGrey[300],
    Vision: yellow[300],
    NonbootsMovement: blueGrey[300],
    Tenacity: palette.cc.main,
    MagicPenetration: palette.ap.main,
    Aura: blueGrey[300],
    Slow: palette.cc.main,
    MagicResist: palette.magic.main,
    GoldPer: palette.gold.main,
  } as { [K in ItemTagKey]: string };
};

interface Props {
  tag: ItemTagKey;
  selected?: boolean;
  onClick?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tag: ItemTagKey,
  ) => void;
}

export const ItemTagChip: React.FC<Props> = ({ tag, selected, onClick }) => {
  const theme = useTheme(),
    colors = useItemColors(),
    enabled = selected === undefined || selected,
    color = colors[tag];

  let clickStyles = {};
  if (onClick !== undefined)
    clickStyles = {
      '&:hover': {
        cursor: 'pointer',
        borderColor: color,
        backgroundColor: color,
      },
    };

  return (
    <Chip
      size='small'
      label={ItemTagLabelsMap[tag]}
      variant='outlined'
      onClick={onClick ? (e) => onClick(e, tag) : undefined}
      clickable={false}
      sx={{
        ...theme.typography.caption,
        userSelect: onClick ? 'none' : 'unset',
        color: enabled
          ? theme.palette.text.primary
          : theme.palette.text.disabled,
        fontWeight: 500,
        [theme.getColorSchemeSelector('dark')]: {
          color: enabled ? lighten(color, 0.9) : theme.palette.text.disabled,
          fontWeight: 600,
        },
        backgroundColor: enabled ? alpha(color, 0.3) : undefined,
        border: `1px solid ${color}`,
        height: 20,
        ...clickStyles,
      }}
    />
  );
};
