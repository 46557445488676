import { KeyboardArrowUp } from '@mui/icons-material';
import { useTheme, Fade, useScrollTrigger, Fab } from '@mui/material';

interface ScrollTopFabProps {
  target?: Element | Window | null;
}

export const ScrollTopFab: React.FC<ScrollTopFabProps> = ({ target }) => {
  const theme = useTheme(),
    trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 200,
      target: target ?? undefined,
    });

  return (
    <Fade in={trigger}>
      <Fab
        size='small'
        color='primary'
        onClick={() =>
          (target ?? window).scrollTo({ top: 0, behavior: 'smooth' })
        }
        role='presentation'
        sx={{
          position: 'absolute',
          bottom: theme.spacing(1),
          left: theme.spacing(1),
        }}
      >
        <KeyboardArrowUp />
      </Fab>
    </Fade>
  );
};
