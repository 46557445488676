import React, { useRef, useState } from 'react';

import {
  Divider,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
  Box,
  useTheme,
} from '@mui/material';

import { DataV1Position } from 'api/statbud-api';
import {
  ChampionNameFilter,
  ChampionTagFilter,
  MasteryFilter,
  PositionFilter,
  ScrollTopFab,
} from 'components';
import { useChampionPositions, useDDragonData, useMasteries } from 'hooks';

import { MasteryItem } from './MasteryItem';

export const Mastery: React.FC = () => {
  const theme = useTheme(),
    ref = useRef<HTMLDivElement>(null),
    { masteries } = useMasteries(),
    { positions } = useChampionPositions(),
    { champions } = useDDragonData(),
    [championNameFilter, setChampionNameFilter] = useState<string[]>([]),
    [positionsFilter, setPositionsFilter] = useState<DataV1Position[]>([]),
    [tagsFilter, setTagsFilter] = useState<string[]>([]),
    [masteriesFilter, setMasteriesFilter] = useState<number[]>([]),
    filterByChampionName = (name?: string) =>
      name === undefined ||
      championNameFilter.length === 0 ||
      championNameFilter.reduce(
        (filter, value) =>
          filter ||
          name.trim().toLowerCase().includes(value.trim().toLowerCase()),
        false,
      ),
    filterByPositions = (positions?: DataV1Position[]) =>
      positions === undefined ||
      positionsFilter.length === 0 ||
      positionsFilter.reduce(
        (filtered, position) => filtered || positions.includes(position),
        false,
      ),
    filterByTags = (tags?: string[]) =>
      tags === undefined ||
      tagsFilter.length === 0 ||
      tagsFilter.reduce(
        (filtered, tag) => filtered || tags.includes(tag),
        false,
      ),
    filterByMasteryLevel = (championLevel: number) =>
      masteriesFilter.length === 0 ||
      masteriesFilter.reduce(
        (filtered, current) =>
          filtered || Math.min(10, championLevel) === current,
        false,
      );

  const filteredMasteries = masteries
    ?.sort(
      (
        { championLevel: aL, championPoints: aP },
        { championLevel: bL, championPoints: bP },
      ) => (aL === bL ? bP - aP : bL - aL),
    )
    .filter(({ championId, championLevel }) => {
      const champion = champions?.find(
        ({ key }) => key === championId.toString(),
      );
      return (
        champion !== undefined &&
        filterByChampionName(champion.name) &&
        filterByMasteryLevel(championLevel) &&
        filterByTags(champion.tags) &&
        filterByPositions(
          positions
            ?.filter(({ championId }) => champion.key === championId)
            .map(({ position }) => position),
        )
      );
    });

  const masteryCounts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  filteredMasteries?.forEach(
    ({ championLevel }) => masteryCounts[Math.min(10, championLevel) - 1]++,
  );

  return (
    <>
      <Box>
        <Box p={theme.spacing(2, 2, 1)}>
          <Typography variant='h5'>Champion Mastery</Typography>
          <ChampionNameFilter
            onChange={(_, value) => setChampionNameFilter(value)}
            sx={{ pt: 1 }}
          />
          <Stack spacing={2} pt={1}>
            <Stack
              direction='row'
              spacing={2}
              justifyContent='space-between'
              alignItems='center'
            >
              <PositionFilter
                onChange={(_, positions) =>
                  setPositionsFilter(positions as DataV1Position[])
                }
                hideNone
                flex={1}
                justifyContent='space-around'
              />
              <ChampionTagFilter
                onChange={(_, tags) => setTagsFilter(tags)}
                flex={1}
                justifyContent='space-around'
              />
            </Stack>
            <Box p={theme.spacing(0, 0.5)}>
              <MasteryFilter
                counts={masteryCounts}
                onChange={(_, masteries) => setMasteriesFilter(masteries)}
                flex={1}
                justifyContent='space-around'
                columnGap={2}
                rowGap={2.5}
              />
            </Box>
          </Stack>
        </Box>
        <Divider sx={{ m: (theme) => theme.spacing(2, 0, 0) }} />
      </Box>
      <Grid
        ref={ref}
        container
        spacing={2}
        sx={{ m: 0, p: 0.5, overflowY: 'auto' }}
      >
        {filteredMasteries?.map((mastery) => {
          const champion = champions?.find(
            ({ key }) => key === mastery.championId.toString(),
          );
          return (
            <Grid key={mastery.championId} xs={12} sm={6}>
              <Box
                sx={{
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: `${theme.shape.borderRadius}px`,
                }}
              >
                <MasteryItem champion={champion} mastery={mastery} />
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <ScrollTopFab target={ref.current} />
    </>
  );
};
