import { MatchV5ParticipantDto } from '@blakearoberts/riot-api-ts';
import { Badge, Stack, useTheme, Typography } from '@mui/material';

import { ChampionIcon, SpellIconGroup } from 'components';

const LevelBadge: React.FC<{ level?: number } & React.PropsWithChildren> = ({
  level,
  children,
}) => {
  const theme = useTheme();
  return (
    <Badge
      badgeContent={
        <Typography
          width='100%'
          height='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'
          variant='caption'
          fontSize='8.5px'
          fontWeight={600}
          color='text.primary'
          lineHeight={0}
          letterSpacing={0}
        >
          {level}
        </Typography>
      }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{
        '& .MuiBadge-badge': {
          width: 12,
          height: 12,
          borderRadius: '6px',
          minWidth: 0,
          right: 8,
          bottom: 11,
          padding: 0,
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
          zIndex: 0,
          pointerEvents: 'none',
        },
      }}
    >
      {children}
    </Badge>
  );
};

interface Props {
  participant: MatchV5ParticipantDto;
}

export const ChampionCell: React.FC<Props> = ({
  participant: { championId, champLevel, summoner1Id, summoner2Id },
}) => {
  const theme = useTheme();
  return (
    <Stack direction='row'>
      <LevelBadge level={champLevel}>
        <ChampionIcon
          championId={championId}
          enableLink
          AvatarProps={{
            sx: {
              lineHeight: 0,
              border: `1px solid ${theme.palette.divider}`,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
          }}
        />
      </LevelBadge>
      <SpellIconGroup
        id1={summoner1Id}
        id2={summoner2Id}
        sx={{
          '> .MuiAvatar-root': {
            borderLeftWidth: 0,
            '&:first-of-type': {
              borderTopLeftRadius: 0,
            },
            '&:last-of-type': {
              borderBottomLeftRadius: 0,
            },
          },
        }}
      />
    </Stack>
  );
};
