import { useState } from 'react';
import { useMatches, useNavigate, useParams } from 'react-router-dom';

import {
  Autocomplete,
  Box,
  IconButton,
  Stack,
  TextField,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { Delete, Search } from '@mui/icons-material';
import { useRiotId, useRiotIds } from 'hooks';

export const SummonerSearchBar: React.FC = () => {
  const theme = useTheme(),
    [riotId, setRiotId] = useRiotId(),
    [riotIds, setRiotIds] = useRiotIds(),
    [inputFocused, setInputFocused] = useState(false),
    matches = useMatches(),
    navigate = useNavigate(),
    params = useParams();

  return (
    <Box
      flex={1}
      display='flex'
      height='100%'
      alignItems='center'
      justifyContent='center'
    >
      <Autocomplete
        disablePortal
        freeSolo
        handleHomeEndKeys
        selectOnFocus
        openOnFocus
        blurOnSelect
        value={riotId}
        options={riotIds}
        onChange={(e, v) => {
          e.preventDefault();
          if (!v) return;

          let gameName: string, tagLine: string, puuid: string | undefined;
          if (typeof v === 'string') {
            const parts = v.trim().split('#');
            if (parts.length === 1) parts.push('NA1');
            gameName = parts[0];
            tagLine = parts[1];
          } else {
            gameName = v.gameName;
            tagLine = v.tagLine;
            puuid = v.puuid;
          }

          const match = matches.at(-1);
          if (match !== undefined && params.riotId !== undefined) {
            const parts = params.riotId.split('-');
            if (parts.length < 2) parts.push('NA1');
            navigate(
              match.pathname
                .replace(parts[0], gameName)
                .replace(parts[1], tagLine),
            );
          } else navigate(`/profile/na1/${gameName}-${tagLine}/history`);
          setRiotId({ gameName, tagLine, puuid });
        }}
        slotProps={{
          clearIndicator: {
            sx: {
              mr: 0.5,
              width: '26px',
              height: '26px',
            },
          },
        }}
        sx={{
          width: '100%',
          maxWidth: '900px',
          borderRadius: `${theme.shape.borderRadius}px`,
          backgroundColor: inputFocused
            ? alpha(theme.palette.common.white, 0.25)
            : alpha(theme.palette.common.white, 0.15),
          '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
          },
          '& .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root': {
            m: theme.spacing(0, 1),
          },
        }}
        getOptionLabel={(o) =>
          typeof o === 'string'
            ? o.includes('#')
              ? o
              : `${o}#NA1`
            : `${o.gameName}#${o.tagLine}`
        }
        renderOption={(props, option) => (
          <Stack direction='row' component='li' {...props}>
            <Typography flex={1}>
              {option.gameName}#{option.tagLine}
            </Typography>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setRiotIds([
                  ...riotIds.filter(({ puuid }) => puuid !== option.puuid),
                ]);
              }}
            >
              <Delete color='neutral' />
            </IconButton>
          </Stack>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Riot ID (name#tag)'
            variant='standard'
            InputProps={{
              ...params.InputProps,
              sx: { color: 'inherit' },
              startAdornment: <Search />,
              spellCheck: false,
              disableUnderline: true,
              onFocus: () => setInputFocused(true),
              onBlur: () => setInputFocused(false),
            }}
          />
        )}
      />
    </Box>
  );
};
