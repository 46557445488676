import { useNavigate, useParams } from 'react-router-dom';

import { Container, TreeChart } from 'd4';
import {
  Box,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { GoldAvatar, ItemAvatar, SVGItemIcon } from 'components';
import { useDDragonData } from 'hooks';

import { Header } from './Header';
import { ItemStatKey } from 'types';

const ItemStatLabelMap = {
  FlatMovementSpeedMod: 'move speed',
  FlatHPPoolMod: 'max health',
  FlatCritChanceMod: 'crit. chance',
  FlatMagicDamageMod: 'ability power',
  FlatMPPoolMod: 'max mana',
  FlatArmorMod: 'armor',
  FlatSpellBlockMod: 'magic resistance',
  FlatPhysicalDamageMod: 'attack damage',
  PercentAttackSpeedMod: 'attack speed',
  PercentLifeStealMod: 'life steal',
  FlatHPRegenMod: 'health per second',
  PercentMovementSpeedMod: 'move speed',
};

const ItemStatValueFormatMap = {
  FlatCritChanceMod: (v: number) =>
    v.toLocaleString(undefined, {
      style: 'percent',
      signDisplay: 'always',
    }),
  PercentAttackSpeedMod: (v: number) =>
    v.toLocaleString(undefined, {
      style: 'percent',
      signDisplay: 'always',
    }),
  PercentLifeStealMod: (v: number) =>
    v.toLocaleString(undefined, {
      style: 'percent',
      signDisplay: 'always',
    }),
  PercentMovementSpeedMod: (v: number) =>
    v.toLocaleString(undefined, {
      style: 'percent',
      signDisplay: 'always',
    }),
} as Partial<{ [k in ItemStatKey]: (v: number) => string }>;

export const Item: React.FC = () => {
  const theme = useTheme(),
    { version, items: ddItems } = useDDragonData(),
    { itemId } = useParams(),
    navigate = useNavigate(),
    item = itemId ? ddItems?.data[itemId] : undefined,
    nodeSize = 40,
    filter = (id: string) => {
      const item = ddItems?.data[id];
      if (item === undefined) return false;
      const {
        requiredAlly,
        requiredChampion,
        hideFromAll,
        gold: { purchasable },
        maps,
      } = item;
      return (
        requiredChampion === undefined &&
        !hideFromAll &&
        purchasable &&
        (maps['11'] || maps['12']) &&
        requiredAlly !== 'Ornn'
      );
    },
    items = Object.keys(ddItems?.data ?? {})
      .filter(filter)
      .map((id) => ({
        id,
        from: ddItems?.data[id].from?.filter(filter) ?? [],
      })),
    stats = Object.entries(item?.stats ?? {}),
    from = item?.from?.filter(filter).length ?? 0,
    into = item?.into?.filter(filter).length ?? 0;

  return (
    <Box
      sx={{
        p: theme.spacing(2, 2, 0),
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: 0,
      }}
    >
      <Header itemId={itemId} version={version} />
      <Box sx={{ flexShrink: 1, overflowY: 'auto' }}>
        <Typography variant='subtitle1'>{item?.plaintext}</Typography>

        {stats.length > 0 && (
          <List disablePadding sx={{ pt: 0.25 }}>
            {stats.map(([stat, value]) => (
              <ListItem key={stat} sx={{ p: theme.spacing(0, 0, 0.25, 2) }}>
                <Typography variant='body2'>
                  {(
                    ItemStatValueFormatMap[stat as ItemStatKey] ??
                    ((v: number) =>
                      v.toLocaleString(undefined, { signDisplay: 'always' }))
                  )(value)}{' '}
                  {ItemStatLabelMap[stat as ItemStatKey]}
                </Typography>
              </ListItem>
            ))}
          </List>
        )}
        <Divider sx={{ m: theme.spacing(1, 0) }} />

        {into > 0 && (
          <>
            <Typography variant='h5' sx={{ p: theme.spacing(1, 0) }}>
              Builds Into
            </Typography>
            <Box sx={{ overflow: 'hidden' }}>
              <Stack direction='row' spacing={2} sx={{ overflow: 'auto' }}>
                {item?.into?.filter(filter).map((id) => (
                  <Stack key={id} spacing={0.25} alignItems='center'>
                    <ItemAvatar
                      itemId={id}
                      version={version}
                      size={nodeSize}
                      link
                    />
                    <Stack direction='row' spacing={0.5} alignItems='center'>
                      <Typography variant='subtitle2' color='gold.main'>
                        {ddItems?.data[id].gold?.total.toLocaleString()}
                      </Typography>
                      <GoldAvatar size={12} />
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Box>
            <Divider sx={{ m: theme.spacing(1, 0) }} />
          </>
        )}

        {from > 0 && (
          <>
            <Typography variant='h5' sx={{ p: theme.spacing(1, 0) }}>
              Build Path
            </Typography>
            <Container
              data={items}
              padding={[
                (3 / 4) * nodeSize,
                (3 / 4) * nodeSize,
                (3 / 4) * nodeSize,
                (3 / 4) * nodeSize,
              ]}
            >
              <TreeChart<{
                id: string;
                from: string[];
              }>
                rootId={itemId}
                identifier={(d) => d?.id}
                descendants={(d) => d?.from}
                vertical={from > 1}
                reverse={from <= 1}
                node={(n) => (
                  <SVGItemIcon
                    style={{
                      outline:
                        n.data?.id === itemId
                          ? `1px solid ${theme.palette.text.disabled}`
                          : undefined,
                      borderRadius: theme.shape.borderRadius,
                    }}
                    itemId={n.data?.id}
                    size={nodeSize}
                    href={n.data?.id !== itemId ? n.data?.id : undefined}
                    onClick={
                      n.data?.id !== itemId
                        ? (e) => {
                            e.preventDefault();
                            if (n.data?.id !== itemId)
                              navigate(`../${n.data?.id}`, {
                                relative: 'path',
                              });
                          }
                        : undefined
                    }
                  />
                )}
              />
            </Container>
          </>
        )}
      </Box>
    </Box>
  );
};
