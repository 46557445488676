import { Stack, Box, Divider } from '@mui/material';

import { useMatch } from 'hooks';

import { GoldDiffChart } from './GoldDiffChart';
import { GoldStackChart } from './GoldStackChart';

interface Props {
  matchId?: string;
}

export const Timeline: React.FC<Props> = ({ matchId }) => {
  const { match } = useMatch(matchId);
  return (
    <Stack spacing={1}>
      <GoldDiffChart matchId={matchId} />
      <Box sx={{ pt: 1, pb: 1 }}>
        <Divider />
      </Box>
      <GoldStackChart
        matchId={matchId}
        participants={match?.info.participants}
      />
    </Stack>
  );
};
