import { useState } from 'react';

import { Autocomplete, Box, BoxProps, TextField } from '@mui/material';

import { useDDragonChampions } from 'hooks';

interface Props extends Omit<BoxProps, 'onChange'> {
  label?: string;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string[],
  ) => void;
}

export const ChampionNameFilter: React.FC<Props> = ({
  onChange,
  label,
  ...rest
}) => {
  const { champions, loading } = useDDragonChampions(),
    [value, setValue] = useState<string[]>([]);
  return (
    <Box {...rest}>
      <Autocomplete
        size='small'
        disablePortal
        multiple
        freeSolo
        autoSelect
        handleHomeEndKeys
        loading={loading}
        options={champions.map(({ name }) => name)}
        value={value}
        filterOptions={(options, state) => {
          return options.filter(
            (option) =>
              option
                .toLowerCase()
                .includes(state.inputValue.toLowerCase().trim()) &&
              value.reduce(
                (filter, value) => filter && !(value === option),
                true,
              ),
          );
        }}
        onChange={(e, value) => {
          setValue(value);
          if (onChange) onChange(e, value);
        }}
        onInputChange={(e, inputValue, reason) => {
          if (onChange && reason === 'input') {
            if (inputValue) onChange(e, [inputValue, ...value]);
            else onChange(e, value);
          }
        }}
        renderInput={(params) => (
          <TextField label={label ?? 'Champion Name'} {...params} />
        )}
      />
    </Box>
  );
};
