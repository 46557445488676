import React from 'react';

import { Box, Divider, Stack, useMediaQuery, useTheme } from '@mui/material';

import { ProfileHeader } from './ProfileHeader';
import { ProfileQueues } from './ProfileQueues';
import { LpTimeline } from './LpTimeline';

export const Profile: React.FC = () => {
  const theme = useTheme(),
    sm = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Stack
      sx={{
        flexGrow: 0,
        flexShrink: 1,
        minHeight: 0,
      }}
    >
      <ProfileHeader />
      <Box
        sx={{
          flexGrow: 1,
          flexBasis: '0%',
          minHeight: '125px',
          overflow: 'auto',
        }}
      >
        <Divider />
        <ProfileQueues />
        {sm ? <></> : <LpTimeline />}
      </Box>
    </Stack>
  );
};
