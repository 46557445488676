import { useId } from 'react';

import * as d3 from 'd3';

import { Chart } from './Chart';
import { ChartContextProvider, Datum, useChart } from './context';

interface Props<D extends Datum> extends React.PropsWithChildren {
  series?: (d: D) => string;
  accessors: [(d: D) => number, (d: D) => number] | ((d: D) => number)[];
  domain?: [number, number];
  color?: string | ((d: D) => string);
  curve?: d3.CurveFactory | d3.CurveFactoryLineOnly;
  point?: (d: D) => JSX.Element;
  fill?: string | ['min' | 'max' | number, string, number?][];
}

export const LineChart = <D extends Datum>({
  series,
  accessors,
  domain,
  color,
  curve,
  point,
  fill,
  children,
}: Props<D>) => {
  const { width, height, padding, data, ...rest } = useChart<D>(),
    x = d3
      .scaleLinear()
      .domain(d3.extent(data, accessors[0]) as [number, number])
      .range([padding[3], width - padding[1]]),
    y = d3
      .scaleLinear()
      .domain(domain ?? (d3.extent(data, accessors[1]) as [number, number]))
      .range([height - padding[2], padding[0]]),
    line = d3
      .line<D>()
      .x((d) => x(accessors[0](d)))
      .y((d) => y(accessors[1](d)))
      .curve(curve ?? d3.curveMonotoneX),
    offset =
      typeof window !== 'undefined' && window.devicePixelRatio > 1 ? 0 : 0.5,
    id = useId(),
    area = d3
      .area<D>()
      .x((d) => x(accessors[0](d)))
      .y0(y(0))
      .y1((d) => y(accessors[1](d)))
      .curve((curve as d3.CurveFactory) ?? d3.curveMonotoneX);

  return (
    <ChartContextProvider
      value={{
        ...rest,
        width,
        height,
        padding,
        data,
        x,
        y,
      }}
    >
      <Chart>
        {children}
        <g data-chart-component='chart' data-chart-type='line'>
          {(series ? Array.from(d3.group(data, series).values()) : [data]).map(
            (s, i) => (
              <g
                key={series ? series(s[0]) : i}
                data-chart-component='series'
                data-series={series ? series(s[0]) : undefined}
              >
                {fill && (
                  <>
                    <path
                      d={area(s) ?? undefined}
                      fill={
                        typeof fill === 'string'
                          ? fill
                          : `url(#${id}${series ? '-' + series(s[0]) : ''})`
                      }
                    />
                    {typeof fill !== 'string' && (
                      <defs>
                        <linearGradient
                          id={`${id}${series ? '-' + series(s[0]) : ''}`}
                          gradientTransform='rotate(90)'
                          spreadMethod='repeat'
                        >
                          {fill.map(([offset, color, opacity], i) => (
                            <stop
                              key={i}
                              offset={
                                offset === 'min'
                                  ? 1
                                  : offset === 'max'
                                  ? 0
                                  : y.domain().every((v) => !isNaN(v))
                                  ? (offset - y.domain()[1]) /
                                    (y.domain()[0] - y.domain()[1])
                                  : 0
                              }
                              stopColor={color}
                              stopOpacity={opacity}
                            />
                          ))}
                        </linearGradient>
                      </defs>
                    )}
                  </>
                )}
                <path
                  d={line(s) ?? undefined}
                  fill='none'
                  stroke={
                    color
                      ? typeof color === 'string'
                        ? color
                        : color(s[0])
                      : 'currentColor'
                  }
                  strokeWidth={1 + offset}
                />
                {point &&
                  s.map((d, j) => (
                    <g
                      key={j}
                      transform={`translate(${x(accessors[0](d)) + offset} ${y(
                        accessors[1](d),
                      )})`}
                    >
                      {point(d)}
                    </g>
                  ))}
              </g>
            ),
          )}
        </g>
      </Chart>
    </ChartContextProvider>
  );
};
