/**
 * StatBud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DataV1Position } from '../models/DataV1Position';
import { HttpFile } from '../http/http';

/**
* This object contains stats for a single champion.
*/
export class StatbudV1ChampionStatsDto {
    /**
    * Champion ID for this entry.
    */
    'championId': string;
    'position': DataV1Position;
    /**
    * Number of games won with this champion.
    */
    'wins': number;
    /**
    * Number of games lost with this champion.
    */
    'losses': number;
    /**
    * Number of kills with this champion.
    */
    'kills': number;
    /**
    * Number of deaths with this champion.
    */
    'deaths': number;
    /**
    * Number of assists with this champion.
    */
    'assists': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "championId",
            "baseName": "championId",
            "type": "string",
            "format": ""
        },
        {
            "name": "position",
            "baseName": "position",
            "type": "DataV1Position",
            "format": ""
        },
        {
            "name": "wins",
            "baseName": "wins",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "losses",
            "baseName": "losses",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "kills",
            "baseName": "kills",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "deaths",
            "baseName": "deaths",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "assists",
            "baseName": "assists",
            "type": "number",
            "format": "int64"
        }    ];

    static getAttributeTypeMap() {
        return StatbudV1ChampionStatsDto.attributeTypeMap;
    }

    public constructor() {
    }
}



