interface ChallengeReward {
  category: string;
  quantity: number;
  title: string;
}

export enum ChallengeLevel {
  IRON = 'IRON',
  BRONZE = 'BRONZE',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
  DIAMOND = 'DIAMOND',
  MASTER = 'MASTER',
  GRANDMASTER = 'GRANDMASTER',
  CHALLENGER = 'CHALLENGER',
}

interface ChallengeThreshold {
  value: number;
  rewards?: ChallengeReward[];
}

export interface Challenge {
  id: number;
  name: string;
  description: string;
  shortDescription: string;
  hasLeaderboard: boolean;
  levelToIconPath: { [k in ChallengeLevel]: string };
  thresholds: { [k in ChallengeLevel]: ChallengeThreshold };
}

export interface ChampionDetailsSimple {
  version: string;
  id: string;
  key: string;
  name: string;
  title: string;
  blurb: string;
  info: any;
  image: Image;
  tags: string[];
  partype: string;
  stats: any;
}

interface Image {
  full: string;
  group: string;
  sprite: string;
  w: number;
  h: number;
  x: number;
  y: number;
}

interface Passive {
  description: string;
  name: string;
  image: Image;
}

interface ChampionSpell {
  cooldown: number[];
  cooldownBurn: string;
  cost: number[];
  costBurn: string;
  costType: string;
  datavalues: any;
  description: string;
  effect: (number[] | null)[];
  effectBurn: (number | null)[];
  id: string;
  image: Image;
  leveltip: { label: string[]; effect: string[] };
  maxammo: string;
  maxrank: number;
  name: string;
  range: number[];
  rangeBurn: string;
  resource: string;
  tooltip: string;
  vars: [];
}

interface ChampionDetailsComplex extends ChampionDetailsSimple {
  allytips: string[];
  enemytips: string[];
  lore: string;
  passive: Passive;
  recommended: [];
  skins: [];
  spells: ChampionSpell[];
}

export interface Champions {
  type: string;
  format: string;
  version: string;
  data: { [k: string]: ChampionDetailsSimple };
}

export interface Champion {
  type: string;
  format: string;
  version: string;
  data: { [k: string]: ChampionDetailsComplex };
}

export type ItemTagKey =
  | 'Boots'
  | 'ManaRegen'
  | 'HealthRegen'
  | 'Health'
  | 'CriticalStrike'
  | 'SpellDamage'
  | 'Mana'
  | 'Armor'
  | 'SpellBlock'
  | 'LifeSteal'
  | 'SpellVamp'
  | 'Jungle'
  | 'Damage'
  | 'Lane'
  | 'AttackSpeed'
  | 'OnHit'
  | 'Trinket'
  | 'Active'
  | 'Consumable'
  | 'CooldownReduction'
  | 'ArmorPenetration'
  | 'AbilityHaste'
  | 'Stealth'
  | 'Vision'
  | 'NonbootsMovement'
  | 'Tenacity'
  | 'MagicPenetration'
  | 'Aura'
  | 'Slow'
  | 'MagicResist'
  | 'GoldPer';

export type ItemStatKey =
  | 'FlatMovementSpeedMod'
  | 'FlatHPPoolMod'
  | 'FlatCritChanceMod'
  | 'FlatMagicDamageMod'
  | 'FlatMPPoolMod'
  | 'FlatArmorMod'
  | 'FlatSpellBlockMod'
  | 'FlatPhysicalDamageMod'
  | 'PercentAttackSpeedMod'
  | 'PercentLifeStealMod'
  | 'FlatHPRegenMod'
  | 'PercentMovementSpeedMod';

export interface Item {
  name: string;
  description: string;
  plaintext: string;
  requiredChampion?: string;
  requiredAlly?: string;
  hideFromAll?: boolean;
  gold: {
    base: number;
    purchasable: boolean;
    total: number;
    sell: number;
  };
  tags: ItemTagKey[];
  from?: string[];
  into?: string[];
  image: Image;
  maps: { [k: string]: boolean };
  stats: Partial<{ [k in ItemStatKey]: number }>;
}

export interface Items {
  type: string;
  version: string;
  basic: any;
  data: { [k: string]: Item };
}

interface Rune {
  id: number;
  key: string;
  icon: string;
  name: string;
  shortDesc: string;
  longDesc: string;
}

export interface RuneStyle {
  id: number;
  key: string;
  icon: string;
  name: string;
  slots: { runes: Rune[] }[];
}

interface SummonerSpell {
  id: string;
  name: string;
  description: string;
  tooltip: string;
  maxrank: number;
  cooldown: number[];
  cooldownBurn: string;
  cost: number[];
  costBurn: string;
  datavalues: any;
  effect: (number[] | null)[];
  effectBurn: (string | null)[];
  vars: any[];
  key: string;
  summonerLevel: number;
  modes: string[];
  costType: string;
  maxammo: string;
  range: number[];
  rangeBurn: string;
  image: Image;
  resource: string;
}

export interface SummonerSpells {
  type: string;
  version: string;
  data: { [k: string]: SummonerSpell };
}
