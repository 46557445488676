import React from 'react';

import {
  Avatar,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { blue, grey, red } from '@mui/material/colors';

import { DataV1Position } from 'api/statbud-api';
import { PositionIcon } from 'components';
import { formatDate, formatMatchDuration, formatTime } from 'helpers';
import { useAccount, useLpDelta, useMatch } from 'hooks';
import { MatchQueues, MatchQueuesXS } from 'types';

interface Props {
  matchId?: string;
}

export const MatchItemHeader: React.FC<Props> = ({ matchId }) => {
  const theme = useTheme(),
    xs = useMediaQuery(theme.breakpoints.down('sm')),
    xxs = useMediaQuery('(max-width:400px)'),
    { account } = useAccount(),
    { match } = useMatch(matchId),
    queueId = match?.info.queueId ?? 0,
    participant = match?.info.participants.find(
      ({ puuid }) => puuid === account?.puuid,
    ),
    position =
      (participant?.teamPosition ?? '').length > 0
        ? (participant?.teamPosition as DataV1Position)
        : participant?.summoner1Id === 11 || participant?.summoner2Id === 11
        ? DataV1Position.Jungle
        : DataV1Position.None,
    duration = match?.info.gameDuration ?? 0,
    date = new Date(match?.info.gameStartTimestamp ?? 0),
    lpDelta = useLpDelta(matchId);

  return (
    <Stack direction='row'>
      <Stack
        flex={1}
        direction='row'
        divider={<Typography>&#x2022;</Typography>}
        spacing={1}
        alignItems='center'
      >
        <Typography>
          {matchId ? (
            <>{xs ? MatchQueuesXS.get(queueId) : MatchQueues.get(queueId)}</>
          ) : (
            <Skeleton width={80} />
          )}
        </Typography>
        <Avatar sx={{ bgcolor: 'unset', width: 24, height: 24 }} alt={position}>
          <PositionIcon
            position={position}
            htmlColor={theme.palette.text.primary}
          />
        </Avatar>
        {!xs && (
          <Typography>
            {matchId ? formatMatchDuration(duration) : <Skeleton width={50} />}
          </Typography>
        )}
        {lpDelta !== undefined ? (
          <Typography
            variant='body2'
            color={lpDelta > 0 ? blue[300] : lpDelta < 0 ? red[300] : grey[500]}
          >
            {lpDelta.toLocaleString(undefined, { signDisplay: 'exceptZero' })}{' '}
            LP
          </Typography>
        ) : undefined}
      </Stack>

      {matchId ? (
        <Typography textAlign='right'>
          {formatDate(date, xxs ? 'small' : xs ? 'medium' : 'large')}
          {!xxs ? <> &#x2022; </> : <></>}
          {formatTime(date)}
        </Typography>
      ) : (
        <Skeleton width={xxs ? 0 : xs ? 80 : 160} />
      )}
    </Stack>
  );
};
