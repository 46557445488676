import { Menu } from '@mui/icons-material';
import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  IconButton,
  Stack,
  Box,
  useTheme,
} from '@mui/material';

import { SettingsMenu } from './SettingsMenu';
import { SummonerSearchBar } from './SummonerSearchBar';

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppBar: React.FC<Props> = ({ setOpen }) => {
  const theme = useTheme();
  return (
    <MuiAppBar sx={{ zIndex: theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Stack direction='row' alignItems='center' spacing={2} width='100%'>
          <Stack
            direction='row'
            alignItems='center'
            sx={{
              [theme.breakpoints.up('lg')]: {
                ...theme.mixins.drawer.nav,
              },
            }}
          >
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              sx={{ display: { lg: 'none' } }}
              onClick={() => setOpen(true)}
            >
              <Menu />
            </IconButton>
            <Typography
              variant='h6'
              noWrap
              component='a'
              href='/'
              sx={{
                display: { xs: 'none', md: 'block' },
                pr: 1,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              StatBud
            </Typography>
          </Stack>

          <SummonerSearchBar />

          <Box
            sx={{
              [theme.breakpoints.up('md')]: {
                ...theme.mixins.drawer.context,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
              },
            }}
          >
            <SettingsMenu />
          </Box>
        </Stack>
      </Toolbar>
    </MuiAppBar>
  );
};
