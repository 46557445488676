import { Avatar, AvatarProps, Skeleton } from '@mui/material';
import { useDDragonData } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';

interface ItemAvatarProps extends AvatarProps {
  itemId?: string;
  version?: string;
  size?: number;
  link?: boolean;
  variant?: 'circular' | 'rounded' | 'square';
}

export const ItemAvatar: React.FC<ItemAvatarProps> = ({
  itemId,
  version,
  size,
  link,
  variant,
  ...props
}) => {
  const { items } = useDDragonData(version),
    { itemId: pathId } = useParams(),
    navigate = useNavigate(),
    item = items?.data[itemId ?? ''],
    href = link && itemId ? '/data/items/' + itemId : undefined,
    { sx, ...rest } = props;
  size ??= 64;

  const avatar = (
    <Avatar
      variant={variant ?? 'rounded'}
      alt={item?.name}
      src={
        version && itemId
          ? `https://ddragon.leagueoflegends.com/cdn/${version}/img/item/${itemId}.png`
          : undefined
      }
      sx={{ width: size, height: size, ...sx }}
      onClick={
        link
          ? (e) => {
              e.preventDefault();
              if (itemId !== pathId) navigate('/data/items/' + itemId);
            }
          : undefined
      }
      {...rest}
    />
  );

  return version ? (
    href !== undefined ? (
      <a href={href} target='_blank' rel='noreferrer'>
        {avatar}
      </a>
    ) : (
      avatar
    )
  ) : (
    <Skeleton
      width={size}
      height={size}
      variant={variant === 'square' ? 'rectangular' : variant ?? 'rounded'}
      component={'div'}
    />
  );
};
