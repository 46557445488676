import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ChampionMasteryV4ChampionMasteryDto } from '@blakearoberts/riot-api-ts';
import {
  AvatarProps,
  Badge,
  Box,
  Card,
  CardActionArea,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';

import { LazyAvatar, MasteryAvatar, SVGMarkIcon } from 'components';
import { ChampionDetailsSimple } from 'types';
import { useDDragonData } from 'hooks';

interface SkeletonTypographyProps
  extends TypographyProps<
    React.ElementType<any>,
    { component?: React.ElementType<any> }
  > {
  loading: boolean;
}

const SkeletonTypography: React.FC<SkeletonTypographyProps> = ({
  loading,
  variant,
  sx,
  ...rest
}) => {
  const theme = useTheme();
  return loading ? (
    <Skeleton
      sx={{
        ...theme.typography[
          variant && variant !== 'inherit' ? variant : 'body1'
        ],
        width: '70%',
        ...sx,
      }}
    />
  ) : (
    <Typography variant={variant} sx={sx} {...rest} />
  );
};

interface SkeletonAvatarProps extends AvatarProps {
  loading: boolean;
  width?: string | number;
  height?: string | number;
  sWidth?: string | number;
  sHeight?: string | number;
}

const SkeletonAvatar: React.FC<SkeletonAvatarProps> = ({
  loading,
  width,
  height,
  sWidth,
  sHeight,
  variant,
  sx,
  ...rest
}) => {
  return loading ? (
    <Skeleton
      variant={variant === 'square' ? 'rectangular' : variant}
      width={sWidth !== undefined ? sWidth : width}
      height={sHeight !== undefined ? sHeight : height}
    />
  ) : (
    <LazyAvatar variant={variant} sx={{ width, height, ...sx }} {...rest} />
  );
};

interface Props {
  champion?: ChampionDetailsSimple;
  mastery?: ChampionMasteryV4ChampionMasteryDto;
}

export const MasteryItem: React.FC<Props> = ({ champion, mastery }) => {
  const navigate = useNavigate(),
    { version } = useDDragonData(),
    progress =
      mastery === undefined
        ? 0
        : mastery.championPointsUntilNextLevel < 1
        ? 100
        : (mastery.championPointsSinceLastLevel /
            (mastery.championPointsSinceLastLevel +
              mastery.championPointsUntilNextLevel)) *
          100;
  return (
    <Card elevation={0}>
      <CardActionArea
        onClick={() => navigate(`/data/champions/${champion?.id}`)}
      >
        <Stack direction='row' alignItems='center' sx={{ p: 1 }}>
          <Stack alignItems='center' width='50%'>
            <Badge
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              invisible={!mastery?.chestGranted}
              badgeContent={
                <LazyAvatar
                  sx={{ width: 28, height: 28 }}
                  src={`https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-collections/global/default/images/item-element/hextech-chest.png`}
                />
              }
            >
              <SkeletonAvatar
                loading={champion === undefined}
                alt={champion?.name}
                title={champion?.name}
                variant='rounded'
                src={
                  !champion
                    ? undefined
                    : `https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion.image.full}`
                }
                width={64}
                height={64}
              />
            </Badge>
            <SkeletonTypography
              loading={champion === undefined}
              variant='body1'
              textAlign='center'
              color='text.secondary'
              sx={{ mt: 0.5 }}
            >
              {champion?.name}
            </SkeletonTypography>
          </Stack>

          <Stack alignItems='center' width='50%'>
            <MasteryAvatar level={mastery?.championLevel ?? 1} size={48} />
            <Stack direction='row' alignItems='center' spacing={0.5} mb={-0.75}>
              <Typography
                variant='body2'
                color='text.secondary'
                lineHeight={0}
                component='p'
                letterSpacing={1}
              >
                {mastery?.tokensEarned}/{mastery?.markRequiredForNextLevel}
              </Typography>
              <SVGMarkIcon width={18} height={18} />
            </Stack>
            <Stack
              direction='row'
              sx={{ width: '100%' }}
              justifyContent='center'
              alignItems='center'
            >
              <Typography variant='subtitle1' sx={{ mr: 1 }}>
                {mastery?.championLevel}
              </Typography>
              <Box flexGrow={1}>
                {mastery ? (
                  <LinearProgress variant='determinate' value={progress} />
                ) : (
                  <Skeleton sx={{ width: '100%', height: '28px' }} />
                )}
              </Box>
              <Typography variant='subtitle1' sx={{ ml: 1 }}>
                {mastery === undefined ? undefined : mastery.championLevel + 1}
              </Typography>
            </Stack>
            <SkeletonTypography
              loading={mastery === undefined}
              variant='body1'
              color='text.secondary'
              textAlign='center'
              sx={{ width: '80%', mt: -0.5 }}
            >
              {mastery?.championPoints.toLocaleString()}
            </SkeletonTypography>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  );
};
