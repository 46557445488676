import * as d3 from 'd3';

import { ChartContextProvider, Datum, useChart } from './context';
import { Chart } from './Chart';

interface Props<D extends Datum> extends React.PropsWithChildren {
  series: (d: D) => string;
  keys: string[];
  order?: (series: d3.Series<[string, D], string>[]) => Iterable<number>;
  offset?: (series: d3.Series<[string, D], string>[], order: number[]) => void;
  color?: (k: string) => string;
  bandPadding?: number;
  bandPaddingInner?: number;
  bandPaddingOuter?: number;
}

export const StackedBarChart = <D extends Datum>({
  series,
  keys,
  order,
  offset,
  color,
  bandPadding,
  bandPaddingInner,
  bandPaddingOuter,
  children,
}: Props<D>) => {
  const { width, height, padding, data, ...rest } = useChart<D>(),
    index = d3.index(data, series),
    stack = d3
      .stack<[string, D]>()
      .order(order ?? d3.stackOrderDescending)
      .offset(offset ?? d3.stackOffsetNone)
      .keys(keys)
      .value(([, d], key) => d[key] as number)(index),
    defaultPadding = 0.5,
    x = d3
      .scaleBand()
      .domain(data.map(series))
      .range([padding[3], width - padding[1]])
      .paddingInner(bandPaddingInner ?? bandPadding ?? defaultPadding)
      .paddingOuter(bandPaddingOuter ?? bandPadding ?? defaultPadding),
    y = d3
      .scaleLinear()
      .domain([0, d3.max(stack, (d) => d3.max(d, (d) => d[1] ?? 0) ?? 0) ?? 0])
      .range([height - padding[2], padding[0]]);

  return (
    <ChartContextProvider
      value={{
        ...rest,
        width,
        height,
        padding,
        data,
        x,
        y,
      }}
    >
      <Chart>
        {children}
        <g>
          {stack.map((group, k) => {
            return (
              <g key={k} fill={color ? color(group.key) : 'currentColor'}>
                {group.map((s, i) => {
                  return (
                    <rect
                      key={i}
                      x={x(s.data[0])}
                      y={y(s[1])}
                      width={x.bandwidth()}
                      height={Math.max(0, y(s[0]) - y(s[1]))}
                    />
                  );
                })}
              </g>
            );
          })}
        </g>
      </Chart>
    </ChartContextProvider>
  );
};
