import { useState } from 'react';

import { Badge, Chip, Stack, StackProps, useTheme } from '@mui/material';

export interface ChipsProps<T>
  extends Omit<Omit<StackProps, 'onChange'>, 'children'> {
  values: readonly T[];
  onChange: (event: React.MouseEvent<HTMLElement>, values: T[]) => void;
  labels?: readonly string[];
  counts?: readonly number[];
  initialSelected?: readonly boolean[];
  icons?: readonly (
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined
  )[];
}

export const Chips = <T,>({
  values,
  onChange,
  labels,
  counts,
  initialSelected,
  icons,
  ...rest
}: ChipsProps<T>) => {
  const theme = useTheme(),
    [selected, setSelected] = useState<boolean[]>(
      initialSelected !== undefined
        ? [...initialSelected]
        : Array.from(values, () => false),
    );
  return (
    <Stack direction='row' flexWrap='wrap' rowGap={1} columnGap={1} {...rest}>
      {values.map((value, i) => {
        const chip = (
          <Chip
            size='small'
            key={i}
            label={labels ? labels[i] : `${value}`}
            variant={selected[i] ? 'filled' : 'outlined'}
            sx={{
              '&.MuiChip-outlined': {
                borderColor: theme.vars.palette.divider,
              },
              '&.MuiChip-filled': {
                border: (theme) =>
                  `1px solid rgba(${theme.vars.palette.primary.mainChannel} / ${theme.vars.palette.action.selectedOpacity})`,
                backgroundColor: `rgba(${theme.vars.palette.primary.mainChannel} / ${theme.vars.palette.action.selectedOpacity})`,
              },
              '&:hover': {
                borderColor: theme.vars.palette.action.hover,
                backgroundColor: theme.vars.palette.action.hover,
              },
              '& .MuiChip-avatar': {
                ml: 0.5,
                backgroundColor: 'unset',
              },
              [theme.getColorSchemeSelector('dark')]: {
                fontWeight: theme.typography.fontWeightMedium,
              },
            }}
            onClick={(e) => {
              const curSelected = [...selected];
              curSelected[i] = !curSelected[i];
              setSelected(curSelected);
              onChange(
                e,
                values.reduce(
                  (values, value, i) =>
                    curSelected[i] ? [value, ...values] : values,
                  [] as T[],
                ),
              );
            }}
            avatar={icons?.at(i)}
          />
        );
        return counts !== undefined ? (
          <Badge
            key={i}
            badgeContent={counts[i]}
            sx={{
              '& .MuiBadge-badge': {
                color: 'common.white',
                backgroundColor: 'grey.A700',
                fontSize: '0.75rem',
                lineHeight: 0,
              },
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            {chip}
          </Badge>
        ) : (
          chip
        );
      })}
    </Stack>
  );
};
