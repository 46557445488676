import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useSWR from 'swr';

import { Riot } from 'api';
import { useLocalStorage } from 'usehooks-ts';

const riotIdKey = 'riot-id';
const riotIdsKey = 'riot-ids';

type RiotId = {
  gameName: string;
  tagLine: string;
  puuid?: string;
};

const getRiotIdFromLocalStorage = (): RiotId | undefined => {
  const raw = window?.localStorage.getItem(riotIdKey);
  if (raw) return JSON.parse(raw);
  return undefined;
};

const setRiotIdToLocalStorage = (riotId: RiotId) => {
  window?.localStorage.setItem(riotIdKey, JSON.stringify(riotId));
};

const RiotIdContext = createContext([
  getRiotIdFromLocalStorage(),
  (_: RiotId) => {},
] as [RiotId, (riotId: RiotId) => void]);

const useRiotIdFromLocalStorage = (): [
  RiotId | undefined,
  (riotId: RiotId) => void,
] => [getRiotIdFromLocalStorage(), setRiotIdToLocalStorage];

const useRiotIdFromParams = (): RiotId | undefined => {
  const parts = useParams().riotId?.split('-');
  return parts?.length === 2
    ? { gameName: parts[0], tagLine: parts[1] }
    : undefined;
};

export const useRiotId = () => useContext(RiotIdContext);

export const useRiotIds = () => {
  const [riotIds, setRiotIds] = useLocalStorage<RiotId[]>(riotIdsKey, []);
  riotIds.filter((r) => r.puuid !== undefined);
  return [riotIds, setRiotIds] as [RiotId[], (riotIds: RiotId[]) => void];
};

export const RiotIdProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const location = useLocation(),
    navigate = useNavigate(),
    [riotIdFromLocalStorage, setRiotIdToLocalStorage] =
      useRiotIdFromLocalStorage(),
    riotIdFromParams = useRiotIdFromParams(),
    [riotId, setRiotId] = useState(
      riotIdFromParams
        ? {
            ...riotIdFromParams,
            puuid:
              riotIdFromLocalStorage?.gameName === riotIdFromParams?.gameName &&
              riotIdFromLocalStorage?.tagLine === riotIdFromParams?.tagLine
                ? riotIdFromLocalStorage?.puuid
                : undefined,
          }
        : riotIdFromLocalStorage,
    ),
    [riotIds, setRiotIds] = useRiotIds(),
    setRiotIdWithLocalStorage = (riotId: RiotId) => {
      setRiotIdToLocalStorage(riotId);
      setRiotId(riotId);
      setRiotIds([
        riotId,
        ...riotIds.filter(
          (r) => r.puuid !== undefined && r.puuid !== riotId.puuid,
        ),
      ]);
    },
    gameName = riotId?.gameName ?? '',
    tagLine = riotId?.tagLine ?? '';

  // TODO: move side effect
  useEffect(() => {
    if (gameName !== '' && tagLine !== '' && location.pathname === '/')
      navigate(`profile/na1/${gameName}-${tagLine}/history`);
  }, [gameName, tagLine, location.pathname, navigate]);

  return (
    <RiotIdContext.Provider
      value={[riotId ?? { gameName, tagLine }, setRiotIdWithLocalStorage]}
    >
      {children}
    </RiotIdContext.Provider>
  );
};

export const useAccount = () => {
  const [{ gameName, tagLine, puuid }, setRiotId] = useRiotId(),
    [riotIds, setRiotIds] = useRiotIds(),
    { data: account, isLoading } = useSWR(
      puuid !== undefined
        ? `riot/account/v1/accounts/by-puuid/${puuid}`
        : gameName !== undefined && tagLine !== undefined
        ? `riot/account/v1/accounts/by-riot-id/${gameName}/${tagLine}`
        : undefined,
      async () => {
        return puuid
          ? Riot.AccountV1.accountV1GetByPuuid({ puuid })
          : Riot.AccountV1.accountV1GetByRiotId({
              gameName: gameName!,
              tagLine: tagLine!,
            });
      },
      {
        dedupingInterval: 3000,
        fallback: {
          account: { gameName, tagLine, puuid },
        },
      },
    );

  useEffect(() => {
    if (
      isLoading ||
      account?.gameName === undefined ||
      account?.tagLine === undefined ||
      puuid !== undefined
    )
      return;
    const riotId = {
      gameName: account.gameName,
      tagLine: account.tagLine,
      puuid: account.puuid,
    };
    setRiotId(riotId);
    setRiotIds([
      riotId,
      ...riotIds.filter(
        (r) => r.puuid !== undefined && r.puuid !== riotId.puuid,
      ),
    ]);
  });

  return { gameName, tagLine, account, loading: isLoading };
};

export const useSummoner = () => {
  const { account } = useAccount(),
    { data: summoner, isLoading } = useSWR(
      account !== undefined
        ? `/lol/summoner/v4/summoners/by-puuid/${account.puuid}`
        : undefined,
      () =>
        Riot.SummonerV4.summonerV4GetByPUUID({
          encryptedPUUID: account!.puuid,
        }),
      { dedupingInterval: 3000, refreshInterval: 60000 },
    );
  return { summoner, loading: isLoading };
};
