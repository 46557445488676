import { useMemo } from 'react';

import { blue, cyan, grey, pink, red } from '@mui/material/colors';
import {
  alpha,
  CssBaseline,
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from '@mui/material';

const useDefaultMode = () =>
  useMemo(
    () =>
      (window.localStorage.getItem('mui-mode') as
        | 'dark'
        | 'light'
        | undefined) ??
      (window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'system'),
    [],
  );

const palette = {
  primary: {
    main: pink[300],
  },
  secondary: {
    main: cyan[300],
  },
  action: {
    hover: alpha(pink[300], 0.12),
    selected: alpha(pink[300], 0.08),
  },
  neutral: {
    light: grey[400],
    main: grey[500],
    dark: grey[600],
    contrastText: '#000',
  },
  tooltip: {
    main: grey[300],
  },
  win: {
    main: blue[300],
  },
  lose: {
    main: red[300],
  },
  hot: {
    main: red[300],
  },
  cold: {
    main: blue[300],
  },
  physical: {
    main: '#ff8c34',
  },
  magic: {
    main: '#00b0f0',
  },
  true: {
    main: '#ffffff',
  },
  health: {
    main: '#61d85d',
  },
  cc: {
    main: '#9b18c6',
  },
  gold: {
    main: '#ffd14e',
  },
  crit: {
    main: '#e34d4c',
  },
  ap: {
    main: '#7a6dff',
  },
};

export const ThemeProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const defaultMode = useDefaultMode(),
    theme = extendTheme({
      colorSchemes: {
        light: {
          palette: {
            ...palette,
          },
        },
        dark: {
          palette: {
            ...palette,
            primary: {
              main: pink[200],
            },
            secondary: {
              main: cyan[200],
            },
            action: {
              hover: alpha(pink[200], 0.12),
              selected: alpha(pink[200], 0.08),
            },
            neutral: {
              light: grey[300],
              main: grey[400],
              dark: grey[500],
              contrastText: '#fff',
            },
            tooltip: {
              main: grey[400],
            },
          },
        },
      },
      mixins: {
        drawer: {
          nav: {
            minWidth: '220px',
            maxWidth: '220px',
          },
          context: {
            minWidth: '300px',
            maxWidth: '300px',
          },
        },
      },
    });
  return (
    <CssVarsProvider theme={theme} defaultMode={defaultMode}>
      <CssBaseline enableColorScheme />
      {children}
    </CssVarsProvider>
  );
};
