import {
  Avatar,
  AvatarProps,
  Stack,
  StackProps,
  styled,
  useTheme,
} from '@mui/material';

import { useSpellIcon } from 'hooks';

const SpellIconStack = styled(Stack)(({ theme }) => ({
  '> :first-of-type': {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomWidth: 0,
  },
  '> :last-of-type': {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
}));

interface Props extends AvatarProps {
  spellId?: number;
}

export const SpellIcon: React.FC<Props> = ({ spellId, sx, ...rest }) => {
  const theme = useTheme(),
    src = useSpellIcon(spellId);
  return (
    <Avatar
      variant='square'
      src={src}
      sx={{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.palette.divider,
        width: '20px',
        height: '20px',
        ...sx,
      }}
      {...rest}
    />
  );
};

interface SpellIconGroupProps extends StackProps {
  id1?: number;
  id2?: number;
  AvatarProps?: AvatarProps;
}

export const SpellIconGroup: React.FC<SpellIconGroupProps> = ({
  id1,
  id2,
  AvatarProps,
  ...rest
}) => {
  return (
    <SpellIconStack {...rest}>
      <SpellIcon spellId={id1} {...AvatarProps}>
        S1
      </SpellIcon>
      <SpellIcon spellId={id2} {...AvatarProps}>
        S2
      </SpellIcon>
    </SpellIconStack>
  );
};
