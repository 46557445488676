// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile, HttpInfo} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { StatbudV1ChampionStatsDto } from '../models/StatbudV1ChampionStatsDto';
import { StatbudV1LeagueEntryDto } from '../models/StatbudV1LeagueEntryDto';
import { StatbudV1LeagueEntryWithMatchIdDto } from '../models/StatbudV1LeagueEntryWithMatchIdDto';
import { StatbudV1PlayerStatDto } from '../models/StatbudV1PlayerStatDto';

/**
 * no description
 */
export class StatbudV1ApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * Get latest per day league entries for the last 30 days
     * Get latest per day league entries for the last 30 days
     * @param encryptedSummonerId 
     * @param queueType 
     */
    public async statbudV1GetLeagueEntriesHistoryForSummoner(encryptedSummonerId: string, queueType: 'RANKED_SOLO_5x5' | 'RANKED_FLEX_SR', _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'encryptedSummonerId' is not null or undefined
        if (encryptedSummonerId === null || encryptedSummonerId === undefined) {
            throw new RequiredError("StatbudV1Api", "statbudV1GetLeagueEntriesHistoryForSummoner", "encryptedSummonerId");
        }


        // verify required parameter 'queueType' is not null or undefined
        if (queueType === null || queueType === undefined) {
            throw new RequiredError("StatbudV1Api", "statbudV1GetLeagueEntriesHistoryForSummoner", "queueType");
        }


        // Path Params
        const localVarPath = '/lol/statbud/v1/entries/history/{encryptedSummonerId}/{queueType}'
            .replace('{' + 'encryptedSummonerId' + '}', encodeURIComponent(String(encryptedSummonerId)))
            .replace('{' + 'queueType' + '}', encodeURIComponent(String(queueType)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Get match IDs for a summoner
     * Get match IDs for a summoner
     * @param puuid 
     * @param startTime 
     * @param endTime 
     * @param queues 
     * @param type 
     * @param count 
     */
    public async statbudV1GetMatchIdsByPuuid(puuid: string, startTime?: number, endTime?: number, queues?: Array<400 | 420 | 430 | 440 | 450 | 900 | 1300 | 1700>, type?: 'ranked' | 'normal' | 'tourney' | 'tutorial', count?: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'puuid' is not null or undefined
        if (puuid === null || puuid === undefined) {
            throw new RequiredError("StatbudV1Api", "statbudV1GetMatchIdsByPuuid", "puuid");
        }







        // Path Params
        const localVarPath = '/lol/statbud/v1/matches/by-puuid/{puuid}/ids'
            .replace('{' + 'puuid' + '}', encodeURIComponent(String(puuid)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (startTime !== undefined) {
            requestContext.setQueryParam("startTime", ObjectSerializer.serialize(startTime, "number", "int64"));
        }

        // Query Params
        if (endTime !== undefined) {
            requestContext.setQueryParam("endTime", ObjectSerializer.serialize(endTime, "number", "int64"));
        }

        // Query Params
        if (queues !== undefined) {
            requestContext.setQueryParam("queues", ObjectSerializer.serialize(queues, "Array<400 | 420 | 430 | 440 | 450 | 900 | 1300 | 1700>", "int32"));
        }

        // Query Params
        if (type !== undefined) {
            requestContext.setQueryParam("type", ObjectSerializer.serialize(type, "'ranked' | 'normal' | 'tourney' | 'tutorial'", ""));
        }

        // Query Params
        if (count !== undefined) {
            requestContext.setQueryParam("count", ObjectSerializer.serialize(count, "number", "int32"));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Get match IDs correlated with league entries for a summoner
     * Get match IDs correlated with league entries for a summoner
     * @param puuid 
     * @param queueType 
     */
    public async statbudV1GetMatchIdsForLeagueEntriesByPUUID(puuid: string, queueType: 'RANKED_SOLO_5x5' | 'RANKED_FLEX_SR', _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'puuid' is not null or undefined
        if (puuid === null || puuid === undefined) {
            throw new RequiredError("StatbudV1Api", "statbudV1GetMatchIdsForLeagueEntriesByPUUID", "puuid");
        }


        // verify required parameter 'queueType' is not null or undefined
        if (queueType === null || queueType === undefined) {
            throw new RequiredError("StatbudV1Api", "statbudV1GetMatchIdsForLeagueEntriesByPUUID", "queueType");
        }


        // Path Params
        const localVarPath = '/lol/statbud/v1/matches/by-puuid/{puuid}/{queueType}'
            .replace('{' + 'puuid' + '}', encodeURIComponent(String(puuid)))
            .replace('{' + 'queueType' + '}', encodeURIComponent(String(queueType)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Get stats for a player
     * Get stats for a player
     * @param puuid 
     */
    public async statbudV1GetPlayerStats(puuid: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'puuid' is not null or undefined
        if (puuid === null || puuid === undefined) {
            throw new RequiredError("StatbudV1Api", "statbudV1GetPlayerStats", "puuid");
        }


        // Path Params
        const localVarPath = '/lol/statbud/v1/player/stats/{puuid}'
            .replace('{' + 'puuid' + '}', encodeURIComponent(String(puuid)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Get champion stats for an account puuid
     * Get champion stats for an account puuid
     * @param puuid 
     */
    public async statbudV1GetSummonerChampionStats(puuid: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'puuid' is not null or undefined
        if (puuid === null || puuid === undefined) {
            throw new RequiredError("StatbudV1Api", "statbudV1GetSummonerChampionStats", "puuid");
        }


        // Path Params
        const localVarPath = '/lol/statbud/v1/summoner/champions';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (puuid !== undefined) {
            requestContext.setQueryParam("puuid", ObjectSerializer.serialize(puuid, "string", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class StatbudV1ApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to statbudV1GetLeagueEntriesHistoryForSummoner
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async statbudV1GetLeagueEntriesHistoryForSummonerWithHttpInfo(response: ResponseContext): Promise<HttpInfo<Array<StatbudV1LeagueEntryDto> >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<StatbudV1LeagueEntryDto> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<StatbudV1LeagueEntryDto>", ""
            ) as Array<StatbudV1LeagueEntryDto>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<StatbudV1LeagueEntryDto> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<StatbudV1LeagueEntryDto>", ""
            ) as Array<StatbudV1LeagueEntryDto>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to statbudV1GetMatchIdsByPuuid
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async statbudV1GetMatchIdsByPuuidWithHttpInfo(response: ResponseContext): Promise<HttpInfo<Array<string> >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<string> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<string>", ""
            ) as Array<string>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<string> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<string>", ""
            ) as Array<string>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to statbudV1GetMatchIdsForLeagueEntriesByPUUID
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async statbudV1GetMatchIdsForLeagueEntriesByPUUIDWithHttpInfo(response: ResponseContext): Promise<HttpInfo<Array<StatbudV1LeagueEntryWithMatchIdDto> >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<StatbudV1LeagueEntryWithMatchIdDto> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<StatbudV1LeagueEntryWithMatchIdDto>", ""
            ) as Array<StatbudV1LeagueEntryWithMatchIdDto>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<StatbudV1LeagueEntryWithMatchIdDto> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<StatbudV1LeagueEntryWithMatchIdDto>", ""
            ) as Array<StatbudV1LeagueEntryWithMatchIdDto>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to statbudV1GetPlayerStats
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async statbudV1GetPlayerStatsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<Array<StatbudV1PlayerStatDto> >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<StatbudV1PlayerStatDto> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<StatbudV1PlayerStatDto>", ""
            ) as Array<StatbudV1PlayerStatDto>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<StatbudV1PlayerStatDto> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<StatbudV1PlayerStatDto>", ""
            ) as Array<StatbudV1PlayerStatDto>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to statbudV1GetSummonerChampionStats
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async statbudV1GetSummonerChampionStatsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<Array<StatbudV1ChampionStatsDto> >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<StatbudV1ChampionStatsDto> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<StatbudV1ChampionStatsDto>", ""
            ) as Array<StatbudV1ChampionStatsDto>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<StatbudV1ChampionStatsDto> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<StatbudV1ChampionStatsDto>", ""
            ) as Array<StatbudV1ChampionStatsDto>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
