import { Avatar, BoxProps, Box, AvatarProps } from '@mui/material';

import { ChampionLink } from 'components';
import { useChampion, useDDragonData } from 'hooks';

interface ChampionProps extends BoxProps {
  championId?: number;
  enableLink?: boolean;
  AvatarProps?: AvatarProps;
}

export const ChampionIcon: React.FC<ChampionProps> = ({
  championId,
  enableLink,
  AvatarProps,
  ...rest
}) => {
  const { version } = useDDragonData(),
    champion = useChampion(championId),
    src =
      version && champion
        ? `https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion.image.full}`
        : undefined,
    children = (
      <Avatar variant='rounded' src={src} {...AvatarProps}>
        C
      </Avatar>
    );
  return (
    <Box {...rest}>
      {enableLink ? (
        <ChampionLink championKey={champion?.id ?? ''}>{children}</ChampionLink>
      ) : (
        children
      )}
    </Box>
  );
};
