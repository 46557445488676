import { Avatar, Badge, Box, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import titles from 'assets/challenges/titles.json';
import { SVGMasteryIcon } from 'components';
import {
  useChallenges,
  useDDragonData,
  useMasteries,
  useRiotId,
  useSummoner,
} from 'hooks';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
  fontSize: theme.typography.caption.fontSize,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.getContrastText(theme.palette.secondary.main),
}));

export const ProfileHeader: React.FC = () => {
  const theme = useTheme(),
    [{ gameName, tagLine }] = useRiotId(),
    { summoner } = useSummoner(),
    { version } = useDDragonData(),
    { masteries } = useMasteries(),
    { challenges: info } = useChallenges();
  const title = info?.preferences.title
    ? (titles as { [k: string]: string })[info.preferences.title]
    : '';
  const profileIconPath =
    version && summoner
      ? `https://ddragon.leagueoflegends.com/cdn/${version}/img/profileicon/${summoner.profileIconId}.png`
      : undefined;
  const masteryLevel = masteries?.reduce(
    (total, { championLevel }) => total + championLevel,
    0,
  );
  return (
    <Stack direction='row' padding={2} spacing={2} alignItems='center'>
      <Badge
        overlap='circular'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <SmallAvatar
            sx={{
              width: 32,
              height: 32,
            }}
          >
            {summoner?.summonerLevel || '-'}
          </SmallAvatar>
        }
      >
        <Avatar
          alt={gameName + '#' + tagLine}
          src={profileIconPath}
          sx={{
            width: 72,
            height: 72,
          }}
        />
      </Badge>

      <Box sx={{ flex: 1, minWidth: 0 }}>
        <Typography variant='h5' overflow='hidden' textOverflow='ellipsis'>
          {gameName}
          <Typography
            variant='body2'
            color='text.secondary'
            sx={{ display: 'inline' }}
          >
            #{tagLine}
          </Typography>
        </Typography>

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography
            variant='body1'
            color='text.secondary'
            component='span'
            flexShrink={1}
          >
            {title}
          </Typography>
          <Box flexGrow={1} flexShrink={2} />
          <Box sx={{ mr: 0.25 }}>
            <SVGMasteryIcon color={theme.palette.text.secondary} />
          </Box>
          <Typography variant='body1' color='text.secondary' component='span'>
            {masteryLevel}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};
