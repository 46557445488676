import React from 'react';
import { useParams } from 'react-router-dom';

import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import { useDDragonData } from 'hooks';

export const RunePage: React.FC = () => {
  const { styleId } = useParams(),
    { runes } = useDDragonData();

  if (runes === undefined)
    return (
      <Box>
        <Grid container justifyContent='center' sx={{ p: 2 }}>
          <CircularProgress />
        </Grid>
      </Box>
    );

  const { name, icon, slots } = runes.find(
    ({ id }) => id.toString() === styleId,
  )!;
  return (
    <Box sx={{ p: 2, overflowY: 'auto' }}>
      <Stack
        direction='row'
        spacing={2}
        justifyContent='center'
        alignItems='center'
        sx={{ pt: 1, pb: 2 }}
      >
        <Avatar
          variant='square'
          alt={name}
          src={`https://ddragon.leagueoflegends.com/cdn/img/${icon}`}
        />
        <Typography variant='h5'>{name}</Typography>
      </Stack>
      <Grid container alignItems='center' spacing={2}>
        {slots.map(({ runes }, i) =>
          runes.map(({ id, icon, name, shortDesc }) => (
            <Grid key={id} xs={12} sm={4}>
              <Card elevation={1}>
                <Stack
                  key={id}
                  direction='row'
                  alignItems='center'
                  spacing={1}
                  sx={{ pt: 2, pl: 2 }}
                >
                  <Avatar
                    variant='square'
                    alt={name}
                    src={`https://ddragon.leagueoflegends.com/cdn/img/${icon}`}
                    sx={{
                      width: 64,
                      height: 64,
                    }}
                  />
                  <Typography>{name}</Typography>
                </Stack>
                <CardContent>
                  <Typography color='text.secondary'>
                    {shortDesc.replace(/<[^>]*>/g, '')}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )),
        )}
      </Grid>
    </Box>
  );
};
