import { MatchV5ParticipantDto } from '@blakearoberts/riot-api-ts';
import * as d3 from 'd3';
import { Container, OrdinalChart, TidyTooltip, XAxisBand, YAxis } from 'd4';
import {
  useTheme,
  Typography,
  Paper,
  Stack,
  Divider,
  Box,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import {
  blue,
  brown,
  deepPurple,
  green,
  grey,
  orange,
  pink,
  purple,
  red,
  yellow,
} from '@mui/material/colors';

import { ChampionIcon } from 'components';
import { formatDuration } from 'helpers';
import {
  MatchTimelineGoldFrame,
  useAccount,
  useMatchGoldTimeline,
} from 'hooks';

const useColors = () => {
  return {
    kill: blue[800],
    assist: blue[400],
    shutdown: yellow[300],
    plate: brown[500],
    tower: grey[500],
    inhibitor: red[400],
    drake: orange[500],
    rift: purple[500],
    baron: deepPurple[500],
    ward: pink[300],
    passive: green[500],
  } as { [k: string]: string };
};

interface Props {
  matchId?: string;
  participants?: MatchV5ParticipantDto[];
}

export const GoldStackChart: React.FC<Props> = ({ matchId, participants }) => {
  const theme = useTheme(),
    { account } = useAccount(),
    participant = participants?.find(({ puuid }) => puuid === account?.puuid),
    summonerTeamId = participant?.teamId,
    timeline = (useMatchGoldTimeline(matchId) ?? []).reduce(
      (frames, frame, i, timeline) => {
        // if the game ended within one second of the previous minute, remove
        // the game end frame

        // participant's previous index and frame
        const j = i - (participants?.length ?? 0),
          prev = timeline.at(j);
        if (
          // frame is not a game end frame
          i < timeline.length - (participants?.length ?? 0) ||
          // game end frame isn't within one second of previous frame
          Math.trunc(frame.timestamp / 1000) !==
            Math.trunc((prev?.timestamp ?? 0) / 1000)
        )
          // append frame
          return [...frames, frame];

        // don't append frame
        return frames;
      },
      [] as MatchTimelineGoldFrame[],
    ),
    data = timeline
      .map(({ timestamp, participantId, teamId, totalGold, ...rest }) => {
        const p = participants?.find(
          ({ participantId: id }) => id === participantId,
        );
        return {
          timestamp,
          summonerId: p?.summonerId ?? participantId.toString(),
          value: Object.values(rest).reduce((total, v) => total + v, 0) ?? 0,
        };
      })
      .filter(({ value }) => value !== 0),
    index = d3.index(
      timeline,
      (d) => d.timestamp,
      (d) =>
        participants?.find(({ participantId: id }) => id === d.participantId)
          ?.summonerId,
    ),
    colors = useColors();

  type Datum = (typeof data)[0];
  return (
    <>
      <Typography variant='h5'>Gold Earned per Summoner</Typography>

      <Container data={data} padding={[8, 16, 24, 36]}>
        <OrdinalChart<Datum>
          domain={(d) => d.timestamp}
          value={(d) => d?.value ?? 0}
          series={(d) => d.summonerId}
          order={(series) =>
            series
              .map((s, i) => {
                const { participantId, teamId } = participants?.find(
                  ({ summonerId, participantId }) =>
                    summonerId === s.key || participantId.toString() === s.key,
                ) ?? { teamId: -1, participantId: -1 };
                return {
                  i,
                  teamId,
                  participantId,
                };
              })
              .sort(
                (
                  { participantId: a, teamId: aTeam },
                  { participantId: b, teamId: bTeam },
                ) =>
                  aTeam === bTeam ? b - a : aTeam === summonerTeamId ? -1 : 1,
              )
              .map(({ i }) => i)
          }
          offset={d3.stackOffsetExpand}
          color={(key) => {
            const p = participants?.find(
              ({ summonerId }) => summonerId === key,
            );
            return p?.teamId === participant?.teamId
              ? d3.interpolateBlues((((p?.participantId ?? 0) % 5) + 3) / 10)
              : d3.interpolateReds((((p?.participantId ?? 0) % 5) + 3) / 10);
          }}
          padding={{ inner: 0 }}
        >
          <XAxisBand<number>
            stroke={theme.palette.text.secondary}
            tick={(v, i) => (
              <>
                <line stroke='currentColor' y2={6} strokeWidth={0.5} />
                {(i & 1) === 0 && (
                  <text
                    fill={theme.palette.text.secondary}
                    strokeWidth={0}
                    textAnchor='middle'
                    transform='translate(0 16) rotate(0)'
                  >
                    {formatDuration(v).split(':')[0]}
                  </text>
                )}
              </>
            )}
          />
          <YAxis
            stroke={theme.palette.text.secondary}
            format={(v) => v.toLocaleString(undefined, { style: 'percent' })}
          />
          <TidyTooltip<Datum>
            content={({ datum }) => {
              if (datum === undefined) return <></>;
              const { summonerId, timestamp, value } = datum,
                p = participants?.find(
                  ({ summonerId: id }) => id === summonerId,
                ),
                frame = index.get(timestamp)?.get(summonerId);
              return (
                <Paper sx={{ p: 1 }} elevation={3}>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <ChampionIcon
                      championId={p?.championId}
                      AvatarProps={{
                        variant: 'circular',
                        sx: {
                          width: 32,
                          height: 32,
                          border: `2px solid ${
                            p?.teamId === summonerTeamId
                              ? theme.palette.win.main
                              : theme.palette.lose.main
                          }`,
                        },
                      }}
                    />
                    <Box>
                      <Typography>{`${p?.riotIdGameName}#${p?.riotIdTagline}`}</Typography>
                      <Typography variant='caption' color='text.secondary'>
                        {value.toLocaleString()} g @{' '}
                        {formatDuration(timestamp, true)}
                      </Typography>
                    </Box>
                  </Stack>
                  <Divider sx={{ m: theme.spacing(0.5, 0, 0) }} />
                  <Table size='small'>
                    <TableBody>
                      {Object.entries(frame ?? {})
                        .filter(
                          ([k, v]) =>
                            ![
                              'participantId',
                              'teamId',
                              'timestamp',
                              'totalGold',
                            ].includes(k) && v !== 0,
                        )
                        .map(([k, v]) => (
                          <TableRow
                            key={k}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell>
                              <Typography
                                variant='body2'
                                color='text.secondary'
                              >
                                {k}
                              </Typography>
                            </TableCell>
                            <TableCell align='right' color={colors[k]}>
                              {v.toLocaleString()} g
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Paper>
              );
            }}
          />
        </OrdinalChart>
      </Container>
    </>
  );
};
