import { ChartContext, Datum, defaultChartContextValue } from './context';
import { useElementBounds } from './hooks';

interface Props<D extends Datum> {
  data: D[];
  padding?: [number, number, number, number];
  style?: React.CSSProperties;
}

export const Container: React.FC<React.PropsWithChildren<Props<Datum>>> = ({
  data,
  padding,
  children,
  style,
}) => {
  const [ref, { width }] = useElementBounds(),
    height = Math.floor((width * 9) / 16);
  return (
    <ChartContext.Provider
      value={{
        ...defaultChartContextValue,
        ref,
        width,
        height,
        padding: padding ?? defaultChartContextValue.padding,
        data,
      }}
    >
      <div
        ref={ref}
        style={{
          width: '100%',
          lineHeight: 0,
          ...style,
        }}
      >
        {children}
      </div>
    </ChartContext.Provider>
  );
};
