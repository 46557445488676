import { MatchV5EventsTimeLineDto } from '@blakearoberts/riot-api-ts';
import * as d3 from 'd3';
import { Container, Grid, LineChart, Tooltip, XAxis, YAxis } from 'd4';
import {
  Avatar,
  Divider,
  Paper,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { yellow } from '@mui/material/colors';

import { ChampionIcon } from 'components';
import { formatDuration } from 'helpers';
import {
  useAccount,
  useMatch,
  useMatchGoldTimeline,
  useMatchTimeline,
} from 'hooks';

const KillIcon = () => (
  <img
    width={20}
    height={20}
    src='https://raw.communitydragon.org/latest/game/assets/ux/minimap/pings/all_in.png'
    alt=''
  />
);

const MinionIconAvatar: React.FC<{ team: boolean }> = ({ team }) => {
  const theme = useTheme();
  return (
    <Avatar
      sx={{
        width: 30,
        height: 30,
        border: `2px solid ${
          team ? theme.palette.win.main : theme.palette.lose.main
        }`,
      }}
      variant='circular'
      src={require(`assets/icon_minions.png`)}
    />
  );
};

interface MinimapIconProps {
  file?: string;
  bounty?: boolean;
}

const MinimapIconAvatar: React.FC<MinimapIconProps> = ({ file, bounty }) => {
  const theme = useTheme();
  return (
    <Avatar
      sx={{
        width: 30,
        height: 30,
        border: `2px solid ${bounty ? yellow[700] : theme.palette.divider}`,
      }}
      variant='circular'
      src={
        file
          ? `https://raw.communitydragon.org/latest/game/assets/ux/minimap/icons/${file}`
          : undefined
      }
    />
  );
};

const ChampionAvatar = ({
  championId,
  team,
}: {
  championId?: number;
  team: boolean;
}) => {
  const theme = useTheme();
  return (
    <ChampionIcon
      championId={championId}
      AvatarProps={{
        variant: 'circular',
        sx: {
          width: 30,
          height: 30,
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: team ? theme.palette.win.main : theme.palette.lose.main,
        },
      }}
    />
  );
};

interface TooltipEventProps {
  summonerTeamId?: number;
  matchId?: string;
  event: MatchV5EventsTimeLineDto;
}

const TooltipEvent: React.FC<TooltipEventProps> = ({
  summonerTeamId,
  matchId,
  event,
}) => {
  const {
    timestamp,
    type,
    killerId,
    victimId,
    teamId,
    monsterType,
    monsterSubType,
    buildingType,
    bounty,
  } = event;
  const { match } = useMatch(matchId),
    participant = (id?: number) =>
      match?.info.participants.find(
        ({ participantId }) => participantId === id,
      ),
    killer = participant(killerId),
    victim = participant(victimId);
  return (
    <Stack direction='row' alignItems='center' spacing={0.5}>
      {killer ? (
        <ChampionAvatar
          championId={killer?.championId}
          team={summonerTeamId === killer?.teamId}
        />
      ) : (
        <MinionIconAvatar team={summonerTeamId !== teamId} />
      )}
      <KillIcon />
      {type === 'CHAMPION_KILL' ? (
        <ChampionAvatar
          championId={victim?.championId}
          team={summonerTeamId === victim?.teamId}
        />
      ) : type === 'ELITE_MONSTER_KILL' ? (
        <MinimapIconAvatar
          bounty={(bounty ?? 0) > 0}
          file={
            monsterType === 'BARON_NASHOR'
              ? 'baron_minimap_icon.png'
              : monsterType === 'DRAGON'
              ? monsterSubType === 'CHEMTECH_DRAGON'
                ? `dragonchemtechmini.png`
                : `dragon${monsterSubType
                    ?.split('_')[0]
                    .toLowerCase()}minimap.png`
              : monsterType === 'RIFTHERALD'
              ? 'sru_riftherald_minimap_icon.png'
              : monsterType === 'HORDE'
              ? 'horde.png'
              : undefined
          }
        />
      ) : type === 'BUILDING_KILL' ? (
        <MinimapIconAvatar
          bounty={(bounty ?? 0) > 0}
          file={
            buildingType === 'TOWER_BUILDING'
              ? 'icon_ui_tower_minimap.png'
              : buildingType === 'INHIBITOR_BUILDING'
              ? 'icon_ui_inhibitor_minimap_v2.png'
              : undefined
          }
        />
      ) : type === 'TURRET_PLATE_DESTROYED' ? (
        <MinimapIconAvatar
          bounty={(bounty ?? 0) > 0}
          file={'icon_ui_tower_minimap_1_palisades.png'}
        />
      ) : (
        <></>
      )}
      <Typography variant='overline'>
        @ {formatDuration(Math.ceil(timestamp / 1000) * 1000)}
      </Typography>
    </Stack>
  );
};

type Diff = {
  timestamp: number;
  diff: number;
};

interface Props {
  matchId?: string;
}

export const GoldDiffChart: React.FC<Props> = ({ matchId }) => {
  const theme = useTheme(),
    { account } = useAccount(),
    { match } = useMatch(matchId),
    { timeline } = useMatchTimeline(matchId),
    participant = match?.info.participants.find(
      ({ puuid }) => puuid === account?.puuid,
    ),
    summonerTeamId = participant?.teamId,
    data = useMatchGoldTimeline(matchId),
    ticks = Array.from(new Array(4))
      .map(
        (_, i) =>
          Math.floor(((data?.at(-1)?.timestamp ?? 0) * (i / 4)) / 60000) *
          60000,
      )
      .concat(Math.floor((data?.at(-1)?.timestamp ?? 0) / 1000) * 1000),
    rollup = d3
      .flatRollup(
        d3
          .flatRollup(
            data ?? [],
            (ds) => ds.reduce((total, d) => d.totalGold + total, 0),
            (d) => d.timestamp,
            (d) => d.teamId,
          )
          .map(([timestamp, teamId, gold]) => ({ timestamp, teamId, gold })),
        (ds) =>
          ds.reduce(
            (diff, { teamId, gold }) =>
              teamId === summonerTeamId ? diff + gold : diff - gold,
            0,
          ),
        (d) => d.timestamp,
      )
      .map(([timestamp, diff]) => ({ timestamp, diff })),
    accessors = [(d: Diff) => d.timestamp, (d: Diff) => d.diff];

  return (
    <>
      <Typography variant='h5'>Team Gold Difference</Typography>
      {rollup?.length ? (
        <Container data={rollup ?? []} padding={[8, 16, 24, 32]}>
          <LineChart<Diff>
            accessors={accessors}
            color={theme.palette.divider}
            point={() => (
              <circle
                r={1.25}
                strokeWidth={3}
                stroke={theme.palette.divider}
                fill={theme.palette.text.secondary}
              />
            )}
            fill={[
              ['max', theme.palette.win.main],
              [0, theme.palette.win.main, 0.02],
              [0, theme.palette.background.paper, 0],
              [0, theme.palette.lose.main, 0.02],
              ['min', theme.palette.lose.main],
            ]}
          >
            <Grid stroke={theme.palette.divider} xs={ticks} />
            <XAxis<Diff>
              format={formatDuration}
              ticks={ticks}
              stroke={theme.palette.text.secondary}
            />
            <YAxis stroke={theme.palette.text.secondary} />
            <Tooltip<Diff, number>
              accessors={accessors}
              content={({ datum }) => {
                if (!match || !timeline || !datum) return <></>;

                const { timestamp, diff } = datum,
                  frame = timeline.info.frames.at(timestamp / 60000),
                  events = frame?.events.filter(({ type }) =>
                    [
                      'BUILDING_KILL',
                      'CHAMPION_KILL',
                      'ELITE_MONSTER_KILL',
                      'TURRET_PLATE_DESTROYED',
                    ].includes(type),
                  );

                return (
                  <Paper
                    elevation={2}
                    sx={{ p: 1, zIndex: theme.zIndex.tooltip }}
                  >
                    <Typography
                      color='text.secondary'
                      variant='body2'
                      component='div'
                    >
                      <Typography>
                        {diff.toLocaleString(undefined, {
                          signDisplay: 'exceptZero',
                        })}{' '}
                        gold @{' '}
                        {formatDuration(Math.round(timestamp / 60000) * 60000)}
                      </Typography>
                      <Divider sx={{ mb: 1 }} />
                      <Stack spacing={0.5}>
                        {events?.map((e, i) => (
                          <TooltipEvent
                            key={i}
                            summonerTeamId={summonerTeamId}
                            matchId={matchId}
                            event={e}
                          />
                        ))}
                      </Stack>
                    </Typography>
                  </Paper>
                );
              }}
            />
          </LineChart>
        </Container>
      ) : (
        <Skeleton
          variant='rounded'
          sx={{ width: '100%', aspectRatio: '16/9', height: 'auto', mt: 1.5 }}
        />
      )}
    </>
  );
};
