interface SVGMasteryIconProps {
  width?: number | string;
  height?: number | string;
  color?: string;
}

export const SVGMasteryIcon: React.FC<SVGMasteryIconProps> = ({
  width,
  height,
  color,
}) => {
  return (
    <svg
      preserveAspectRatio='xMidYMid meet'
      width={width ?? '12'}
      height={height ?? '12'}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.4082 5.58751C10.4309 5.11028 10.377 4.63439 10.2502 4.17771C10.3054 3.92786 10.3375 3.76678 10.3375 3.76678L11.0819 1.0166C11.0819 1.0166 10.6332 1.23321 9.68892 2.01012C8.87479 2.67995 8.76258 3.09168 8.74711 3.19245C9.21245 3.7883 9.48997 4.53953 9.48997 5.35594C9.48997 7.29202 7.92929 8.86152 6.00409 8.86152C4.0789 8.86152 2.51822 7.29202 2.51822 5.35594C2.51822 4.54441 2.79243 3.79728 3.25275 3.20316C3.23724 3.10226 3.12489 2.69057 2.31096 2.0209C1.36668 1.24399 0.917969 1.02738 0.917969 1.02738L1.66239 3.77755C1.66239 3.77755 1.68903 3.91132 1.73551 4.12413C1.60554 4.57074 1.54547 5.03684 1.55916 5.5056C1.58141 6.26754 1.79778 7.01098 2.18755 7.66474C2.57733 8.31851 3.12745 8.86071 3.78526 9.23943C4.14958 9.44917 4.54016 9.60489 4.94493 9.70328C4.99404 9.79941 5.05549 9.90695 5.13279 10.0223C5.34902 10.3451 5.98525 10.9838 5.98525 10.9838C5.98525 10.9838 6.62148 10.3451 6.83772 10.0223C6.91502 9.90695 6.97646 9.79941 7.02557 9.70328C7.4039 9.61132 7.77006 9.46922 8.11413 9.2795C8.77876 8.91302 9.33872 8.38109 9.74039 7.73466C10.1421 7.08822 10.372 6.34891 10.4082 5.58751Z'
        fill={color ?? '#A09B8C'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.99407 2.82766C4.94356 2.94194 4.89899 3.05178 4.8596 3.156C4.07262 3.57091 3.53572 4.40037 3.53572 5.35594C3.53572 6.72689 4.64085 7.83827 6.00409 7.83827C7.36734 7.83827 8.47247 6.72689 8.47247 5.35594C8.47247 4.3631 7.89287 3.5064 7.05539 3.10936C7.02051 3.01917 6.98178 2.92503 6.93875 2.82766C6.69211 2.26959 6.16799 1.73041 5.96641 1.58507C5.76483 1.73041 5.24071 2.26959 4.99407 2.82766ZM5.96641 6.66343C6.68445 6.66343 7.26655 6.07804 7.26655 5.35594C7.26655 4.63383 6.68445 4.04845 5.96641 4.04845C5.24836 4.04845 4.66627 4.63383 4.66627 5.35594C4.66627 6.07804 5.24836 6.66343 5.96641 6.66343Z'
        fill={color ?? '#A09B8C'}
      />
    </svg>
  );
};
