import { MatchV5ParticipantDto } from '@blakearoberts/riot-api-ts';
import { Stack, StackProps, styled, useTheme } from '@mui/material';
import { ItemAvatar } from 'components';

const ItemStack = styled(Stack)(({ theme }) => ({
  '> :first-of-type > div': {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  '> :last-of-type > div': {
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    borderRightWidth: 1,
  },
}));

interface Props extends StackProps {
  participant: MatchV5ParticipantDto;
  version?: string;
}

export const Items: React.FC<Props> = ({
  participant: { item0, item1, item2, item3, item4, item5, item6 },
  version,
  ...rest
}) => {
  const theme = useTheme(),
    items = [item0, item1, item2, item3, item4, item5, item6];
  return (
    <ItemStack
      direction='row'
      justifyContent='center'
      alignItems='center'
      height='100%'
      {...rest}
    >
      {items.map((item, i) => {
        return (
          <ItemAvatar
            key={i}
            itemId={item > 0 ? item.toString() : undefined}
            version={version}
            variant='square'
            size={32}
            link
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              borderRightWidth: 0,
              backgroundColor: theme.palette.background.paper,
            }}
          />
        );
      })}
    </ItemStack>
  );
};
