import React from 'react';

import { Parser, Node } from 'commonmark';
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Circle, Close } from '@mui/icons-material';

import { useFetchText } from 'hooks';

interface Props {
  onClose: () => void;
}

export const Changelog: React.FC<Props> = ({ onClose }) => {
  const { data: changelog } = useFetchText('/CHANGELOG.md'),
    theme = useTheme();

  const visit: (node: Node | null) => React.ReactNode = (n) => {
    if (n === null) return null;
    if (n.type === 'text') return n.literal;

    const children: React.ReactNode[] = [];
    let child = n.firstChild;
    do {
      children.push(visit(child));
      if (child === null || child === n.lastChild) break;
    } while ((child = child.next));

    switch (n.type) {
      case 'document':
        return <>{children}</>;
      case 'heading':
        return (
          <Typography
            key={n.sourcepos.toString()}
            variant={`h${n.level + 2}` as any}
            sx={{ pt: 2, pb: 1 }}
          >
            {children}
          </Typography>
        );
      case 'paragraph':
        return <Typography key={n.sourcepos.toString()}>{children}</Typography>;
      case 'list':
        return <List key={n.sourcepos.toString()}>{children}</List>;
      case 'item':
        return (
          <ListItem key={n.sourcepos.toString()} disablePadding sx={{ pl: 3 }}>
            <ListItemIcon>
              <Circle sx={{ width: 10 }} />
            </ListItemIcon>
            {children}
          </ListItem>
        );
      default:
        console.debug('unknown type', n.type, n);
        return (
          <React.Fragment key={n.sourcepos.toString()}>
            {children}
          </React.Fragment>
        );
    }
  };

  return (
    <Paper
      sx={{
        [theme.breakpoints.up('sm')]: {
          position: 'absolute',
          width: '75%',
          maxWidth: theme.breakpoints.values.md,
          height: '85%',
        },
        [theme.breakpoints.down('sm')]: {
          position: 'absolute',
          width: `calc(100% - ${theme.spacing(2)})`,
          height: `calc(100% - ${theme.spacing(2)})`,
        },
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          pb: 3,
          pr: 3,
          pl: 3,
        }}
      >
        {changelog === undefined ? (
          <Stack
            justifyContent='center'
            alignItems='center'
            sx={{ height: '100%' }}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <>{visit(new Parser().parse(changelog).walker().next().node)}</>
        )}
      </Box>
      <Box sx={{ position: 'sticky', top: 0, width: '100%' }}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <Close />
        </IconButton>
      </Box>
    </Paper>
  );
};
