import { MatchV5ParticipantDto } from '@blakearoberts/riot-api-ts';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';

import { formatScalar, formatPercentage } from 'helpers';

interface Props {
  participant: MatchV5ParticipantDto;
  teamKills: number;
  maxKp: number;
}

export const KdaCell: React.FC<Props> = ({
  participant: { kills, assists, deaths },
  teamKills,
  maxKp,
}) => {
  const kda = formatScalar((kills + assists) / deaths),
    kp = formatPercentage(kills + assists, teamKills, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).slice(0, -1),
    progress = ((kills + assists) / maxKp) * 100;
  return (
    <Stack flexWrap='nowrap' height='100%' justifyContent='center'>
      <Stack
        direction='row'
        justifyContent='space-between'
        sx={{ mr: { xs: 0.5, sm: 1 } }}
      >
        <Box display='flex' alignItems='center'>
          <Typography
            variant='body2'
            noWrap
            textOverflow='clip'
            fontSize={{ xs: '0.8rem', sm: '1.1rem' }}
          >
            {kda}
            <Typography
              variant='caption'
              fontSize='.75em'
              fontWeight={500}
              color='text.secondary'
            >
              {' '}
              KDA
            </Typography>
          </Typography>
        </Box>
        <Box display='flex' alignItems='center'>
          <Typography
            variant='body2'
            noWrap
            textOverflow='clip'
            fontSize={{ xs: '0.8rem', sm: '1.1rem' }}
          >
            {kp}
            <Typography
              variant='caption'
              fontSize='.75em'
              fontWeight={500}
              color='text.secondary'
            >
              {'% '}
              KP
            </Typography>
          </Typography>
        </Box>
      </Stack>
      <LinearProgress
        variant='determinate'
        value={progress}
        sx={{ height: 8, mr: { xs: 0.5, sm: 1 } }}
      />
    </Stack>
  );
};
