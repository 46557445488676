/**
 * StatBud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DataV1Position } from '../models/DataV1Position';
import { HttpFile } from '../http/http';

/**
* List of champion ID, position pairs signifying a tendency for the given champion ID to play that position.
*/
export class DataV1ChampionPositionDto {
    /**
    * Champion ID for this entry.
    */
    'championId': string;
    'position': DataV1Position;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "championId",
            "baseName": "championId",
            "type": "string",
            "format": ""
        },
        {
            "name": "position",
            "baseName": "position",
            "type": "DataV1Position",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return DataV1ChampionPositionDto.attributeTypeMap;
    }

    public constructor() {
    }
}



