import React, { useMemo, useState } from 'react';

import {
  Box,
  Checkbox,
  Divider,
  FormGroup,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';

import { DataV1Position, StatbudV1PlayerStatDto } from 'api/statbud-api';
import { useDDragonChampions, usePlayerStats } from 'hooks';
import { MatchQueuesXS } from 'types';
import { PositionFilter, PositionIcon, QueueFilter } from 'components/filters';
import { useLocalStorage } from 'usehooks-ts';
import { ChampionLink } from 'components/links';

type CellId =
  | 'championName'
  | 'teamPosition'
  | 'queueId'
  | 'count'
  | 'winRate'
  | 'kda'
  | 'avgDamagePerMin'
  | 'avgDamageTakenPerMin'
  | 'avgCSPerMin'
  | 'avgGoldPerMin';

interface Row {
  championNameId: string;
  championName: string;
  teamPosition: DataV1Position;
  queueId: number;
  count: number;
  wins: number;
  winRate: number;
  kda: number;
  avgDamagePerMin: number;
  avgDamageTakenPerMin: number;
  avgCSPerMin: number;
  avgGoldPerMin: number;
}

interface Column {
  label: string;
  align: 'left' | 'right';
  accessor: (row: Row, theme: Theme) => React.ReactNode;
  hidden: boolean;
}

type Columns = {
  [key in CellId]?: Column;
};

const columns: Columns = {
  championName: {
    label: 'Champion',
    align: 'left',
    accessor: ({ championName, championNameId }) => (
      <ChampionLink championKey={championNameId}>{championName}</ChampionLink>
    ),
    hidden: false,
  },
  teamPosition: {
    label: 'Position',
    align: 'left',
    accessor: ({ teamPosition }) => (
      <PositionIcon
        sx={{
          verticalAlign: 'text-bottom',
          width: 18,
          height: 18,
        }}
        position={teamPosition}
      />
    ),
    hidden: false,
  },
  queueId: {
    label: 'Queue',
    align: 'left',
    accessor: ({ queueId }) => MatchQueuesXS.get(queueId),
    hidden: false,
  },
  count: {
    label: 'Match Count',
    align: 'right',
    accessor: ({ count }) => count.toLocaleString(),
    hidden: false,
  },
  winRate: {
    label: 'W:L',
    align: 'right',
    accessor: ({ count, wins, winRate }, theme) => {
      return (
        <Typography variant='body2'>
          {wins.toLocaleString()}:{(count - wins).toLocaleString()}{' '}
          <Typography variant='caption'>
            (
            <Typography
              variant='caption'
              color={
                winRate >= 0.51
                  ? theme.palette.win.main
                  : winRate <= 0.49
                  ? theme.palette.lose.main
                  : undefined
              }
            >
              {winRate.toLocaleString(undefined, {
                style: 'percent',
                maximumFractionDigits: 0,
              })}
            </Typography>
            )
          </Typography>
        </Typography>
      );
    },
    hidden: false,
  },
  kda: {
    label: 'KDA',
    align: 'right',
    accessor: ({ kda }, theme) => (
      <Typography
        variant='body2'
        color={
          kda >= 1.8
            ? theme.palette.win.main
            : kda <= 1.2
            ? theme.palette.lose.main
            : undefined
        }
      >
        {kda.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 1,
        })}
      </Typography>
    ),
    hidden: false,
  },
  avgDamagePerMin: {
    label: 'Dmg/Min',
    align: 'right',
    accessor: ({ avgDamagePerMin }) =>
      avgDamagePerMin.toLocaleString(undefined, {
        maximumFractionDigits: 0,
      }),
    hidden: false,
  },
  avgCSPerMin: {
    label: 'CS/Min',
    align: 'right',
    accessor: ({ avgCSPerMin }) =>
      avgCSPerMin.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
    hidden: false,
  },
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const Stats: React.FC = () => {
  const theme = useTheme(),
    { stats } = usePlayerStats(),
    { champions } = useDDragonChampions(),
    [queues, setQueues] = useLocalStorage<number[]>('stats-queue-filter', []),
    [positions, setPositions] = useState<string[]>(() => []),
    [groupByChampion, setGroupByChampion] = useState(true),
    [groupByQueue, setGroupByQueue] = useState(true),
    [groupByPosition, setGroupByPosition] = useState(true),
    filteredStats = useMemo(
      () =>
        stats
          ?.filter((s) => queues.length === 0 || queues.includes(s.queueId))
          .filter(
            (s) => positions.length === 0 || positions.includes(s.teamPosition),
          ),
      [stats, queues, positions],
    ),
    groupedStats = useMemo(
      () =>
        filteredStats?.reduce((groups, s) => {
          const key = [
            groupByChampion ? s.championName : '',
            groupByQueue ? s.queueId : '',
            groupByPosition ? s.teamPosition : '',
          ].join('/');
          let group = groups[key] ?? {
            championName: groupByChampion ? s.championName : '',
            queueId: groupByQueue ? s.queueId : 0,
            teamPosition: groupByPosition
              ? s.teamPosition
              : DataV1Position.None,
            count: 0,
            wins: 0,
            kills: 0,
            deaths: 0,
            assists: 0,
            damage: 0,
            damageTaken: 0,
            cs: 0,
            goldEarned: 0,
            timePlayed: 0,
          };
          group.count += s.count;
          group.wins += s.wins;
          group.kills += s.kills;
          group.deaths += s.deaths;
          group.assists += s.assists;
          group.damage += s.damage;
          group.damageTaken += s.damageTaken;
          group.cs += s.cs;
          group.goldEarned += s.goldEarned;
          group.timePlayed += s.timePlayed;
          groups[key] = group;
          return groups;
        }, {} as { [key: string]: StatbudV1PlayerStatDto }),
      [filteredStats, groupByChampion, groupByQueue, groupByPosition],
    ),
    rows = useMemo(
      () =>
        Object.values(groupedStats ?? {}).map((g) => ({
          championNameId: g.championName,
          championName:
            champions?.find((c) => c.id === g.championName)?.name ?? '',
          teamPosition: g.teamPosition,
          queueId: g.queueId,
          count: g.count,
          wins: g.wins,
          winRate: g.wins / g.count,
          kda: (g.kills + g.assists) / g.deaths,
          avgDamagePerMin: (g.damage * 60) / g.timePlayed,
          avgDamageTakenPerMin: (g.damageTaken * 60) / g.timePlayed,
          avgCSPerMin: (g.cs * 60) / g.timePlayed,
          avgGoldPerMin: (g.goldEarned * 60) / g.timePlayed,
        })) ?? [],
      [champions, groupedStats],
    );

  const [order, setOrder] = useState<Order>('desc'),
    [orderBy, setOrderBy] = useState<CellId>('count'),
    handleSort = (property: CellId) => {
      const isDesc = orderBy === property && order === 'desc';
      setOrder(isDesc ? 'asc' : 'desc');
      setOrderBy(property);
    };

  const [page, setPage] = useState(0),
    [rowsPerPage, setRowsPerPage] = useState(10),
    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

  // avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - rows.length),
    visibleRows = useMemo(
      () =>
        rows
          .slice()
          .sort(getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
      [rows, order, orderBy, page, rowsPerPage],
    );

  return (
    <>
      <Box p={theme.spacing(2, 2, 0)}>
        <Stack direction='row' alignItems='center'>
          <Typography variant='h5' flex={1}>
            Stats
            <Typography variant='subtitle2' component='span'>
              {' '}
              Last 90 days
            </Typography>
          </Typography>
        </Stack>
        <Typography variant='subtitle1'>
          Filter
          <Typography variant='caption' color='text.secondary'>
            {' '}
            by queue, or position. This removes matches from the results.
          </Typography>
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          p={theme.spacing(0, 2)}
          spacing={2}
        >
          <Box flex={1} display='flex' alignItems='center'>
            <QueueFilter
              value={queues}
              onChange={(queues) => setQueues(queues)}
            />
          </Box>
          <PositionFilter
            onChange={(_, positions) => {
              setPositions(positions);
            }}
            alignItems='center'
            justifyContent='space-evenly'
            hideNone
            flex={1}
          />
        </Stack>
        <Typography variant='subtitle1'>
          Group
          <Typography variant='caption' color='text.secondary'>
            {' '}
            by champion, queue, and/or position. Matches that share a group are
            combined.
          </Typography>
        </Typography>
        <FormGroup
          sx={{
            flexDirection: 'row',
            p: theme.spacing(0, 2),
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={groupByChampion}
                onChange={(_, checked) => setGroupByChampion(checked)}
              />
            }
            label='champion'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={groupByPosition}
                onChange={(_, checked) => setGroupByPosition(checked)}
              />
            }
            label='position'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={groupByQueue}
                onChange={(_, checked) => setGroupByQueue(checked)}
              />
            }
            label='queue'
          />
        </FormGroup>
      </Box>
      <Divider sx={{ m: theme.spacing(1, 0, 0) }} />

      <TableContainer>
        <Table
          size='small'
          sx={{ position: 'relative', borderCollapse: 'separate' }}
        >
          <TableHead
            sx={{
              height: 49,
              position: 'sticky',
              top: 0,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <TableRow>
              {Object.entries(columns)
                .filter(
                  ([id, { hidden }]) =>
                    !hidden &&
                    !(id === 'championName' && !groupByChampion) &&
                    !(id === 'queueId' && !groupByQueue) &&
                    !(id === 'teamPosition' && !groupByPosition),
                )
                .map(([id, { label, align }], i, arr) => (
                  <TableCell
                    key={id}
                    align={align}
                    padding={
                      i === 0 || i === arr.length - 1 || id === 'winRate'
                        ? 'normal'
                        : 'none'
                    }
                    sortDirection={orderBy === id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? order : 'asc'}
                      onClick={() => handleSort(id as CellId)}
                    >
                      {label}
                    </TableSortLabel>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row, i) => {
              return (
                <TableRow hover tabIndex={-1} key={i}>
                  {Object.entries(columns)
                    .filter(
                      ([id, { hidden }]) =>
                        !hidden &&
                        !(id === 'championName' && !groupByChampion) &&
                        !(id === 'queueId' && !groupByQueue) &&
                        !(id === 'teamPosition' && !groupByPosition),
                    )
                    .map(([id, { align, accessor }], i, arr) => (
                      <TableCell
                        key={id}
                        align={align}
                        padding={
                          i === 0 || i === arr.length - 1 ? 'normal' : 'none'
                        }
                      >
                        {accessor(row, theme)}
                      </TableCell>
                    ))}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        sx={{
          flexShrink: 0,
          borderTop: `1px solid ${theme.vars.palette.TableCell.border}`,
        }}
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, page) => setPage(page)}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
