import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

export const Tool: React.FC = () => {
  return (
    <Box sx={{ overflowY: 'auto' }}>
      <Outlet />
    </Box>
  );
};
