import { Stack } from '@mui/material';

import { ItemTagKey } from 'types';

import { ItemTagChip } from './ItemTagChip';

interface Props {
  tags?: ItemTagKey[];
  selected?: ItemTagKey[];
  onChange?: (tag: ItemTagKey) => void;
}

export const ItemTagChips: React.FC<Props> = ({ tags, selected, onChange }) => {
  return (
    <Stack
      sx={{
        flexFlow: 'row wrap',
        columnGap: 1,
        rowGap: 0.5,
        minWidth: 'fit-content',
        alignSelf: 'flex-start',
        alignContent: 'space-evenly',
      }}
    >
      {tags?.map((tag) => (
        <ItemTagChip
          key={tag}
          tag={tag}
          selected={selected?.includes(tag)}
          onClick={(_, tag) => onChange?.(tag)}
        />
      ))}
    </Stack>
  );
};
