import { AcUnit, LocalFireDepartment } from '@mui/icons-material';
import { Chip, Typography, useTheme } from '@mui/material';

import { RankedQueueType, leagueEntryToTotalLp, queueTypeToId } from 'helpers';
import {
  useAccount,
  useStatbudMatchIdsForLeagueEntries,
  useStatbudMatchesByMatchIds,
} from 'hooks';

enum Outcome {
  Win,
  Loss,
  Remake,
}

interface Props {
  queueType: RankedQueueType;
}

export const StreakChip: React.FC<Props> = ({ queueType }) => {
  const theme = useTheme(),
    { account } = useAccount(),
    { matches } = useStatbudMatchesByMatchIds([queueTypeToId(queueType)!]),
    { entries } = useStatbudMatchIdsForLeagueEntries(queueType);

  if (matches === undefined || entries === undefined) return <></>;

  const outcomes = matches
      .filter(({ info: { queueId } }) => queueId === queueTypeToId(queueType))
      .sort(
        (
          { info: { gameStartTimestamp: a } },
          { info: { gameStartTimestamp: b } },
        ) => b - a,
      )
      .map(({ metadata: { matchId }, info: { participants } }) => ({
        outcome: participants.at(0)?.gameEndedInEarlySurrender
          ? Outcome.Remake
          : participants.find(({ puuid }) => puuid === account?.puuid)?.win
          ? Outcome.Win
          : Outcome.Loss,
        entry: entries?.find(({ matchId: id }) => id === matchId),
      })),
    first = outcomes?.at(0),
    streakEndLp = leagueEntryToTotalLp(first?.entry),
    streakType = first?.outcome;

  let streak = 1,
    streaking = true,
    prevEntry = undefined;
  for (let i = 1; i < outcomes.length; i++) {
    const cur = outcomes[i];
    if (cur.outcome === Outcome.Remake) continue;
    else if (cur.outcome === streakType && streaking) streak++;
    else streaking = false;
    if (cur.entry !== undefined) prevEntry = cur.entry;
    if (streaking === false && prevEntry !== undefined) break;
  }

  const streakStartLp = prevEntry
    ? leagueEntryToTotalLp(prevEntry)
    : streakEndLp;
  let lpDelta =
    streakEndLp === undefined || streakStartLp === undefined
      ? undefined
      : streakEndLp - streakStartLp;

  return (
    <Chip
      size='small'
      sx={{
        '&.MuiChip-filled': {
          border: (theme) =>
            `1px solid rgba(${
              streakType === Outcome.Win
                ? theme.vars.palette.hot.mainChannel
                : theme.vars.palette.cold.mainChannel
            } / 0.3)`,
          backgroundColor: `rgba(${
            streakType === Outcome.Win
              ? theme.vars.palette.hot.mainChannel
              : theme.vars.palette.cold.mainChannel
          } / 0.3)`,
          [theme.getColorSchemeSelector('dark')]: {
            border: (theme) =>
              `1px solid rgba(${
                streakType === Outcome.Win
                  ? theme.vars.palette.hot.mainChannel
                  : theme.vars.palette.cold.mainChannel
              } / ${theme.vars.palette.action.selectedOpacity})`,
            backgroundColor: `rgba(${
              streakType === Outcome.Win
                ? theme.vars.palette.hot.mainChannel
                : theme.vars.palette.cold.mainChannel
            } / ${theme.vars.palette.action.selectedOpacity})`,
          },
        },
      }}
      icon={streakType === Outcome.Win ? <LocalFireDepartment /> : <AcUnit />}
      label={
        <Typography variant='caption' fontWeight='bold'>
          {streak}{' '}
          {streak === 1
            ? streakType === Outcome.Win
              ? 'win'
              : 'loss'
            : streakType === Outcome.Win
            ? 'wins'
            : 'losses'}
          {lpDelta !== undefined ? (
            <Typography variant='caption' fontSize='0.8em' fontWeight='bold'>
              {' '}
              (
              {lpDelta.toLocaleString(undefined, {
                signDisplay: 'exceptZero',
              })}{' '}
              LP)
            </Typography>
          ) : (
            ''
          )}
        </Typography>
      }
    />
  );
};
