export const formatDate = (date: Date, size?: 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'small':
      return '';
    case 'medium':
      return date.toLocaleDateString(undefined, {
        day: 'numeric',
        month: 'short',
      });
    case 'large':
    default:
      return date.toDateString();
  }
};

export const formatTime = (date: Date) =>
  date.toLocaleString(undefined, {
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
  });

export const formatMatchDuration = (duration: number) => {
  const format = (s: number) => {
    const h = Math.trunc(s / 3600),
      m = Math.trunc((s % 3600) / 60);
    s = s % 60;
    return h === 0 ? `${m}m${s}s` : `${h}h${m}m${s}s`;
  };
  return format(duration);
};

export const formatDuration = (ms: number, round: boolean = false) => {
  const format = (duration: number) => {
    const m = Math.trunc(duration / 60000),
      s = Math.trunc((duration % 60000) / 1000).toLocaleString(undefined, {
        minimumIntegerDigits: 2,
        maximumFractionDigits: 0,
      }),
      ms = duration % 1000;
    return ms > 0 && !round ? `${m}:${s}.${ms}` : `${m}:${s}`;
  };
  return format(ms);
};

export const formatRate = (
  value: number,
  duration: number,
  rate: number = 60,
  options?: Intl.NumberFormatOptions,
) =>
  (value / Math.max(duration / rate, 1)).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...(options ?? {}),
  });

export const formatScalar = (
  value: number,
  options?: Intl.NumberFormatOptions,
) =>
  value.toLocaleString(undefined, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    ...(options ?? {}),
  });

export const formatRatio = (
  value: number,
  total: number,
  options?: Intl.NumberFormatOptions,
) =>
  (value / Math.max(total, 1)).toLocaleString(undefined, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    ...(options ?? {}),
  });

export const formatPercentage = (
  value: number,
  total: number,
  options?: Intl.NumberFormatOptions,
) =>
  (value / Math.max(total, 1)).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    ...(options ?? {}),
  });
