const tiers: { [key: string]: number } = {
  IRON: 0,
  BRONZE: 400,
  SILVER: 800,
  GOLD: 1200,
  PLATINUM: 1600,
  EMERALD: 2000,
  DIAMOND: 2400,
  MASTER: 2800,
  GRANDMASTER: 3200,
  CHALLENGER: 3600,
};

const ranks: { [key: string]: number } = {
  IV: 0,
  III: 100,
  II: 200,
  I: 300,
};

export const leagueEntryToTotalLp = (e?: {
  tier?: string;
  rank?: string;
  leaguePoints: number;
}) =>
  e === undefined
    ? undefined
    : tiers[e.tier ?? 'IRON'] + ranks[e.rank ?? 'IV'] + e.leaguePoints;

export const totalLpToLeagueEntryTuple = (
  totalLp: number,
): [string, string, number] => {
  const tier = Object.keys(tiers)[Math.floor(totalLp / 400)],
    rank = Object.keys(ranks)[Math.floor((totalLp % 400) / 100)],
    lp = totalLp % 100;
  return [tier, rank, lp];
};
