import { useNavigate } from 'react-router-dom';

import { Insights } from '@mui/icons-material';
import { Box, Link, Stack, Typography, useTheme } from '@mui/material';

export const About: React.FC = () => {
  const theme = useTheme(),
    navigate = useNavigate(),
    splashes = ['Ezreal', 'Ashe', 'Kaisa'],
    id = splashes[Math.floor(Math.random() * splashes.length)];
  return (
    <Stack sx={{ p: 4 }}>
      <Stack direction='row' spacing={2}>
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
            width: '40%',
            backgroundImage: `url(https://ddragon.leagueoflegends.com/cdn/img/champion/splash/${id}_0.jpg)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: '75% 0%',
            borderRadius: `${theme.shape.borderRadius}px`,
          }}
        />
        <Stack flex={1} spacing={1}>
          <Typography variant='h5'>Welcome to</Typography>
          <Stack direction='row' alignItems='center' spacing={1}>
            <Insights fontSize='large' />
            <Typography variant='h3'>StatBud</Typography>
          </Stack>
          <Typography variant='body1'>
            StatBud is a League of Legends companion app. Track match history,
            LP progress, champion mastery, and more!
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ pt: 4 }} spacing={2} textAlign='center'>
        <Typography>
          To get started, type your summoner name in the search bar above.
        </Typography>
        <Typography>
          Not interested in your stats? Checkout information related to a
          specific{' '}
          <Link
            underline='hover'
            href='/data/champions'
            onClick={(e) => {
              e.preventDefault();
              navigate('/data/champions');
            }}
          >
            champion
          </Link>
          , or use the{' '}
          <Link
            underline='hover'
            href='/tool/team-picker'
            onClick={(e) => {
              e.preventDefault();
              navigate('/tool/team-picker');
            }}
          >
            Team Picker
          </Link>{' '}
          tool and challenge your friends to a custom match.
        </Typography>
        <Typography>
          Have feedback, a feature idea, or a bug you'd like to report? Join the{' '}
          <Link
            href='https://discord.gg/2RmsdZJJ6a'
            target='_blank'
            underline='hover'
          >
            Discord
          </Link>
          !
        </Typography>
      </Stack>
    </Stack>
  );
};
