import { ResponseContext, RequestContext, HttpFile, HttpInfo } from '../http/http';
import { Configuration} from '../configuration'
import { Observable, of, from } from '../rxjsStub';
import {mergeMap, map} from  '../rxjsStub';
import { DataV1ChampionPositionDto } from '../models/DataV1ChampionPositionDto';
import { DataV1Position } from '../models/DataV1Position';
import { MatchV5BanDto } from '../models/MatchV5BanDto';
import { MatchV5ChallengesDto } from '../models/MatchV5ChallengesDto';
import { MatchV5InfoDto } from '../models/MatchV5InfoDto';
import { MatchV5MatchDto } from '../models/MatchV5MatchDto';
import { MatchV5MetadataDto } from '../models/MatchV5MetadataDto';
import { MatchV5MissionsDto } from '../models/MatchV5MissionsDto';
import { MatchV5ObjectiveDto } from '../models/MatchV5ObjectiveDto';
import { MatchV5ObjectivesDto } from '../models/MatchV5ObjectivesDto';
import { MatchV5ParticipantDto } from '../models/MatchV5ParticipantDto';
import { MatchV5PerkStatsDto } from '../models/MatchV5PerkStatsDto';
import { MatchV5PerkStyleDto } from '../models/MatchV5PerkStyleDto';
import { MatchV5PerkStyleSelectionDto } from '../models/MatchV5PerkStyleSelectionDto';
import { MatchV5PerksDto } from '../models/MatchV5PerksDto';
import { MatchV5TeamDto } from '../models/MatchV5TeamDto';
import { StatbudV1ChampionStatsDto } from '../models/StatbudV1ChampionStatsDto';
import { StatbudV1LeagueEntryDto } from '../models/StatbudV1LeagueEntryDto';
import { StatbudV1LeagueEntryWithMatchIdDto } from '../models/StatbudV1LeagueEntryWithMatchIdDto';
import { StatbudV1PlayerStatDto } from '../models/StatbudV1PlayerStatDto';
import { StatbudV1Rank } from '../models/StatbudV1Rank';
import { StatbudV1Tier } from '../models/StatbudV1Tier';

import { DataV1ApiRequestFactory, DataV1ApiResponseProcessor} from "../apis/DataV1Api";
export class ObservableDataV1Api {
    private requestFactory: DataV1ApiRequestFactory;
    private responseProcessor: DataV1ApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: DataV1ApiRequestFactory,
        responseProcessor?: DataV1ApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new DataV1ApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new DataV1ApiResponseProcessor();
    }

    /**
     * Get the positions each champion most commonly plays
     * Get the positions each champion most commonly plays
     */
    public dataV1GetChampionPositionsWithHttpInfo(_options?: Configuration): Observable<HttpInfo<Array<DataV1ChampionPositionDto>>> {
        const requestContextPromise = this.requestFactory.dataV1GetChampionPositions(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.dataV1GetChampionPositionsWithHttpInfo(rsp)));
            }));
    }

    /**
     * Get the positions each champion most commonly plays
     * Get the positions each champion most commonly plays
     */
    public dataV1GetChampionPositions(_options?: Configuration): Observable<Array<DataV1ChampionPositionDto>> {
        return this.dataV1GetChampionPositionsWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<Array<DataV1ChampionPositionDto>>) => apiResponse.data));
    }

}

import { MatchV5ApiRequestFactory, MatchV5ApiResponseProcessor} from "../apis/MatchV5Api";
export class ObservableMatchV5Api {
    private requestFactory: MatchV5ApiRequestFactory;
    private responseProcessor: MatchV5ApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: MatchV5ApiRequestFactory,
        responseProcessor?: MatchV5ApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new MatchV5ApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new MatchV5ApiResponseProcessor();
    }

    /**
     * Get a match by match id
     * Get matches by match IDs
     * @param matchIds 
     */
    public matchV5GetMatchesWithHttpInfo(matchIds: Array<string>, _options?: Configuration): Observable<HttpInfo<Array<MatchV5MatchDto>>> {
        const requestContextPromise = this.requestFactory.matchV5GetMatches(matchIds, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.matchV5GetMatchesWithHttpInfo(rsp)));
            }));
    }

    /**
     * Get a match by match id
     * Get matches by match IDs
     * @param matchIds 
     */
    public matchV5GetMatches(matchIds: Array<string>, _options?: Configuration): Observable<Array<MatchV5MatchDto>> {
        return this.matchV5GetMatchesWithHttpInfo(matchIds, _options).pipe(map((apiResponse: HttpInfo<Array<MatchV5MatchDto>>) => apiResponse.data));
    }

}

import { StatbudV1ApiRequestFactory, StatbudV1ApiResponseProcessor} from "../apis/StatbudV1Api";
export class ObservableStatbudV1Api {
    private requestFactory: StatbudV1ApiRequestFactory;
    private responseProcessor: StatbudV1ApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: StatbudV1ApiRequestFactory,
        responseProcessor?: StatbudV1ApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new StatbudV1ApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new StatbudV1ApiResponseProcessor();
    }

    /**
     * Get latest per day league entries for the last 30 days
     * Get latest per day league entries for the last 30 days
     * @param encryptedSummonerId 
     * @param queueType 
     */
    public statbudV1GetLeagueEntriesHistoryForSummonerWithHttpInfo(encryptedSummonerId: string, queueType: 'RANKED_SOLO_5x5' | 'RANKED_FLEX_SR', _options?: Configuration): Observable<HttpInfo<Array<StatbudV1LeagueEntryDto>>> {
        const requestContextPromise = this.requestFactory.statbudV1GetLeagueEntriesHistoryForSummoner(encryptedSummonerId, queueType, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.statbudV1GetLeagueEntriesHistoryForSummonerWithHttpInfo(rsp)));
            }));
    }

    /**
     * Get latest per day league entries for the last 30 days
     * Get latest per day league entries for the last 30 days
     * @param encryptedSummonerId 
     * @param queueType 
     */
    public statbudV1GetLeagueEntriesHistoryForSummoner(encryptedSummonerId: string, queueType: 'RANKED_SOLO_5x5' | 'RANKED_FLEX_SR', _options?: Configuration): Observable<Array<StatbudV1LeagueEntryDto>> {
        return this.statbudV1GetLeagueEntriesHistoryForSummonerWithHttpInfo(encryptedSummonerId, queueType, _options).pipe(map((apiResponse: HttpInfo<Array<StatbudV1LeagueEntryDto>>) => apiResponse.data));
    }

    /**
     * Get match IDs for a summoner
     * Get match IDs for a summoner
     * @param puuid 
     * @param startTime 
     * @param endTime 
     * @param queues 
     * @param type 
     * @param count 
     */
    public statbudV1GetMatchIdsByPuuidWithHttpInfo(puuid: string, startTime?: number, endTime?: number, queues?: Array<400 | 420 | 430 | 440 | 450 | 900 | 1300 | 1700>, type?: 'ranked' | 'normal' | 'tourney' | 'tutorial', count?: number, _options?: Configuration): Observable<HttpInfo<Array<string>>> {
        const requestContextPromise = this.requestFactory.statbudV1GetMatchIdsByPuuid(puuid, startTime, endTime, queues, type, count, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.statbudV1GetMatchIdsByPuuidWithHttpInfo(rsp)));
            }));
    }

    /**
     * Get match IDs for a summoner
     * Get match IDs for a summoner
     * @param puuid 
     * @param startTime 
     * @param endTime 
     * @param queues 
     * @param type 
     * @param count 
     */
    public statbudV1GetMatchIdsByPuuid(puuid: string, startTime?: number, endTime?: number, queues?: Array<400 | 420 | 430 | 440 | 450 | 900 | 1300 | 1700>, type?: 'ranked' | 'normal' | 'tourney' | 'tutorial', count?: number, _options?: Configuration): Observable<Array<string>> {
        return this.statbudV1GetMatchIdsByPuuidWithHttpInfo(puuid, startTime, endTime, queues, type, count, _options).pipe(map((apiResponse: HttpInfo<Array<string>>) => apiResponse.data));
    }

    /**
     * Get match IDs correlated with league entries for a summoner
     * Get match IDs correlated with league entries for a summoner
     * @param puuid 
     * @param queueType 
     */
    public statbudV1GetMatchIdsForLeagueEntriesByPUUIDWithHttpInfo(puuid: string, queueType: 'RANKED_SOLO_5x5' | 'RANKED_FLEX_SR', _options?: Configuration): Observable<HttpInfo<Array<StatbudV1LeagueEntryWithMatchIdDto>>> {
        const requestContextPromise = this.requestFactory.statbudV1GetMatchIdsForLeagueEntriesByPUUID(puuid, queueType, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.statbudV1GetMatchIdsForLeagueEntriesByPUUIDWithHttpInfo(rsp)));
            }));
    }

    /**
     * Get match IDs correlated with league entries for a summoner
     * Get match IDs correlated with league entries for a summoner
     * @param puuid 
     * @param queueType 
     */
    public statbudV1GetMatchIdsForLeagueEntriesByPUUID(puuid: string, queueType: 'RANKED_SOLO_5x5' | 'RANKED_FLEX_SR', _options?: Configuration): Observable<Array<StatbudV1LeagueEntryWithMatchIdDto>> {
        return this.statbudV1GetMatchIdsForLeagueEntriesByPUUIDWithHttpInfo(puuid, queueType, _options).pipe(map((apiResponse: HttpInfo<Array<StatbudV1LeagueEntryWithMatchIdDto>>) => apiResponse.data));
    }

    /**
     * Get stats for a player
     * Get stats for a player
     * @param puuid 
     */
    public statbudV1GetPlayerStatsWithHttpInfo(puuid: string, _options?: Configuration): Observable<HttpInfo<Array<StatbudV1PlayerStatDto>>> {
        const requestContextPromise = this.requestFactory.statbudV1GetPlayerStats(puuid, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.statbudV1GetPlayerStatsWithHttpInfo(rsp)));
            }));
    }

    /**
     * Get stats for a player
     * Get stats for a player
     * @param puuid 
     */
    public statbudV1GetPlayerStats(puuid: string, _options?: Configuration): Observable<Array<StatbudV1PlayerStatDto>> {
        return this.statbudV1GetPlayerStatsWithHttpInfo(puuid, _options).pipe(map((apiResponse: HttpInfo<Array<StatbudV1PlayerStatDto>>) => apiResponse.data));
    }

    /**
     * Get champion stats for an account puuid
     * Get champion stats for an account puuid
     * @param puuid 
     */
    public statbudV1GetSummonerChampionStatsWithHttpInfo(puuid: string, _options?: Configuration): Observable<HttpInfo<Array<StatbudV1ChampionStatsDto>>> {
        const requestContextPromise = this.requestFactory.statbudV1GetSummonerChampionStats(puuid, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.statbudV1GetSummonerChampionStatsWithHttpInfo(rsp)));
            }));
    }

    /**
     * Get champion stats for an account puuid
     * Get champion stats for an account puuid
     * @param puuid 
     */
    public statbudV1GetSummonerChampionStats(puuid: string, _options?: Configuration): Observable<Array<StatbudV1ChampionStatsDto>> {
        return this.statbudV1GetSummonerChampionStatsWithHttpInfo(puuid, _options).pipe(map((apiResponse: HttpInfo<Array<StatbudV1ChampionStatsDto>>) => apiResponse.data));
    }

}
