import {
  AccountV1Api,
  ChampionMasteryV4Api,
  createConfiguration,
  LeagueV4Api,
  LolChallengesV1Api,
  MatchV5Api,
  server1,
  ServerConfiguration,
  SpectatorV5Api,
  SummonerV4Api,
} from '@blakearoberts/riot-api-ts';

export class Riot {
  private static APiConfig = createConfiguration({
    baseServer: new ServerConfiguration(
      window.location.origin,
      server1.getConfiguration(),
    ),
  });

  static AccountV1 = new AccountV1Api(Riot.APiConfig);
  static ChampionMasteryV4 = new ChampionMasteryV4Api(Riot.APiConfig);
  static LeagueV4 = new LeagueV4Api(Riot.APiConfig);
  static LolChallengesV1 = new LolChallengesV1Api(Riot.APiConfig);
  static MatchV5 = new MatchV5Api(Riot.APiConfig);
  static SpectatorV5 = new SpectatorV5Api(Riot.APiConfig);
  static SummonerV4 = new SummonerV4Api(Riot.APiConfig);
}
