import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Divider,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import { useDDragonData } from 'hooks';

export const Runes: React.FC = () => {
  const { runes } = useDDragonData(),
    navigate = useNavigate();
  return (
    <Box sx={{ p: 2, overflowY: 'auto' }}>
      <Typography variant='h5'>Runes</Typography>
      <Divider sx={{ m: (theme) => theme.spacing(2, 0) }} />
      <Grid container justifyContent='center' alignItems='center' spacing={2}>
        {runes?.map(({ id, name, icon, slots }, i) => (
          <Grid key={i} xs={12} sm={4}>
            <Card elevation={1}>
              <CardActionArea
                onClick={() => navigate(`${id}`)}
                sx={{ p: (theme) => theme.spacing(0, 1, 1) }}
              >
                <Stack
                  direction='row'
                  spacing={2}
                  justifyContent='center'
                  alignItems='center'
                  sx={{ p: 2 }}
                >
                  <Avatar
                    variant='square'
                    alt={name}
                    src={`https://ddragon.leagueoflegends.com/cdn/img/${icon}`}
                  />
                  <Typography variant='h5'>{name}</Typography>
                </Stack>
                {slots.map(({ runes }, i) => (
                  <Stack
                    key={i}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                      p: (theme) =>
                        theme.spacing(0, i === 0 ? 0 : 2, i === 0 ? 2 : 2),
                    }}
                  >
                    {runes.map(({ id, icon, name }, j) => (
                      <Box key={j}>
                        <Avatar
                          variant='square'
                          key={id}
                          alt={name}
                          src={`https://ddragon.leagueoflegends.com/cdn/img/${icon}`}
                          sx={
                            i === 0
                              ? {
                                  width: 45,
                                  height: 45,
                                }
                              : {
                                  width: 35,
                                  height: 35,
                                }
                          }
                        />
                      </Box>
                    ))}
                  </Stack>
                ))}
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
