import { MatchV5MatchDto } from '@blakearoberts/riot-api-ts';
import useSWR, { useSWRConfig } from 'swr';

import { StatBud } from 'api';
import {
  RankedQueueType,
  leagueEntryToTotalLp,
  rankedQueueIdToType,
} from 'helpers';
import { useAccount, useMatch, useMatchIds, useSummoner } from 'hooks';

export const usePlayerStats = () => {
  const { account } = useAccount(),
    key = account
      ? `/lol/statbud/v1/player/stats/${account?.puuid}`
      : undefined,
    { data: stats, isLoading } = useSWR(key, () =>
      StatBud.StatbudV1.statbudV1GetPlayerStats({
        puuid: account!.puuid,
      }),
    );
  return {
    stats,
    loading: isLoading,
  };
};

export const useSummonerChampionStats = () => {
  const { account } = useAccount(),
    key = account
      ? `/lol/statbud/v1/summoner/champions?puuid=${account?.puuid}`
      : undefined,
    { data: champions, isLoading } = useSWR(key, () =>
      StatBud.StatbudV1.statbudV1GetSummonerChampionStats({
        puuid: account!.puuid,
      }),
    );
  return {
    champions,
    loading: isLoading,
  };
};

export const useChampionPositions = () => {
  const { data: positions } = useSWR('/lol/data/v1/champion-positions', () =>
    StatBud.DataV1.dataV1GetChampionPositions(),
  );
  return { positions };
};

export const useStatbudLeaguesHistory = (
  queue?: 'RANKED_SOLO_5x5' | 'RANKED_FLEX_SR',
) => {
  const { summoner } = useSummoner(),
    key =
      summoner && queue
        ? `/lol/statbud/v1/entries/history/${summoner.id}/${queue}`
        : undefined,
    { data: entries } = useSWR(key, () =>
      StatBud.StatbudV1.statbudV1GetLeagueEntriesHistoryForSummoner({
        encryptedSummonerId: summoner!.id,
        queueType: queue!,
      }),
    );
  return { entries };
};

// TODO: make hook using this one that holds cursor state, refreshes on an
//       interval, returns a callback to load more matches (using cursor), and
//       returns all matches from the cache that match the set of queues
//       specified
export const useStatbudMatchesByMatchIds = (
  queues?: number[],
  type?: 'ranked' | 'normal',
  limit: number = 20,
) => {
  const { matchIds: ids, loading: matchIdsLoading } = useMatchIds(
      queues !== undefined && queues.length > 0 ? queues[0] : undefined,
      type,
      limit,
    ),
    { cache, mutate } = useSWRConfig(),
    { data: matches, isLoading: matchesLoading } = useSWR(
      ids ? `lol/match/v5/matches?matchIds=${ids.join(',')}` : undefined,
      async () => {
        if (ids === undefined) return undefined;

        const key = (matchId: string) => `/lol/match/v5/matches/${matchId}`;

        const matches: MatchV5MatchDto[] = [];
        const missedMatchIds = [];
        for (const matchId of ids) {
          const state = cache.get(key(matchId));

          if (state && state.data) {
            matches.push(state.data as MatchV5MatchDto);
            continue;
          }

          missedMatchIds.push(matchId);
        }

        if (missedMatchIds.length === 0) return matches;

        const fetchedMatches = await StatBud.MatchV5.matchV5GetMatches({
          matchIds: missedMatchIds,
        });
        for (const match of fetchedMatches) {
          if ([450, 900].includes(match.info.queueId)) {
            match.info.participants = match.info.participants.map((p) => ({
              ...p,
              teamPosition: 'NONE',
              individualPosition: 'NONE',
            }));
          }
          mutate(key(match.metadata.matchId)!, match);
          matches.push(match);
        }

        return matches;
      },
    );

  return {
    matches,
    loading: matchIdsLoading || matchesLoading,
  };
};

export const useStatbudMatchIdsForLeagueEntries = (
  queueType?: RankedQueueType,
) => {
  const { account } = useAccount(),
    key =
      account && queueType !== undefined
        ? `/lol/statbud/v1/matches/by-puuid/${account.puuid}/${queueType}`
        : undefined,
    { data: entries, isLoading } = useSWR(key, () =>
      StatBud.StatbudV1.statbudV1GetMatchIdsForLeagueEntriesByPUUID({
        puuid: account!.puuid,
        queueType: queueType!,
      }),
    );
  return { entries, loading: isLoading };
};

export const useLpDelta = (matchId?: string) => {
  const { match } = useMatch(matchId),
    { entries } = useStatbudMatchIdsForLeagueEntries(
      rankedQueueIdToType(match?.info.queueId),
    ),
    i = entries?.findIndex(({ matchId: id }) => id === matchId) ?? -1;

  if (entries === undefined || i < 0 || entries.length <= i + 1)
    return undefined;

  const cur = leagueEntryToTotalLp(entries.at(i)),
    prev = leagueEntryToTotalLp(entries.at(i + 1));
  return cur === undefined || prev === undefined ? undefined : cur - prev;
};
