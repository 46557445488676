import { Button, Container, Paper, Typography } from '@mui/material';

import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from 'react-router-dom';

export const Error: React.FC = () => {
  const error = useRouteError(),
    navigate = useNavigate();
  console.error(error);

  return (
    <Container maxWidth='sm'>
      <Paper sx={{ m: 2, p: 2 }}>
        <Typography variant='h1' textAlign='center'>
          Oops!
        </Typography>
        <Typography variant='subtitle1' textAlign='center'>
          An unexpected error has occurred.
        </Typography>
        <Typography variant='h5' textAlign='center'>
          {isRouteErrorResponse(error) ? (
            <>
              {error.status} {error.statusText}
            </>
          ) : (
            (error as Error)?.message || 'Unknown Error'
          )}
        </Typography>
        <Button
          sx={{ display: 'block', m: '0 auto', mt: 1 }}
          onClick={() => {
            navigate('/');
          }}
        >
          Take me home.
        </Button>
      </Paper>
    </Container>
  );
};
