import React from 'react';

import {
  createBrowserRouter,
  RouterProvider as ReactRouterProvider,
} from 'react-router-dom';

import {
  About,
  App,
  Challenges,
  Champion,
  Champions,
  Data,
  Error,
  Item,
  Items,
  Mastery,
  MatchHistory,
  MatchSummary,
  PrivacyPolicy,
  RunePage,
  Runes,
  Stats,
  Summoner,
  TermsOfService,
  TeamPicker,
  Tool,
} from 'components';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: '',
        index: true,
        element: <About />,
        errorElement: <Error />,
      },
      {
        path: 'about',
        element: <About />,
        errorElement: <Error />,
      },
      {
        // TODO: support all regions
        path: 'profile/na1/:riotId',
        // TODO: create profile component (rename old profile component)
        element: <Summoner />,
        errorElement: <Error />,
        children: [
          { path: 'history', element: <MatchHistory /> },
          {
            path: 'history/:matchId',
            element: <MatchSummary />,
            children: [{ path: ':tab', element: <MatchSummary /> }],
          },
          { path: 'stats', element: <Stats /> },
          { path: 'mastery', element: <Mastery /> },
          { path: 'challenges', element: <Challenges /> },
        ],
      },
      {
        path: 'data',
        element: <Data />,
        errorElement: <Error />,
        children: [
          {
            path: 'champions',
            element: <Champions />,
            errorElement: <Error />,
          },
          {
            path: 'champions/:championId',
            element: <Champion />,
            errorElement: <Error />,
          },
          {
            path: 'items',
            element: <Items />,
            errorElement: <Error />,
          },
          {
            path: 'items/:itemId',
            element: <Item />,
            errorElement: <Error />,
          },
          {
            path: 'runes',
            element: <Runes />,
            errorElement: <Error />,
          },
          {
            path: 'runes/:styleId',
            element: <RunePage />,
            errorElement: <Error />,
          },
        ],
      },
      {
        path: 'tool',
        element: <Tool />,
        errorElement: <Error />,
        children: [
          {
            path: 'team-picker',
            element: <TeamPicker />,
            errorElement: <Error />,
          },
        ],
      },
      {
        path: 'privacy',
        element: <PrivacyPolicy />,
        errorElement: <Error />,
      },
      {
        path: 'terms',
        element: <TermsOfService />,
        errorElement: <Error />,
      },
    ],
  },
]);

export const RouterProvider: React.FC = () => {
  return <ReactRouterProvider router={router} />;
};
