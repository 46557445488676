import React from 'react';

import { createRoot } from 'react-dom/client';

import './index.css';

import { RouterProvider } from 'router';

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider />
  </React.StrictMode>,
);
