/**
 * StatBud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DataV1Position } from '../models/DataV1Position';
import { HttpFile } from '../http/http';

export class StatbudV1PlayerStatDto {
    /**
    * Champion name for this entry.
    */
    'championName': string;
    /**
    * Queue ID for this entry.
    */
    'queueId': number;
    'teamPosition': DataV1Position;
    /**
    * Number of matches played.
    */
    'count': number;
    /**
    * Number of matches won.
    */
    'wins': number;
    /**
    * Total number of kills.
    */
    'kills': number;
    /**
    * Total number of deaths.
    */
    'deaths': number;
    /**
    * Total number of assists.
    */
    'assists': number;
    /**
    * Total damage dealt.
    */
    'damage': number;
    /**
    * Total damage taken.
    */
    'damageTaken': number;
    /**
    * Total CS.
    */
    'cs': number;
    /**
    * Total gold earned.
    */
    'goldEarned': number;
    /**
    * Total time played.
    */
    'timePlayed': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "championName",
            "baseName": "championName",
            "type": "string",
            "format": ""
        },
        {
            "name": "queueId",
            "baseName": "queueId",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "teamPosition",
            "baseName": "teamPosition",
            "type": "DataV1Position",
            "format": ""
        },
        {
            "name": "count",
            "baseName": "count",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "wins",
            "baseName": "wins",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "kills",
            "baseName": "kills",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "deaths",
            "baseName": "deaths",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "assists",
            "baseName": "assists",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "damage",
            "baseName": "damage",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "damageTaken",
            "baseName": "damageTaken",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "cs",
            "baseName": "cs",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "goldEarned",
            "baseName": "goldEarned",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "timePlayed",
            "baseName": "timePlayed",
            "type": "number",
            "format": "int64"
        }    ];

    static getAttributeTypeMap() {
        return StatbudV1PlayerStatDto.attributeTypeMap;
    }

    public constructor() {
    }
}



