import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

import { DataV1Position } from 'api/statbud-api';

import { Chips, ChipsProps } from './Chips';

const positions = Object.values(DataV1Position);

const positionsTitleMap: Readonly<{
  [k in DataV1Position]: string;
}> = {
  TOP: 'Top',
  JUNGLE: 'Jungle',
  MIDDLE: 'Middle',
  BOTTOM: 'Bottom',
  UTILITY: 'Support',
  NONE: 'None',
} as const;

const BottomIcon: React.FC<SvgIconProps> = ({ viewBox, ...rest }) => (
  <SvgIcon viewBox='0 0 34 34' {...rest}>
    <path
      opacity='0.5'
      d='M13,20h7V13H13v7ZM4,4V26.984l3.955-4L8,8,22.986,8l4-4H4Z'
    />
    <polygon points='29.997 5.955 25 11 25 25 11 25 5.955 29.997 30 30 29.997 5.955' />
  </SvgIcon>
);

const JungleIcon: React.FC<SvgIconProps> = ({ viewBox, ...rest }) => (
  <SvgIcon viewBox='0 0 34 34' {...rest}>
    <path d='M25,3c-2.128,3.3-5.147,6.851-6.966,11.469A42.373,42.373,0,0,1,20,20a27.7,27.7,0,0,1,1-3C21,12.023,22.856,8.277,25,3ZM13,20c-1.488-4.487-4.76-6.966-9-9,3.868,3.136,4.422,7.52,5,12l3.743,3.312C14.215,27.917,16.527,30.451,17,31c4.555-9.445-3.366-20.8-8-28C11.67,9.573,13.717,13.342,13,20Zm8,5a15.271,15.271,0,0,1,0,2l4-4c0.578-4.48,1.132-8.864,5-12C24.712,13.537,22.134,18.854,21,25Z' />
  </SvgIcon>
);

const MiddleIcon: React.FC<SvgIconProps> = ({ viewBox, ...rest }) => (
  <SvgIcon viewBox='0 0 34 34' {...rest}>
    <path
      opacity='0.5'
      d='M30,12.968l-4.008,4L26,26H17l-4,4H30ZM16.979,8L21,4H4V20.977L8,17,8,8h8.981Z'
    />
    <polygon points='25 4 4 25 4 30 9 30 30 9 30 4 25 4' />
  </SvgIcon>
);

const NoneIcon: React.FC<SvgIconProps> = ({ viewBox, ...rest }) => (
  <SvgIcon viewBox='0 0 136 136' {...rest}>
    <path d='M 37.683 25.338 L 36 30.676 36.034 32.088 L 36.068 33.500 43.955 46.500 L 51.843 59.500 35.601 59.773 L 19.360 60.047 15.477 64.023 L 11.594 68 15.477 71.977 L 19.360 75.953 35.601 76.227 L 51.843 76.500 43.955 89.500 L 36.068 102.500 36.034 103.912 L 36 105.324 37.683 110.662 L 39.365 116 40.933 115.922 L 42.500 115.845 47.500 114.088 L 52.500 112.331 60 99.693 L 67.500 87.055 68 87.055 L 68.500 87.055 76 99.693 L 83.500 112.331 88.500 114.088 L 93.500 115.845 95.067 115.922 L 96.635 116 98.317 110.662 L 100 105.324 99.966 103.912 L 99.932 102.500 92.045 89.500 L 84.157 76.500 100.399 76.227 L 116.640 75.953 120.523 71.977 L 124.406 68 120.523 64.023 L 116.640 60.047 100.399 59.773 L 84.157 59.500 92.045 46.500 L 99.932 33.500 99.966 32.088 L 100 30.676 98.317 25.338 L 96.635 20 95.067 20.078 L 93.500 20.155 88.500 21.912 L 83.500 23.669 76 36.307 L 68.500 48.945 68 48.945 L 67.500 48.945 60 36.307 L 52.500 23.669 47.500 21.912 L 42.500 20.155 40.933 20.078 L 39.365 20 37.683 25.338' />
  </SvgIcon>
);

const SupportIcon: React.FC<SvgIconProps> = ({ viewBox, ...rest }) => (
  <SvgIcon viewBox='0 0 34 34' {...rest}>
    <path d='M26,13c3.535,0,8-4,8-4H23l-3,3,2,7,5-2-3-4h2ZM22,5L20.827,3H13.062L12,5l5,6Zm-5,9-1-1L13,28l4,3,4-3L18,13ZM11,9H0s4.465,4,8,4h2L7,17l5,2,2-7Z' />
  </SvgIcon>
);

const TopIcon: React.FC<SvgIconProps> = ({ viewBox, ...rest }) => (
  <SvgIcon viewBox='0 0 34 34' {...rest}>
    <path opacity='0.5' d='M21,14H14v7h7V14Zm5-3V26L11.014,26l-4,4H30V7.016Z' />
    <polygon points='4 4 4.003 28.045 9 23 9 9 23 9 28.045 4.003 4 4' />
  </SvgIcon>
);

export const PositionIcon: React.FC<
  { position: DataV1Position } & SvgIconProps
> = ({ position, ...rest }) => {
  switch (position) {
    case 'BOTTOM':
      return <BottomIcon {...rest} />;
    case 'JUNGLE':
      return <JungleIcon {...rest} />;
    case 'MIDDLE':
      return <MiddleIcon {...rest} />;
    case 'UTILITY':
      return <SupportIcon {...rest} />;
    case 'TOP':
      return <TopIcon {...rest} />;
    case 'NONE':
    default:
      return <NoneIcon {...rest} />;
  }
};

interface Props extends Omit<Omit<ChipsProps<string>, 'values'>, 'icons'> {
  hideNone?: boolean;
}

export const PositionFilter: React.FC<Props> = ({
  onChange,
  hideNone,
  ...rest
}) => {
  return (
    <Chips
      values={positions
        .filter(
          (position) =>
            hideNone === undefined || position !== 'NONE' || !hideNone,
        )
        .map((position) => positionsTitleMap[position])}
      onChange={(e, values) => {
        if (onChange)
          onChange(
            e,
            // TODO: update chip on change to use generics to avoid the need to
            // map values
            values.map((value) =>
              value === 'Support' ? 'UTILITY' : value.toUpperCase(),
            ),
          );
      }}
      icons={positions.map((position) => (
        <PositionIcon position={position} />
      ))}
      {...rest}
    />
  );
};
