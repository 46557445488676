import { Link, LinkProps, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Props extends LinkProps {
  riotIdGameName?: string;
  riotIdTagLine?: string;
}

export const MatchHistoryLink: React.FC<Props> = ({
  riotIdGameName,
  riotIdTagLine,
  ...rest
}) => {
  const navigate = useNavigate(),
    // TODO: Remove hardcoded region
    href = `/profile/na1/${riotIdGameName}-${riotIdTagLine}/history`;
  return (
    <Link
      color='text.primary'
      underline='hover'
      href={href}
      onClick={(e) => {
        e.preventDefault();
        navigate(href);
      }}
      {...rest}
    >
      {riotIdGameName}
      <Typography
        color='text.secondary'
        sx={{ display: 'inline', fontSize: '0.7em' }}
      >
        #{riotIdTagLine}
      </Typography>
    </Link>
  );
};
