import * as d3 from 'd3';
import {
  Container,
  ExtractDatumKeys,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  Tooltip,
  XAxisTime,
} from 'd4';
import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import { useLocalStorage } from 'usehooks-ts';

import { StatbudV1LeagueEntryDto } from 'api/statbud-api';
import { leagueEntryToTotalLp, totalLpToLeagueEntryTuple } from 'helpers';
import { useStatbudLeaguesHistory } from 'hooks';

type Datum = ExtractDatumKeys<StatbudV1LeagueEntryDto> & { queue: string };

const capitalize = ([first, ...rest]: string) =>
  (first?.toUpperCase() ?? '') + rest.join('');

const tiers: { [key: string]: number } = {
  IRON: 0,
  BRONZE: 400,
  SILVER: 800,
  GOLD: 1200,
  PLATINUM: 1600,
  EMERALD: 2000,
  DIAMOND: 2400,
  MASTER: 2800,
  GRANDMASTER: 3200,
  CHALLENGER: 3600,
};

const tierColors: { [key: string]: string } = {
  IRON: '#0e0e0e',
  BRONZE: '#7e3f28',
  SILVER: '#586b72',
  GOLD: '#dca13d',
  PLATINUM: '#539190',
  EMERALD: '#16a050',
  DIAMOND: '#415081',
  MASTER: '#9062b2',
  GRANDMASTER: '#8c141c',
  CHALLENGER: '#1ebaff',
};

enum Queue {
  Both,
  SoloDuo,
  Flex,
}

const useColors = () => {
  const theme = useTheme();
  return {
    solo: theme.palette.primary.main,
    flex: theme.palette.secondary.main,
  } as { [k: string]: string };
};

export const LpTimeline: React.FC = () => {
  const theme = useTheme(),
    [queue, setQueue] = useLocalStorage<Queue>(
      'lp-chart-queue-show-enum',
      Queue.Both,
    ),
    solo = useStatbudLeaguesHistory('RANKED_SOLO_5x5').entries ?? [],
    flex = useStatbudLeaguesHistory('RANKED_FLEX_SR').entries ?? [],
    colors = useColors(),
    lp = ([Queue.SoloDuo, Queue.Both].includes(queue) ? solo : [])
      .map(({ timestamp, ...rest }) => ({
        queue: 'solo',
        timestamp: timestamp * 1000,
        ...rest,
      }))
      .concat(
        ([Queue.Flex, Queue.Both].includes(queue) ? flex : []).map(
          ({ timestamp, ...rest }) => ({
            queue: 'flex',
            timestamp: timestamp * 1000,
            ...rest,
          }),
        ),
      ),
    [min, max] = lp.reduce(
      ([min, max], entry) => {
        const lp = leagueEntryToTotalLp(entry) ?? -1;
        return [lp < min || min < 0 ? lp : min, lp > max ? lp : max];
      },
      [-1, 0],
    ),
    latest = Math.max(solo.at(-1)?.timestamp ?? 0, flex.at(-1)?.timestamp ?? 0),
    earliest = Math.min(
      solo.at(0)?.timestamp ?? 0,
      flex.at(0)?.timestamp ?? latest,
    ),
    twoWeeks = 60 * 60 * 24 * 7 * 2, // seconds in two weeks
    interval =
      (latest - earliest < twoWeeks
        ? d3.timeDay.every(3)
        : d3.timeWeek.every(3)) ?? undefined;

  return (
    ((solo.length || flex.length) && (
      <Stack sx={{ p: 1 }} spacing={1}>
        <Container data={lp} padding={[0, 12, 24, 12]}>
          <LineChart<Datum>
            series={(d) => d.queue}
            accessors={[
              (d) => d.timestamp,
              (d) => leagueEntryToTotalLp(d) ?? 0,
            ]}
            domain={[
              min % 100 === 0 ? min - 100 : min - (min % 100),
              max % 100 === 0 ? max + 100 : max - (max % 100) + 150,
            ]}
            color={(d) => colors[d.queue]}
            curve={d3.curveStepAfter}
            point={(d) => (
              <circle
                fill={theme.palette.background.paper}
                stroke={colors[d.queue]}
                strokeWidth={1.5}
                r={2.5}
              />
            )}
          >
            <XAxisTime interval={interval} />
            {Object.entries(tiers).map(([tier, value], i) => (
              <ReferenceArea
                key={i}
                y1={value}
                y2={value + 400}
                fill={tierColors[tier]}
                fillOpacity={0.2}
              />
            ))}
            {Array.from(new Array(40)).map((_, i) => (
              <ReferenceLine
                key={i}
                y={i * 100}
                label={(() => {
                  const [tier, rank] = totalLpToLeagueEntryTuple(i * 100);
                  return (
                    <text
                      fill={theme.palette.text.secondary}
                      style={{
                        userSelect: 'none',
                        ...theme.typography.caption,
                      }}
                    >
                      {`${capitalize(tier.toLocaleLowerCase())} ${rank}`}
                    </text>
                  );
                })()}
              />
            ))}
            <Tooltip<Datum, number>
              accessors={[
                (d) => d.timestamp,
                (d) => leagueEntryToTotalLp(d) ?? 0,
              ]}
              content={({ datum: d }) => (
                <Stack
                  component={Paper}
                  elevation={2}
                  sx={{ p: 0.75 }}
                  alignItems='center'
                >
                  <Typography color='text.secondary' variant='body2'>
                    {`${capitalize(d?.tier.toLocaleLowerCase() ?? '')} ${
                      d?.rank
                    } ${d?.leaguePoints} LP`}
                  </Typography>
                  <Typography color='text.secondary' variant='caption'>
                    {new Date(d?.timestamp ?? 0).toLocaleDateString(undefined, {
                      weekday: 'short',
                      day: 'numeric',
                      month: 'short',
                    })}
                  </Typography>
                </Stack>
              )}
            />
          </LineChart>
        </Container>
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={3}
        >
          {[Queue.SoloDuo, Queue.Flex].map((q) => (
            <Typography
              component='div'
              key={q.toString()}
              variant='body2'
              onClick={() => (queue === q ? setQueue(Queue.Both) : setQueue(q))}
              sx={{
                display: 'flex',
                color: [q, Queue.Both].includes(queue)
                  ? theme.palette.text.primary
                  : theme.palette.text.disabled,
                ':hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Box
                sx={{
                  display: 'inline-block',
                  alignSelf: 'center',
                  width: 8,
                  height: 8,
                  borderRadius: 4,
                  mr: 0.5,
                  backgroundColor:
                    q === Queue.SoloDuo
                      ? theme.palette.primary.main
                      : theme.palette.secondary.main,
                }}
              />
              <Box alignSelf='center' sx={{ userSelect: 'none' }}>
                {q === Queue.SoloDuo ? 'Solo/Duo' : 'Flex'}
              </Box>
            </Typography>
          ))}
        </Stack>
      </Stack>
    )) || <></>
  );
};
