import { ResponseContext, RequestContext, HttpFile, HttpInfo } from '../http/http';
import { Configuration} from '../configuration'

import { DataV1ChampionPositionDto } from '../models/DataV1ChampionPositionDto';
import { DataV1Position } from '../models/DataV1Position';
import { MatchV5BanDto } from '../models/MatchV5BanDto';
import { MatchV5ChallengesDto } from '../models/MatchV5ChallengesDto';
import { MatchV5InfoDto } from '../models/MatchV5InfoDto';
import { MatchV5MatchDto } from '../models/MatchV5MatchDto';
import { MatchV5MetadataDto } from '../models/MatchV5MetadataDto';
import { MatchV5MissionsDto } from '../models/MatchV5MissionsDto';
import { MatchV5ObjectiveDto } from '../models/MatchV5ObjectiveDto';
import { MatchV5ObjectivesDto } from '../models/MatchV5ObjectivesDto';
import { MatchV5ParticipantDto } from '../models/MatchV5ParticipantDto';
import { MatchV5PerkStatsDto } from '../models/MatchV5PerkStatsDto';
import { MatchV5PerkStyleDto } from '../models/MatchV5PerkStyleDto';
import { MatchV5PerkStyleSelectionDto } from '../models/MatchV5PerkStyleSelectionDto';
import { MatchV5PerksDto } from '../models/MatchV5PerksDto';
import { MatchV5TeamDto } from '../models/MatchV5TeamDto';
import { StatbudV1ChampionStatsDto } from '../models/StatbudV1ChampionStatsDto';
import { StatbudV1LeagueEntryDto } from '../models/StatbudV1LeagueEntryDto';
import { StatbudV1LeagueEntryWithMatchIdDto } from '../models/StatbudV1LeagueEntryWithMatchIdDto';
import { StatbudV1PlayerStatDto } from '../models/StatbudV1PlayerStatDto';
import { StatbudV1Rank } from '../models/StatbudV1Rank';
import { StatbudV1Tier } from '../models/StatbudV1Tier';

import { ObservableDataV1Api } from "./ObservableAPI";
import { DataV1ApiRequestFactory, DataV1ApiResponseProcessor} from "../apis/DataV1Api";

export interface DataV1ApiDataV1GetChampionPositionsRequest {
}

export class ObjectDataV1Api {
    private api: ObservableDataV1Api

    public constructor(configuration: Configuration, requestFactory?: DataV1ApiRequestFactory, responseProcessor?: DataV1ApiResponseProcessor) {
        this.api = new ObservableDataV1Api(configuration, requestFactory, responseProcessor);
    }

    /**
     * Get the positions each champion most commonly plays
     * Get the positions each champion most commonly plays
     * @param param the request object
     */
    public dataV1GetChampionPositionsWithHttpInfo(param: DataV1ApiDataV1GetChampionPositionsRequest = {}, options?: Configuration): Promise<HttpInfo<Array<DataV1ChampionPositionDto>>> {
        return this.api.dataV1GetChampionPositionsWithHttpInfo( options).toPromise();
    }

    /**
     * Get the positions each champion most commonly plays
     * Get the positions each champion most commonly plays
     * @param param the request object
     */
    public dataV1GetChampionPositions(param: DataV1ApiDataV1GetChampionPositionsRequest = {}, options?: Configuration): Promise<Array<DataV1ChampionPositionDto>> {
        return this.api.dataV1GetChampionPositions( options).toPromise();
    }

}

import { ObservableMatchV5Api } from "./ObservableAPI";
import { MatchV5ApiRequestFactory, MatchV5ApiResponseProcessor} from "../apis/MatchV5Api";

export interface MatchV5ApiMatchV5GetMatchesRequest {
    /**
     * 
     * @type Array&lt;string&gt;
     * @memberof MatchV5ApimatchV5GetMatches
     */
    matchIds: Array<string>
}

export class ObjectMatchV5Api {
    private api: ObservableMatchV5Api

    public constructor(configuration: Configuration, requestFactory?: MatchV5ApiRequestFactory, responseProcessor?: MatchV5ApiResponseProcessor) {
        this.api = new ObservableMatchV5Api(configuration, requestFactory, responseProcessor);
    }

    /**
     * Get a match by match id
     * Get matches by match IDs
     * @param param the request object
     */
    public matchV5GetMatchesWithHttpInfo(param: MatchV5ApiMatchV5GetMatchesRequest, options?: Configuration): Promise<HttpInfo<Array<MatchV5MatchDto>>> {
        return this.api.matchV5GetMatchesWithHttpInfo(param.matchIds,  options).toPromise();
    }

    /**
     * Get a match by match id
     * Get matches by match IDs
     * @param param the request object
     */
    public matchV5GetMatches(param: MatchV5ApiMatchV5GetMatchesRequest, options?: Configuration): Promise<Array<MatchV5MatchDto>> {
        return this.api.matchV5GetMatches(param.matchIds,  options).toPromise();
    }

}

import { ObservableStatbudV1Api } from "./ObservableAPI";
import { StatbudV1ApiRequestFactory, StatbudV1ApiResponseProcessor} from "../apis/StatbudV1Api";

export interface StatbudV1ApiStatbudV1GetLeagueEntriesHistoryForSummonerRequest {
    /**
     * 
     * @type string
     * @memberof StatbudV1ApistatbudV1GetLeagueEntriesHistoryForSummoner
     */
    encryptedSummonerId: string
    /**
     * 
     * @type &#39;RANKED_SOLO_5x5&#39; | &#39;RANKED_FLEX_SR&#39;
     * @memberof StatbudV1ApistatbudV1GetLeagueEntriesHistoryForSummoner
     */
    queueType: 'RANKED_SOLO_5x5' | 'RANKED_FLEX_SR'
}

export interface StatbudV1ApiStatbudV1GetMatchIdsByPuuidRequest {
    /**
     * 
     * @type string
     * @memberof StatbudV1ApistatbudV1GetMatchIdsByPuuid
     */
    puuid: string
    /**
     * 
     * @type number
     * @memberof StatbudV1ApistatbudV1GetMatchIdsByPuuid
     */
    startTime?: number
    /**
     * 
     * @type number
     * @memberof StatbudV1ApistatbudV1GetMatchIdsByPuuid
     */
    endTime?: number
    /**
     * 
     * @type Array&lt;400 | 420 | 430 | 440 | 450 | 900 | 1300 | 1700&gt;
     * @memberof StatbudV1ApistatbudV1GetMatchIdsByPuuid
     */
    queues?: Array<400 | 420 | 430 | 440 | 450 | 900 | 1300 | 1700>
    /**
     * 
     * @type &#39;ranked&#39; | &#39;normal&#39; | &#39;tourney&#39; | &#39;tutorial&#39;
     * @memberof StatbudV1ApistatbudV1GetMatchIdsByPuuid
     */
    type?: 'ranked' | 'normal' | 'tourney' | 'tutorial'
    /**
     * 
     * @type number
     * @memberof StatbudV1ApistatbudV1GetMatchIdsByPuuid
     */
    count?: number
}

export interface StatbudV1ApiStatbudV1GetMatchIdsForLeagueEntriesByPUUIDRequest {
    /**
     * 
     * @type string
     * @memberof StatbudV1ApistatbudV1GetMatchIdsForLeagueEntriesByPUUID
     */
    puuid: string
    /**
     * 
     * @type &#39;RANKED_SOLO_5x5&#39; | &#39;RANKED_FLEX_SR&#39;
     * @memberof StatbudV1ApistatbudV1GetMatchIdsForLeagueEntriesByPUUID
     */
    queueType: 'RANKED_SOLO_5x5' | 'RANKED_FLEX_SR'
}

export interface StatbudV1ApiStatbudV1GetPlayerStatsRequest {
    /**
     * 
     * @type string
     * @memberof StatbudV1ApistatbudV1GetPlayerStats
     */
    puuid: string
}

export interface StatbudV1ApiStatbudV1GetSummonerChampionStatsRequest {
    /**
     * 
     * @type string
     * @memberof StatbudV1ApistatbudV1GetSummonerChampionStats
     */
    puuid: string
}

export class ObjectStatbudV1Api {
    private api: ObservableStatbudV1Api

    public constructor(configuration: Configuration, requestFactory?: StatbudV1ApiRequestFactory, responseProcessor?: StatbudV1ApiResponseProcessor) {
        this.api = new ObservableStatbudV1Api(configuration, requestFactory, responseProcessor);
    }

    /**
     * Get latest per day league entries for the last 30 days
     * Get latest per day league entries for the last 30 days
     * @param param the request object
     */
    public statbudV1GetLeagueEntriesHistoryForSummonerWithHttpInfo(param: StatbudV1ApiStatbudV1GetLeagueEntriesHistoryForSummonerRequest, options?: Configuration): Promise<HttpInfo<Array<StatbudV1LeagueEntryDto>>> {
        return this.api.statbudV1GetLeagueEntriesHistoryForSummonerWithHttpInfo(param.encryptedSummonerId, param.queueType,  options).toPromise();
    }

    /**
     * Get latest per day league entries for the last 30 days
     * Get latest per day league entries for the last 30 days
     * @param param the request object
     */
    public statbudV1GetLeagueEntriesHistoryForSummoner(param: StatbudV1ApiStatbudV1GetLeagueEntriesHistoryForSummonerRequest, options?: Configuration): Promise<Array<StatbudV1LeagueEntryDto>> {
        return this.api.statbudV1GetLeagueEntriesHistoryForSummoner(param.encryptedSummonerId, param.queueType,  options).toPromise();
    }

    /**
     * Get match IDs for a summoner
     * Get match IDs for a summoner
     * @param param the request object
     */
    public statbudV1GetMatchIdsByPuuidWithHttpInfo(param: StatbudV1ApiStatbudV1GetMatchIdsByPuuidRequest, options?: Configuration): Promise<HttpInfo<Array<string>>> {
        return this.api.statbudV1GetMatchIdsByPuuidWithHttpInfo(param.puuid, param.startTime, param.endTime, param.queues, param.type, param.count,  options).toPromise();
    }

    /**
     * Get match IDs for a summoner
     * Get match IDs for a summoner
     * @param param the request object
     */
    public statbudV1GetMatchIdsByPuuid(param: StatbudV1ApiStatbudV1GetMatchIdsByPuuidRequest, options?: Configuration): Promise<Array<string>> {
        return this.api.statbudV1GetMatchIdsByPuuid(param.puuid, param.startTime, param.endTime, param.queues, param.type, param.count,  options).toPromise();
    }

    /**
     * Get match IDs correlated with league entries for a summoner
     * Get match IDs correlated with league entries for a summoner
     * @param param the request object
     */
    public statbudV1GetMatchIdsForLeagueEntriesByPUUIDWithHttpInfo(param: StatbudV1ApiStatbudV1GetMatchIdsForLeagueEntriesByPUUIDRequest, options?: Configuration): Promise<HttpInfo<Array<StatbudV1LeagueEntryWithMatchIdDto>>> {
        return this.api.statbudV1GetMatchIdsForLeagueEntriesByPUUIDWithHttpInfo(param.puuid, param.queueType,  options).toPromise();
    }

    /**
     * Get match IDs correlated with league entries for a summoner
     * Get match IDs correlated with league entries for a summoner
     * @param param the request object
     */
    public statbudV1GetMatchIdsForLeagueEntriesByPUUID(param: StatbudV1ApiStatbudV1GetMatchIdsForLeagueEntriesByPUUIDRequest, options?: Configuration): Promise<Array<StatbudV1LeagueEntryWithMatchIdDto>> {
        return this.api.statbudV1GetMatchIdsForLeagueEntriesByPUUID(param.puuid, param.queueType,  options).toPromise();
    }

    /**
     * Get stats for a player
     * Get stats for a player
     * @param param the request object
     */
    public statbudV1GetPlayerStatsWithHttpInfo(param: StatbudV1ApiStatbudV1GetPlayerStatsRequest, options?: Configuration): Promise<HttpInfo<Array<StatbudV1PlayerStatDto>>> {
        return this.api.statbudV1GetPlayerStatsWithHttpInfo(param.puuid,  options).toPromise();
    }

    /**
     * Get stats for a player
     * Get stats for a player
     * @param param the request object
     */
    public statbudV1GetPlayerStats(param: StatbudV1ApiStatbudV1GetPlayerStatsRequest, options?: Configuration): Promise<Array<StatbudV1PlayerStatDto>> {
        return this.api.statbudV1GetPlayerStats(param.puuid,  options).toPromise();
    }

    /**
     * Get champion stats for an account puuid
     * Get champion stats for an account puuid
     * @param param the request object
     */
    public statbudV1GetSummonerChampionStatsWithHttpInfo(param: StatbudV1ApiStatbudV1GetSummonerChampionStatsRequest, options?: Configuration): Promise<HttpInfo<Array<StatbudV1ChampionStatsDto>>> {
        return this.api.statbudV1GetSummonerChampionStatsWithHttpInfo(param.puuid,  options).toPromise();
    }

    /**
     * Get champion stats for an account puuid
     * Get champion stats for an account puuid
     * @param param the request object
     */
    public statbudV1GetSummonerChampionStats(param: StatbudV1ApiStatbudV1GetSummonerChampionStatsRequest, options?: Configuration): Promise<Array<StatbudV1ChampionStatsDto>> {
        return this.api.statbudV1GetSummonerChampionStats(param.puuid,  options).toPromise();
    }

}
