import React from 'react';

import { MatchV5InfoDto } from '@blakearoberts/riot-api-ts';
import { blue, red } from '@mui/material/colors';
import { Typography, Stack } from '@mui/material';

import { formatPercentage } from 'helpers';
import { useAccount } from 'hooks';

import { SummaryItem } from './SummaryItem';

class ChampionStats {
  constructor(
    public wins: number = 0,
    public losses: number = 0,
    public kills: number = 0,
    public deaths: number = 0,
    public assists: number = 0,
  ) {}
}

interface Props {
  matches: MatchV5InfoDto[];
}

export const Summary: React.FC<Props> = ({ matches }) => {
  const { account } = useAccount(),
    loading = matches.length === 0,
    champions = new Map<string, ChampionStats>();
  let wins = 0,
    losses = 0;
  matches.forEach((match) => {
    if (match.gameDuration < 10 * 60) return;
    const participant = match.participants.find(
        (p) => p.puuid === account?.puuid,
      )!,
      team = match.teams.find((t) => t.teamId === participant.teamId);
    let champion = champions.get(participant.championName);
    if (champion === undefined) {
      champion = new ChampionStats();
      champions.set(participant.championName, champion);
    }
    if (team?.win) {
      champion.wins++;
      wins++;
    } else {
      champion.losses++;
      losses++;
    }
    champion.kills += participant.kills;
    champion.deaths += participant.deaths;
    champion.assists += participant.assists;
  });
  const mostPlayed = Array.from(champions.entries())
    .sort(
      ([_a, { wins: aW, losses: aL }], [_b, { wins: bW, losses: bL }]) =>
        bW + bL - (aW + aL),
    )
    .splice(0, 3);
  return (
    <Stack direction='row' sx={{ p: (theme) => theme.spacing(0, 2) }}>
      <Stack
        width={80}
        justifyContent='center'
        alignItems={{ xs: 'center', sm: 'start' }}
        flexGrow={{ xs: 1, sm: 0 }}
      >
        <Typography>Last {matches.length}</Typography>
        <Typography variant='body2'>
          {wins}W &#x2022; {losses}L{' '}
        </Typography>
        <Typography
          variant='body2'
          color={wins - losses >= 0 ? blue[300] : red[300]}
        >
          {formatPercentage(wins, wins + losses)} WR
        </Typography>
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} flexGrow={1}>
        {loading
          ? Array.from(new Array(3)).map((_, i) => (
              <SummaryItem
                key={i}
                wins={0}
                losses={0}
                kills={0}
                deaths={0}
                assists={0}
              />
            ))
          : mostPlayed.map(
              ([championId, { wins, losses, kills, deaths, assists }], i) => (
                <SummaryItem
                  key={i}
                  championId={championId}
                  wins={wins}
                  losses={losses}
                  kills={kills}
                  deaths={deaths}
                  assists={assists}
                />
              ),
            )}
      </Stack>
    </Stack>
  );
};
