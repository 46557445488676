export interface FontConfig {
  entries: { [k: string]: string };
}

interface ChampionBinItem {
  __type: ChampionBinItemType;
}

type ChampionBinItemType = 'AbilityObject' | 'CharacterRecord' | 'SpellObject';

export interface AbilityObject extends ChampionBinItem {
  mRootSpell: string;
  mChildSpells: string;
  mName: string;
  mType: number;
  __type: 'AbilityObject';
}

export interface SpellObject {
  mScriptName: string;
  mSpell: SpellDataResource;
  __type: 'SpellObject';
}

interface AbilityResourceSlotInfo {}

interface AttackSlotData {}

interface ToolEducationData {}

interface CharacterToolData {}

interface PerkReplacementList {}

interface CharacterPassiveData {}

export interface CharacterRecord extends ChampionBinItem {
  mCharacterName: string;
  baseHP: number;
  hpPerLevel: number;
  baseStaticHPRegen: number;
  hpRegenPerLevel: number;
  healthBarHeight: number;
  primaryAbilityResource: AbilityResourceSlotInfo;
  secondaryAbilityResource: AbilityResourceSlotInfo;
  baseDamage: number;
  damagePerLevel: number;
  baseArmor: number;
  armorPerLevel: number;
  baseSpellBlock: number;
  spellBlockPerLevel: number;
  baseMoveSpeed: number;
  attackRange: number;
  attackSpeed: number;
  attackSpeedRatio: number;
  attackSpeedPerLevel: number;
  acquisitionRange: number;
  basicAttack: AttackSlotData;
  extraAttacks: AttackSlotData[];
  critAttacks: AttackSlotData[];
  spellNames: string[];
  mAbilities: string[];
  passiveName: string;
  passiveToolTip: string;
  passiveSpell: string;
  passive1IconName: string;
  name: string;
  selectionHeight: number;
  selectionRadius: number;
  pathfindingCollisionRadius: number;
  unitTagsString: 'Champion';
  mEducationToolData: ToolEducationData;
  mAbilitySlotCC: [number, number, number, number];
  characterToolData: CharacterToolData;
  platformEnabled: boolean;
  flags: number;
  purchaseIdentities: string[];
  mPreferredPerkStyle: string;
  mPerkReplacements: PerkReplacementList;
  mCharacterPassiveSpell: string;
  mCharacterPassiveBuffs: CharacterPassiveData[];
  __type: 'CharacterRecord';
}

type Numbers7 = [number, number, number, number, number, number, number];

export interface SpellDataValue {
  mName: string;
  mValues: Numbers7;
}

interface SpellEffectAmount {
  value?: number[];
  __type: 'SpellEffectAmount';
}

export enum StatType {
  AbilityPower = 0,
  Armor = 1,
  Attack = 2,
  AttackSpeed = 3,
  AttackWindupTime = 4,
  MagicResist = 5,
  MoveSpeed = 6,
  CritChance = 7,
  CritDamage = 8,
  CooldownReduction = 9,
  AbilityHaste = 10,
  MaxHealth = 11,
  CurrentHealth = 12,
  PercentMissingHealth = 13,
  Unknown14 = 14,
  LifeSteal = 15,
  OmniVamp = 17,
  PhysicalVamp = 18,
  MagicPenetrationFlat = 19,
  MagicPenetrationPercent = 20,
  BonusMagicPenetrationPercent = 21,
  MagicLethality = 22,
  ArmorPenetrationFlat = 23,
  ArmorPenetrationPercent = 24,
  BonusArmorPenetrationPercent = 25,
  PhysicalLethality = 26,
  Tenacity = 27,
  AttackRange = 28,
  HealthRegenRate = 29,
  ResourceRegenRate = 30,
  Unknown31 = 31,
  Unknown32 = 32,
  DodgeChance = 33,
}

export enum StatFormulaType {
  Base = 0,
  Bonus = 1,
  Total = 2,
}

interface ICalculationPart {
  __type:
    | 'ByCharLevelBreakpointsCalculationPart'
    | 'ByCharLevelInterpolationCalculationPart'
    | 'EffectValueCalculationPart'
    | 'NamedDataValueCalculationPart'
    | 'NumberCalculationPart'
    | 'StatByCoefficientCalculationPart'
    | 'StatByNamedDataValueCalculationPart'
    | 'SumOfSubPartsCalculationPart';
}

interface Breakpoint {
  mLevel: number;
  __type: 'Breakpoint';
}

export interface ByCharLevelBreakpoints_CP extends ICalculationPart {
  mLevel1Value?: number;
  mBreakpoints?: Breakpoint[];
  __type: 'ByCharLevelBreakpointsCalculationPart';
}

export interface ByCharLevelInterpolation_CP extends ICalculationPart {
  mStartValue: number;
  mEndValue: number;
  __type: 'ByCharLevelInterpolationCalculationPart';
}

export interface EffectValue_CP extends ICalculationPart {
  mEffectIndex: number;
  __type: 'EffectValueCalculationPart';
}

export interface NamedDataValue_CP extends ICalculationPart {
  mDataValue: string;
  __type: 'NamedDataValueCalculationPart';
}

export interface Number_CP extends ICalculationPart {
  mNumber: number;
  __type: 'NumberCalculationPart';
}

export interface StatByCoefficient_CP extends ICalculationPart {
  mStat?: StatType;
  mStatFormula?: StatFormulaType;
  mCoefficient: number;
  __type: 'StatByCoefficientCalculationPart';
}

export interface StatByNamedDataValue_CP extends ICalculationPart {
  mStat?: number;
  mDataValue: string;
  __type: 'StatByNamedDataValueCalculationPart';
}

export interface SumOfSubParts_CP extends ICalculationPart {
  mSubparts: CalculationPart[];
  __type: 'SumOfSubPartsCalculationPart';
}

export type CalculationPart =
  | ByCharLevelBreakpoints_CP
  | ByCharLevelInterpolation_CP
  | EffectValue_CP
  | NamedDataValue_CP
  | Number_CP
  | StatByCoefficient_CP
  | StatByNamedDataValue_CP
  | SumOfSubParts_CP;

export interface GameCalculation {
  mMultiplier?: CalculationPart;
  mFormulaParts: CalculationPart[];
  mDisplayAsPercent?: boolean;
  __type: 'GameCalculation';
}

export interface GameCalculationModified {
  mMultiplier: CalculationPart;
  tooltipOnly: boolean;
  mModifiedGameCalculation: string;
  __type: 'GameCalculationModified';
}

interface SpellCalculations {
  [k: string]: GameCalculation | GameCalculationModified;
}

interface TooltipInstanceSpell {
  mObjectName: string;
  mFormat: string;
  mLocKeys: {
    keyTooltip: string;
    keyName: string;
    keyCost: string;
    keyTooltipExtendedBelowLine: string;
    keySummary: string;
  };
}

interface ClientData {
  mTooltipData: TooltipInstanceSpell;
}

export interface SpellDataResource {
  flags: 4194304;
  mAffectsTypeFlags: 6154;
  mSpellTags: string[];
  mDataValues?: SpellDataValue[];
  mEffectAmount?: SpellEffectAmount[];
  mSpellCalculations?: SpellCalculations;
  cooldownTime: Numbers7;
  mChannelIsInterruptedByDisables: boolean;
  mChannelIsInterruptedByAttacking: boolean;
  mSpellRevealsChampion: boolean;
  castRange: Numbers7;
  mClientData: ClientData;
}

export interface ChampionBin {
  [k: string]: AbilityObject | CharacterRecord | SpellObject;
}
