import React, { useState } from 'react';

import { Casino, Lock, LockOpen } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Divider,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
  Slider as MuiSlider,
  Box,
  useTheme,
  SliderProps,
  Card,
  Avatar,
  ButtonGroup,
  IconButton,
  Tooltip,
} from '@mui/material';

import { DataV1Position } from 'api/statbud-api';
import {
  ChampionTagFilter,
  LazyAvatar,
  PositionFilter,
  PositionIcon,
} from 'components';
import {
  useChampionPositions,
  useDDragonChampions,
  useDDragonData,
} from 'hooks';

const Slider: React.FC<SliderProps> = ({ min, max, ...rest }) => {
  const theme = useTheme(),
    count = (max || min || 1) - (min || 0) + 1;
  return (
    <Box sx={{ p: theme.spacing(0, 0.5), maxWidth: 500 }}>
      <MuiSlider
        step={1}
        min={min}
        max={max}
        marks={new Array(count).fill(0).map((_, i) => ({
          value: (min || 0) + i,
          label: (min || 0) + i,
        }))}
        {...rest}
      />
    </Box>
  );
};

interface FormProps {
  items: { [label: string]: React.ReactNode };
  loading: boolean;
  onSubmit: () => void;
}

const Form: React.FC<FormProps> = ({ items, loading, onSubmit }) => {
  const theme = useTheme();
  return (
    <Grid container direction='row' spacing={2} alignItems='center'>
      {Object.entries(items).map(([label, input], i) => (
        <React.Fragment key={i}>
          <Grid xs={12} sm={2}>
            <Typography
              color='text.secondary'
              textAlign={{ xs: 'left', sm: 'right' }}
              sx={{ pt: 0.5 }}
            >
              {label}
            </Typography>
          </Grid>
          <Grid xs={12} sm={10} sx={{ p: { sm: theme.spacing(1, 2) } }}>
            {input}
          </Grid>
        </React.Fragment>
      ))}
      <Grid xs={10} xsOffset={2}>
        <LoadingButton
          sx={{ ml: 2 }}
          variant='contained'
          loading={loading}
          loadingPosition='start'
          startIcon={<Casino />}
          onClick={onSubmit}
        >
          Roll
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export const TeamPicker: React.FC = () => {
  const theme = useTheme(),
    { version } = useDDragonData(),
    { champions } = useDDragonChampions(),
    { positions } = useChampionPositions(),
    [picks, setPicks] = useState<string[]>([]),
    [locks, setLocks] = useState<number[]>([]),
    [numChampions, setNumChampions] = useState(6),
    [positionsFilter, setPositionsFilter] = useState<DataV1Position[]>([]),
    [tagsFilter, setTagsFilter] = useState<string[]>(() => []),
    filterByPositions = (positions: DataV1Position[]) =>
      positionsFilter.length === 0 ||
      positionsFilter.reduce(
        (filtered, position) => filtered || positions.includes(position),
        false,
      ),
    filterByTags = (tags: string[]) =>
      tagsFilter.length === 0 ||
      tags.reduce(
        (filtered, tag) => filtered || tagsFilter.includes(tag),
        false,
      ),
    available = () =>
      positions === undefined
        ? []
        : champions
            .filter(
              ({ id, key, tags }) =>
                !locks.includes(picks.indexOf(id)) &&
                filterByTags(tags) &&
                filterByPositions(
                  positions
                    .filter(({ championId }) => key === championId)
                    .map(({ position }) => position),
                ),
            )
            .map(({ id }) => id),
    pick = (pool: string[]) =>
      pool.splice(Math.floor(Math.random() * pool.length), 1)[0],
    roll = () => {
      const pool = available();
      setPicks(
        Array.from(Array(numChampions), (_, i) =>
          locks.includes(i) ? picks[i] : pick(pool),
        ),
      );
    },
    reroll = (index: number) => {
      const newPicks = [...picks];
      newPicks[index] = pick(available());
      setPicks(newPicks);
    };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant='h5'>Team Picker</Typography>
      <Form
        items={{
          'Number of Champions': (
            <Slider
              value={numChampions}
              min={1}
              max={10}
              onChange={(_, value) => setNumChampions(value as number)}
            />
          ),
          Positions: (
            <PositionFilter
              onChange={(_, positions) =>
                setPositionsFilter(positions as DataV1Position[])
              }
              initialSelected={Array.from(new Array(5), () => true)}
              hideNone
            />
          ),
          Tags: (
            <ChampionTagFilter
              onChange={(_, tags) => setTagsFilter(tags)}
              initialSelected={Array.from(new Array(6), () => true)}
            />
          ),
        }}
        loading={!Boolean(champions) || !Boolean(positions)}
        onSubmit={roll}
      />
      <Divider sx={{ m: (theme) => theme.spacing(2, 0) }} />
      <Grid container spacing={2}>
        {picks.map((id, index) => {
          const champion = champions.find((c) => c.id === id);
          if (champion === undefined) return <React.Fragment key={index} />;
          const { name, key, tags, image } = champion;
          return (
            <Grid key={index} xs={12} sm={6}>
              <Card
                elevation={0}
                sx={{
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: `${theme.shape.borderRadius}px`,
                }}
              >
                <Stack
                  direction='row'
                  spacing={1}
                  alignItems='center'
                  sx={{ p: theme.spacing(1, 2) }}
                >
                  <LazyAvatar
                    sx={{ width: 56, height: 56 }}
                    variant='square'
                    alt={name}
                    title={name}
                    src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${image.full}`}
                  />
                  <Typography
                    variant='h5'
                    component='div'
                    flex={1}
                    textOverflow='ellipsis'
                    noWrap
                  >
                    {name}
                  </Typography>
                  <Stack
                    sx={{
                      display: { xs: 'none', sm: 'flex' },
                      alignSelf: 'stretch',
                    }}
                  >
                    <Stack flex={1} direction='row' justifyContent='end'>
                      {positions
                        ?.filter(({ championId }) => key === championId)
                        .map(({ position }) => position!)
                        .map((position, index) => (
                          <Box key={index} sx={{ p: 1, lineHeight: 0 }}>
                            <PositionIcon position={position} />
                          </Box>
                        ))}
                    </Stack>
                    <Stack flex={1} direction='row' justifyContent='end'>
                      {tags.map((tag, index) => (
                        <Box key={index} sx={{ p: 1, lineHeight: 0 }}>
                          <Avatar
                            variant='square'
                            alt={tag}
                            title={tag}
                            src={`https://raw.communitydragon.org/13.4/plugins/rcp-fe-lol-champion-details/global/default/role-icon-${tag.toLowerCase()}.png`}
                            sx={{ width: 24, height: 24 }}
                          />
                        </Box>
                      ))}
                    </Stack>
                  </Stack>

                  <Divider orientation='vertical' flexItem />

                  <ButtonGroup orientation='vertical' size='small'>
                    <Tooltip title={'Reroll'} placement='right'>
                      <IconButton
                        onClick={() => reroll(index)}
                        disabled={locks.includes(index)}
                      >
                        <Casino />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={locks.includes(index) ? 'Unlock' : 'Lock-in'}
                      placement='right'
                    >
                      <IconButton
                        onClick={() =>
                          locks.includes(index)
                            ? setLocks(locks.filter((lock) => lock !== index))
                            : setLocks([index, ...locks])
                        }
                      >
                        {locks.includes(index) ? <Lock /> : <LockOpen />}
                      </IconButton>
                    </Tooltip>
                  </ButtonGroup>
                </Stack>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
