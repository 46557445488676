import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';

import { Build, Person, Topic } from '@mui/icons-material';
import {
  Box,
  Divider,
  Drawer,
  Link,
  List,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FaDiscord } from 'react-icons/fa6';

import { SubList } from './SubList';
import { useRiotId } from 'hooks';

type View =
  | 'history'
  | 'stats'
  | 'mastery'
  | 'challenges'
  | 'champions'
  | 'items'
  | 'runes'
  | 'team-picker';

const summonerViews: { id: View; text: string }[] = [
  { id: 'history', text: 'Match History' },
  { id: 'stats', text: 'Stats' },
  { id: 'mastery', text: 'Mastery' },
  { id: 'challenges', text: 'Challenges' },
];

const dataViews: { id: View; text: string }[] = [
  { id: 'champions', text: 'Champions' },
  { id: 'items', text: 'Items' },
  { id: 'runes', text: 'Runes' },
];

const toolViews: { id: View; text: string }[] = [
  { id: 'team-picker', text: 'Team Picker' },
];

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const NavDrawer: React.FC<Props> = ({ open, setOpen }) => {
  const theme = useTheme(),
    [{ gameName, tagLine }] = useRiotId(),
    lg = useMediaQuery(theme.breakpoints.up('lg')),
    navigate = useNavigate();
  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
      variant={lg ? 'permanent' : 'temporary'}
    >
      <Stack
        sx={{ ...theme.mixins.drawer.nav, flex: 1 }}
        onKeyDown={() => setOpen(false)}
      >
        <Toolbar />
        <List sx={{ flex: 1 }}>
          <SubList
            text='Profile'
            icon={<Person />}
            items={summonerViews.map(({ id, text }) => ({
              text,
              // TODO: support other regions
              href:
                gameName && tagLine
                  ? `/profile/na1/${gameName}-${tagLine}/${id}`
                  : undefined,
              onClick: () => setOpen(false),
            }))}
          />
          <SubList
            text='Data'
            icon={<Topic />}
            items={dataViews.map(({ id, text }) => ({
              text,
              href: `/data/${id}`,
              onClick: () => setOpen(false),
            }))}
          />
          <SubList
            text='Tools'
            icon={<Build />}
            items={toolViews.map(({ id, text }) => ({
              text,
              href: `/tool/${id}`,
              onClick: () => setOpen(false),
            }))}
          />
        </List>

        <Divider sx={{ mb: 1 }} />
        <Stack direction='row' justifyContent='space-around'>
          <Link
            href='https://discord.gg/2RmsdZJJ6a'
            target='_blank'
            underline='hover'
            sx={{ position: 'relative' }}
          >
            <Box
              sx={{
                position: 'absolute',
                right: '100%',
                pr: 0.5,
                display: 'flex',
                height: '100%',
                alignItems: 'center',
              }}
            >
              <FaDiscord />
            </Box>
            <Typography component='span'>Discord</Typography>
          </Link>
        </Stack>
        <Stack
          direction='row'
          justifyContent='space-around'
          sx={{ p: (theme) => theme.spacing(1, 0) }}
        >
          <Link
            href='/terms'
            underline='hover'
            onClick={(e) => {
              e.preventDefault();
              navigate('/terms');
              setOpen(false);
            }}
          >
            Terms
          </Link>
          <Link
            href='/privacy'
            underline='hover'
            onClick={(e) => {
              e.preventDefault();
              navigate('/privacy');
              setOpen(false);
            }}
          >
            Privacy
          </Link>
        </Stack>
      </Stack>
    </Drawer>
  );
};
