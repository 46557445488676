import {
  Badge,
  Typography,
  useMediaQuery,
  useTheme,
  Skeleton,
  Stack,
} from '@mui/material';

import {
  ChampionIcon,
  KdaTypography,
  MatchResult,
  RuneIconGroup,
  SpellIconGroup,
} from 'components';
import {
  formatPercentage,
  formatRate,
  formatRatio,
  gameVersionToDDragonVersion,
} from 'helpers';
import { useAccount, useMatch } from 'hooks';

const LevelBadge: React.FC<{ level?: number } & React.PropsWithChildren> = ({
  level,
  children,
}) => {
  const theme = useTheme();
  return (
    <Badge
      badgeContent={
        <Stack
          direction='row'
          width='100%'
          height='100%'
          justifyContent='center'
          alignItems='center'
        >
          <Typography
            variant='caption'
            fontWeight='bold'
            lineHeight={0}
            letterSpacing={0}
          >
            {level}
          </Typography>
        </Stack>
      }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{
        '& .MuiBadge-badge': {
          right: theme.spacing(1.5),
          bottom: theme.spacing(1.75),
          padding: 0,
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
          zIndex: 0,
        },
      }}
    >
      {children}
    </Badge>
  );
};

interface Props {
  matchId?: string;
}

export const MatchItemBody: React.FC<Props> = ({ matchId }) => {
  const theme = useTheme(),
    xs = useMediaQuery(theme.breakpoints.down('sm')),
    { account } = useAccount(),
    { match } = useMatch(matchId),
    version = gameVersionToDDragonVersion(match?.info.gameVersion),
    participant = match?.info.participants.find(
      ({ puuid }) => puuid === account?.puuid,
    ),
    gameDuration = match?.info.gameDuration ?? 0,
    kills = participant?.kills ?? 0,
    deaths = participant?.deaths ?? 0,
    assists = participant?.assists ?? 0,
    goldEarned = participant?.goldEarned ?? 0,
    team = match?.info.teams.find((t) => t.teamId === participant?.teamId),
    teammates = match?.info.participants.filter(
      ({ teamId }) => teamId === team?.teamId,
    ),
    totalTeamDamage =
      teammates?.reduce(
        (dmg, participant) => dmg + participant.totalDamageDealtToChampions,
        0,
      ) ?? 0,
    dmg = participant?.totalDamageDealtToChampions ?? 0,
    dmgR = formatRate(dmg, gameDuration, 60, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
    dmgP = formatPercentage(dmg, totalTeamDamage),
    totalTeamKills =
      teammates?.reduce((kills, participant) => kills + participant.kills, 0) ??
      0,
    kda = formatRatio(kills + assists, deaths, { maximumFractionDigits: 2 }),
    kp = formatPercentage(kills + assists, totalTeamKills),
    totalTeamGold =
      teammates?.reduce(
        (gold, participant) => gold + participant.goldEarned,
        0,
      ) ?? 0,
    goldR = formatRate(goldEarned, gameDuration, 60, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
    goldP = formatPercentage(goldEarned, totalTeamGold),
    totalTeamCS =
      teammates?.reduce(
        (cs, participant) => cs + participant.totalMinionsKilled,
        0,
      ) ?? 0,
    cs = participant?.totalMinionsKilled ?? 0,
    csR = formatRate(cs, gameDuration, 60, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }),
    csP = formatPercentage(cs, totalTeamCS);
  return (
    <Stack direction='row' pt={0.5}>
      <Stack direction='row' flex={1} spacing={2} alignItems='center'>
        {matchId ? (
          <Stack direction='row'>
            <LevelBadge level={participant?.champLevel}>
              <ChampionIcon
                championId={participant?.championId}
                AvatarProps={{
                  sx: {
                    width: 80,
                    height: 80,
                    border: `1px solid ${theme.palette.divider}`,
                    borderTopRightRadius: {
                      xs: theme.shape.borderRadius,
                      sm: 0,
                    },
                    borderBottomRightRadius: {
                      xs: theme.shape.borderRadius,
                      sm: 0,
                    },
                  },
                }}
              />
            </LevelBadge>
            <SpellIconGroup
              id1={participant?.summoner1Id}
              id2={participant?.summoner2Id}
              AvatarProps={{ sx: { width: 40, height: 40 } }}
              sx={{
                display: { xs: 'none', sm: 'block' },
                '> .MuiAvatar-root': {
                  borderLeftWidth: 0,
                  '&:first-of-type': {
                    borderTopLeftRadius: 0,
                  },
                  '&:last-of-type': {
                    borderBottomLeftRadius: 0,
                  },
                },
              }}
            />
          </Stack>
        ) : (
          <Skeleton width={120} height={80} />
        )}
        <RuneIconGroup
          participant={participant}
          version={version}
          PrimaryAvatarProps={{ sx: { width: 60, height: 60 } }}
          SecondaryAvatarProps={{ sx: { width: 35, height: 35, p: '4px' } }}
          display={{ xs: 'none', sm: 'block' }}
        />
      </Stack>

      <Stack
        flex={1}
        justifyContent='space-evenly'
        alignItems='center'
        textAlign='center'
      >
        <MatchResult
          win={participant?.win}
          gameDuration={gameDuration}
          remake={participant?.gameEndedInEarlySurrender}
        />
        <KdaTypography
          alignSelf='center'
          kills={kills}
          deaths={deaths}
          assists={assists}
          loading={matchId === undefined}
        />
        <Typography variant='caption'>
          {matchId ? (
            <>
              {kda} KDA{xs ? '' : ` \u2022 ${kp} KP`}
            </>
          ) : (
            <Skeleton width={100} />
          )}
        </Typography>
      </Stack>

      <Stack flex={1} textAlign='right' justifyContent='space-evenly'>
        <Typography variant='body2'>
          {matchId ? (
            <>
              {dmgR}{' '}
              <Typography variant='caption' color='text.secondary'>
                {' '}
                DMG/MIN{xs ? '' : ` (${dmgP})`}
              </Typography>
            </>
          ) : (
            <Skeleton />
          )}
        </Typography>

        <Typography variant='body2'>
          {matchId ? (
            <>
              {csR}{' '}
              <Typography variant='caption' color='text.secondary'>
                {' '}
                CS/MIN{xs ? '' : ` (${csP})`}
              </Typography>
            </>
          ) : (
            <Skeleton />
          )}
        </Typography>

        <Typography variant='body2'>
          {matchId ? (
            <>
              {goldR}{' '}
              <Typography variant='caption' color='text.secondary'>
                {' '}
                GOLD/MIN{xs ? '' : ` (${goldP})`}
              </Typography>
            </>
          ) : (
            <Skeleton />
          )}
        </Typography>
      </Stack>
    </Stack>
  );
};
