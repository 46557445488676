import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, CardActionArea, Stack } from '@mui/material';

import { useRiotId } from 'hooks';

import { MatchItemBody } from './MatchItemBody';
import { MatchItemHeader } from './MatchItemHeader';

interface Props {
  matchId?: string;
}

export const MatchItem: React.FC<Props> = ({ matchId }) => {
  const navigate = useNavigate(),
    [{ gameName, tagLine }] = useRiotId();
  return (
    <Card elevation={0} sx={{ borderRadius: 0 }} component='li'>
      <CardActionArea
        onClick={(e) => {
          e.preventDefault();
          if (matchId) navigate(matchId);
        }}
        href={
          // TODO: support all regions
          gameName && tagLine && matchId
            ? `/profile/na1/${gameName}-${tagLine}/history/${matchId}`
            : ''
        }
      >
        <Stack sx={{ p: (theme) => theme.spacing(1, 2) }} flexGrow={1}>
          <MatchItemHeader matchId={matchId} />
          <MatchItemBody matchId={matchId} />
        </Stack>
      </CardActionArea>
    </Card>
  );
};
