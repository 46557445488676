export const MatchQueues = new Map([
  [400, 'Draft Pick'],
  [420, 'Solo/Duo'],
  [430, 'Blind Pick'],
  [440, 'Ranked Flex'],
  [450, 'ARAM'],
  [490, 'Blind Pick'],
  [900, 'URF'],
  [1020, 'One for All'],
  [1300, 'Nexus Blitz'],
  [1700, 'Arena'],
]);

export const MatchQueuesXS = new Map([
  [400, 'Draft'],
  [420, 'Solo/Duo'],
  [430, 'Blind'],
  [440, 'Flex'],
  [450, 'ARAM'],
  [490, 'Blind'],
  [900, 'URF'],
  [1020, 'OFA'],
  [1300, 'Blitz'],
  [1700, 'Arena'],
]);
