import { useParams } from 'react-router-dom';

import { MatchV5ParticipantDto } from '@blakearoberts/riot-api-ts';
import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { brown, grey } from '@mui/material/colors';

import {
  Container,
  ExtractDatumKeys,
  Grid,
  StackedBarChart,
  Tooltip,
  XAxisBand,
  YAxis,
} from 'd4';
import { useAccount, useMatch } from 'hooks';
import { ChampionIcon, SVGChampionIcon } from 'components';
import { useLocalStorage } from 'usehooks-ts';

type Datum = ExtractDatumKeys<MatchV5ParticipantDto> & {
  damageDealtToEpicMonsters: number;
  damageDealtToBuildings: number;
};

const useColors = () => {
  const theme = useTheme();
  return {
    damageDealtToBuildings: grey[500],
    damageDealtToEpicMonsters: brown[500],
    goldEarned: theme.palette.gold.main,
    magicDamageDealtToChampions: theme.palette.magic.main,
    magicDamageTaken: theme.palette.magic.main,
    neutralMinionsKilled: brown[500],
    physicalDamageDealtToChampions: theme.palette.physical.main,
    physicalDamageTaken: theme.palette.physical.main,
    timeCCingOthers: theme.palette.cc.main,
    totalDamageShieldedOnTeammates:
      theme.palette.mode === 'light' ? grey[500] : theme.palette.true.main,
    trueDamageDealtToChampions:
      theme.palette.mode === 'light' ? grey[500] : theme.palette.true.main,
    trueDamageTaken:
      theme.palette.mode === 'light' ? grey[500] : theme.palette.true.main,
    totalHeal: theme.palette.health.main,
    totalHealsOnTeammates: theme.palette.health.main,
    totalMinionsKilled: grey[500],
  } as { [k: string]: string };
};

interface Stat {
  title: string;
  label?: string;
  keys: Extract<keyof Datum, string>[];
  labels?: string[];
  unit?: string;
}

const stats: Stat[] = [
  {
    title: 'Damage to Champions',
    label: 'Damage',
    keys: [
      'magicDamageDealtToChampions',
      'physicalDamageDealtToChampions',
      'trueDamageDealtToChampions',
    ],
    labels: ['magic', 'physical', 'true'],
  },
  {
    title: 'Damage Taken',
    keys: ['magicDamageTaken', 'physicalDamageTaken', 'trueDamageTaken'],
    labels: ['magic', 'physical', 'true'],
  },
  // TODO: update gold to be a stacked bar chart breaking down of how gold was earned
  {
    title: 'Gold',
    label: 'Gold',
    keys: ['goldEarned'],
    unit: 'g',
  },
  {
    title: 'Creep Score',
    keys: ['totalMinionsKilled', 'neutralMinionsKilled'],
    unit: 'cs',
    labels: ['lane', 'jungle'],
  },
  // TODO: is it possible to break down cc by type or by hard/soft?
  {
    title: 'Crowd Control',
    keys: ['timeCCingOthers'],
    unit: 'cc',
  },
  {
    title: 'Heals/Shields to Teammates',
    label: 'Heals/Shields',
    keys: ['totalHealsOnTeammates', 'totalDamageShieldedOnTeammates'],
    labels: ['heals', 'shields'],
  },
  {
    title: 'Damage to Objectives',
    label: 'Objectives',
    keys: ['damageDealtToEpicMonsters', 'damageDealtToBuildings'],
    labels: ['monsters', 'buildings'],
  },
];

export const Stats: React.FC = () => {
  const theme = useTheme(),
    xl = useMediaQuery(theme.breakpoints.up('xl')),
    colors = useColors(),
    { matchId } = useParams(),
    { match } = useMatch(matchId),
    { account } = useAccount(),
    summonerTeamId = match?.info.participants.find(
      ({ puuid }) => puuid === account?.puuid,
    )?.teamId,
    [stat, setStat] = useLocalStorage<Stat>(
      'match-summary-display-option-stat',
      stats[0],
    ),
    data = match?.info.participants.map(
      ({ damageDealtToObjectives, damageDealtToBuildings, ...rest }) => ({
        damageDealtToObjectives,
        damageDealtToBuildings,
        damageDealtToEpicMonsters:
          damageDealtToObjectives - (damageDealtToBuildings ?? 0),
        ...rest,
      }),
    ) as Datum[],
    series = ({ summonerId }: Datum) => summonerId,
    accessor = (d: Datum) =>
      stat.keys.reduce((total, k) => (d[k] as number) + total, 0);
  return (
    <Stack spacing={1} direction={xl ? 'row' : 'column'}>
      <Box flexShrink={0} flexGrow={0}>
        <Typography
          variant='subtitle1'
          sx={{ pb: 1, display: xl ? 'block' : 'none' }}
        >
          Display
        </Typography>
        <FormControl
          sx={{
            pr: { lg: 1 },
            pb: { xs: 0.5, sm: 1, xl: 0 },
            minWidth: 180,
            maxWidth: 180,
          }}
        >
          <Select
            size='small'
            value={stat.title}
            onChange={(e) =>
              setStat(stats.find(({ title }) => title === e.target.value)!)
            }
          >
            {stats.map(({ title, label }) => (
              <MenuItem key={title} value={title}>
                {label ?? title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Divider orientation={xl ? 'vertical' : 'horizontal'} flexItem />
      <Container data={data ?? []} padding={[8, 0, 40, 32]}>
        {xl && (
          <Typography variant='h5' sx={{ pb: 1 }}>
            {stat.title}
          </Typography>
        )}
        <StackedBarChart<Datum>
          series={series}
          keys={stat.keys}
          color={(k) => colors[k]}
        >
          <Grid stroke={theme.palette.divider} yTickCount={6} />
          <XAxisBand
            tick={(t) => {
              const p = match?.info.participants.find(
                ({ summonerId }) => summonerId === t,
              );
              return (
                <SVGChampionIcon
                  championId={p?.championId}
                  size={30}
                  team={p?.teamId === summonerTeamId}
                  transform='translate(0 23)'
                />
              );
            }}
          />
          <YAxis tickCount={6} />
          <StatsTooltip
            series={series}
            accessor={accessor}
            values={(d) =>
              stat.keys.map((k, i) => ({
                value: d[k] as number,
                color: colors[k],
                label: stat.labels?.at(i),
              }))
            }
            unit={stat.unit}
          />
        </StackedBarChart>
      </Container>
    </Stack>
  );
};

interface StatsTooltipValue {
  value: number;
  color: string;
  label?: string;
}

interface StatsTooltipProps {
  series: (d: Datum) => string;
  accessor: (d: Datum) => number;
  values: (d: Datum) => StatsTooltipValue[];
  unit?: string;
}

const StatsTooltip = ({
  series,
  accessor,
  values,
  unit,
}: StatsTooltipProps) => {
  const theme = useTheme(),
    { matchId } = useParams(),
    { account } = useAccount(),
    { match } = useMatch(matchId),
    summonerTeamId = match?.info.participants.find(
      ({ puuid }) => puuid === account?.puuid,
    )?.teamId;
  return (
    <Tooltip<Datum, string>
      accessors={[series, accessor]}
      content={({ datum: d }) => {
        if (d === undefined) return <></>;
        const p = match?.info.participants.find(
            ({ summonerId }) => summonerId === series(d),
          ),
          total = accessor(d),
          vs = values(d);
        return (
          <Paper sx={{ p: 1 }} elevation={3}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <ChampionIcon
                championId={p?.championId}
                AvatarProps={{
                  variant: 'circular',
                  sx: {
                    width: 32,
                    height: 32,
                    border: `2px solid ${
                      p?.teamId === summonerTeamId
                        ? theme.palette.win.main
                        : theme.palette.lose.main
                    }`,
                  },
                }}
              />
              <Box>
                <Typography variant='subtitle1'>
                  {(d.riotIdGameName ?? d.summonerName) + '#' + d.riotIdTagline}
                </Typography>
                <Typography variant='body1'>
                  {total.toLocaleString()} {unit}
                  {!vs.every(({ value }) => value === 0) && vs.length > 1 ? (
                    <Typography variant='caption'>
                      {' '}
                      (
                      {[
                        ...vs
                          .sort(({ value: a }, { value: b }) => b - a)
                          .filter(({ value }) => value !== 0)
                          .map(({ value: v, color }, i) => (
                            <Typography
                              key={i}
                              color={color}
                              component='span'
                              fontWeight={500}
                              variant='caption'
                            >
                              {' '}
                              {(v / total).toLocaleString(undefined, {
                                style: 'percent',
                              })}
                            </Typography>
                          )),
                      ]}{' '}
                      )
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Typography>

                {vs.every(({ label }) => label !== undefined) &&
                vs.length > 1 ? (
                  vs
                    .filter(({ value }) => value !== 0)
                    .map(({ value, color, label }, i) => (
                      <Stack
                        key={i}
                        direction='row'
                        spacing={0.5}
                        width={'100%'}
                      >
                        <Typography
                          color={color}
                          flexBasis={'30%'}
                          flexGrow={0}
                          flexShrink={0}
                          fontWeight={500}
                          textAlign={'right'}
                          variant='caption'
                        >
                          {value.toLocaleString(undefined, {
                            notation: 'compact',
                          })}
                        </Typography>
                        <Typography
                          color='text.secondary'
                          fontWeight={500}
                          variant='caption'
                          width={'70%'}
                        >
                          {label}
                        </Typography>
                      </Stack>
                    ))
                ) : (
                  <></>
                )}
              </Box>
            </Stack>
          </Paper>
        );
      }}
    />
  );
};
