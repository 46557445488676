import * as d3 from 'd3';

import { Chart } from './Chart';
import { ChartContextProvider, Datum, StringValue, useChart } from './context';
import { useBandScale, useLinearScale, useRangeScale, useStack } from './hooks';

interface ChartProps<D> extends React.PropsWithChildren {
  value: (d: D | undefined) => number;
  series: (d: D) => string;
  order?: (
    series: d3.Series<[number, d3.InternMap<string, D>], string>[],
  ) => Iterable<number>;
  offset?: (
    series: d3.Series<[number, d3.InternMap<string, D>], string>[],
    order: number[],
  ) => void;
  color?: (k: string) => string;
}

interface ContinuousChartProps<D> extends ChartProps<D> {
  domain: (d: D) => d3.NumberValue;
  curve?: d3.CurveFactory;
}

export const ContinuousChart = <D extends Datum>({
  domain,
  value,
  series,
  order,
  offset,
  color,
  curve,
  children,
}: ContinuousChartProps<D>) => {
  const { data, ...ctx } = useChart<D>(),
    stack = useStack(domain, value, series, order, offset),
    x = useLinearScale().domain(d3.extent(data, domain).map((v) => v ?? 0)),
    y = useRangeScale().domain([
      d3.min(stack, (d) => d3.min(d, (d) => d[0])) ?? 0,
      d3.max(stack, (d) => d3.max(d, (d) => d[1])) ?? 0,
    ]),
    area = d3
      .area<d3.SeriesPoint<[d3.NumberValue, d3.InternMap<string, D>]>>()
      .x((d) => x(d.data[0]))
      .y0((d) => y(d[0]))
      .y1((d) => y(d[1]))
      .curve(curve ?? d3.curveMonotoneX);

  color ??= d3
    .scaleOrdinal<string>()
    .domain(stack.map((d) => d.key))
    .range(d3.schemeTableau10);

  return (
    <ChartContextProvider
      value={{
        ...ctx,
        data,
        x,
        y,
      }}
    >
      <Chart>
        {children}
        <g data-chart-component='chart' data-chart-type='tidy'>
          {stack.map((s, i) => {
            return (
              <g key={i} data-series={series(s.at(0)?.data[1].get(s.key)!)}>
                <path d={area(s) ?? undefined} fill={color!(s.key)} />
              </g>
            );
          })}
        </g>
      </Chart>
    </ChartContextProvider>
  );
};

interface OrdinalChartProps<D, Domain extends StringValue = StringValue>
  extends ChartProps<D> {
  domain: (d: D) => Domain;
  padding?: number | { inner?: number; outer?: number };
}

export const OrdinalChart = <
  D extends Datum,
  Domain extends StringValue = StringValue,
>({
  domain,
  value,
  series,
  order,
  offset,
  color,
  padding: p,
  children,
}: OrdinalChartProps<D, Domain>) => {
  const { data, ...ctx } = useChart<D, Domain>(),
    stack = useStack(domain, value, series, order, offset),
    x = useBandScale<Domain>()
      .domain(data.map(domain))
      .paddingInner(typeof p === 'number' ? p : p?.inner ?? 0)
      .paddingOuter(typeof p === 'number' ? p : p?.outer ?? 0),
    y = useRangeScale().domain([
      d3.min(stack, (d) => d3.min(d, (d) => d[0])) ?? 0,
      d3.max(stack, (d) => d3.max(d, (d) => d[1])) ?? 0,
    ]);

  color ??= d3
    .scaleOrdinal<string>()
    .domain(stack.map((d) => d.key))
    .range(d3.schemeTableau10);

  return (
    <ChartContextProvider
      value={{
        ...ctx,
        data,
        x,
        y,
        stack,
      }}
    >
      <Chart>
        {children}
        <g data-chart-component='chart' data-chart-type='tidy-band'>
          {stack.map((s, i) => {
            return (
              <g key={i} data-series={s.key} fill={color!(s.key)}>
                {s.map((p, j) => (
                  <rect
                    key={j}
                    x={x(p.data[0])}
                    y={y(p[1])}
                    width={x.bandwidth()}
                    height={Math.max(0, y(p[0]) - y(p[1]))}
                  />
                ))}
              </g>
            );
          })}
        </g>
      </Chart>
    </ChartContextProvider>
  );
};
