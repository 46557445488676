import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { blue, red } from '@mui/material/colors';

import { ChampionIcon, PositionIcon } from 'components';
import { useDDragonData, useSummonerChampionStats } from 'hooks';

export const ChampionStats: React.FC = () => {
  const theme = useTheme(),
    { champions: stats } = useSummonerChampionStats(),
    { champions: raw } = useDDragonData(),
    winP = (wins: number, losses: number) =>
      (wins / (wins + losses)).toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
  if (stats === undefined || raw === undefined) return <></>;

  const champions = raw.filter(({ key }) =>
    stats.find(({ championId }) => key === championId),
  );
  return (
    <Stack
      sx={{
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: '185px',
        maxHeight: '275px',
        overflow: 'hidden',
      }}
    >
      <Divider />
      <Typography
        sx={{ p: theme.spacing(1, 2) }}
        variant='subtitle1'
        fontSize='1.15em'
      >
        Most Played
        <Typography variant='caption'> Last 90 Days</Typography>
      </Typography>

      <Stack
        spacing={1}
        sx={{
          p: theme.spacing(0, 1, 1, 2),
          flexGrow: 1,
          flexShrink: 0,
          flexBasis: '0%',
          minHeight: 0,
          overflow: 'auto',
        }}
      >
        {stats
          .slice(0, 5)
          .map(
            (
              { championId, position, wins, losses, kills, deaths, assists },
              i,
            ) => {
              const champion = champions.find(({ key }) => key === championId),
                kda = ((kills + assists) / deaths).toLocaleString(undefined, {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                });
              return (
                <Stack key={i} direction='row' alignItems='center' spacing={1}>
                  <ChampionIcon
                    championId={Number.parseInt(championId)}
                    enableLink
                    AvatarProps={{ sx: { width: 36, height: 36 } }}
                  />
                  <Stack
                    width='55%'
                    direction='row'
                    alignItems='center'
                    spacing={1}
                  >
                    <PositionIcon position={position} />
                    <Stack
                      direction='row'
                      sx={{ overflow: 'hidden' }}
                      alignItems='center'
                      spacing={1}
                    >
                      <Typography
                        component='span'
                        flex={1}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {champion?.name}
                      </Typography>
                      <Typography
                        component='span'
                        color={wins - losses >= 0 ? blue[300] : red[300]}
                        fontSize={theme.typography.caption.fontSize}
                      >
                        ({winP(wins, losses)})
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack flex={1}>
                    <Typography
                      variant='body2'
                      textAlign='center'
                      fontSize={theme.typography.caption.fontSize}
                    >
                      {wins}W &#x2022; {losses}L
                    </Typography>
                    <Typography
                      variant='body2'
                      textAlign='center'
                      fontSize={theme.typography.caption.fontSize}
                    >
                      {kda} <Typography variant='caption'>KDA</Typography>
                    </Typography>
                  </Stack>
                </Stack>
              );
            },
          )}
      </Stack>
    </Stack>
  );
};
