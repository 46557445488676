import * as d3 from 'd3';
import { StringValue } from './context';

export type Scale<Domain extends StringValue = StringValue> =
  | d3.ScaleLinear<number, number>
  | d3.ScaleBand<Domain>;

export const isScaleContinuousNumeric = <
  Domain extends StringValue = StringValue,
>(
  s: Scale<Domain>,
): s is d3.ScaleLinear<number, number> =>
  'ticks' in s && typeof s.ticks === 'function';

export const isScaleBand = <Domain extends StringValue = StringValue>(
  s: Scale<Domain>,
): s is d3.ScaleBand<Domain> =>
  'bandwidth' in s && typeof s.bandwidth === 'function';
