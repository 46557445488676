/**
 * StatBud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MatchV5ObjectiveDto } from '../models/MatchV5ObjectiveDto';
import { HttpFile } from '../http/http';

export class MatchV5ObjectivesDto {
    'baron': MatchV5ObjectiveDto;
    'champion': MatchV5ObjectiveDto;
    'dragon': MatchV5ObjectiveDto;
    'horde'?: MatchV5ObjectiveDto;
    'inhibitor': MatchV5ObjectiveDto;
    'riftHerald': MatchV5ObjectiveDto;
    'tower': MatchV5ObjectiveDto;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "baron",
            "baseName": "baron",
            "type": "MatchV5ObjectiveDto",
            "format": ""
        },
        {
            "name": "champion",
            "baseName": "champion",
            "type": "MatchV5ObjectiveDto",
            "format": ""
        },
        {
            "name": "dragon",
            "baseName": "dragon",
            "type": "MatchV5ObjectiveDto",
            "format": ""
        },
        {
            "name": "horde",
            "baseName": "horde",
            "type": "MatchV5ObjectiveDto",
            "format": ""
        },
        {
            "name": "inhibitor",
            "baseName": "inhibitor",
            "type": "MatchV5ObjectiveDto",
            "format": ""
        },
        {
            "name": "riftHerald",
            "baseName": "riftHerald",
            "type": "MatchV5ObjectiveDto",
            "format": ""
        },
        {
            "name": "tower",
            "baseName": "tower",
            "type": "MatchV5ObjectiveDto",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return MatchV5ObjectivesDto.attributeTypeMap;
    }

    public constructor() {
    }
}

