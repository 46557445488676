import { useState } from 'react';

import {
  Box,
  Drawer,
  SwipeableDrawer,
  Toolbar,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { useAccount } from 'hooks';

import { ChampionStats } from './ChampionStats';
import { Profile } from './Profile';

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: grey[300],
  [theme.getColorSchemeSelector('dark')]: {
    backgroundColor: grey[900],
  },
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

interface SwipeableContextDrawerProps {
  children: React.ReactNode;
}

const SwipeableContextDrawer: React.FC<SwipeableContextDrawerProps> = ({
  children,
}) => {
  const theme = useTheme(),
    [open, setOpen] = useState(false),
    toggle = (open: boolean) => () => setOpen(open);
  return (
    <SwipeableDrawer
      anchor='bottom'
      open={open}
      onClose={toggle(false)}
      onOpen={toggle(true)}
      disableSwipeToOpen={false}
      PaperProps={{
        sx: {
          overflow: 'visible',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage: theme.vars.overlays[10],
          },
        },
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: -23,
          right: 0,
          left: 0,
          borderRadius: theme.spacing(2, 2, 0, 0),
          backgroundColor: 'inherit',
          backgroundImage: 'inherit',
          height: 24,
        }}
      >
        <Puller />
      </Box>
      {children}
    </SwipeableDrawer>
  );
};

interface PermanentContextDrawerProps {
  children: React.ReactNode;
}

const PermanentContextDrawer: React.FC<PermanentContextDrawerProps> = ({
  children,
}) => {
  const theme = useTheme();
  return (
    <Drawer
      anchor='right'
      variant='permanent'
      PaperProps={{
        className: 'mui-fixed',
      }}
    >
      <Box
        sx={{
          ...theme.mixins.drawer.context,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        {children}
      </Box>
    </Drawer>
  );
};

export const ContextDrawer: React.FC = () => {
  const theme = useTheme(),
    { account, loading } = useAccount(),
    sm = useMediaQuery(theme.breakpoints.down('md'));
  return account === undefined && !loading ? (
    <></>
  ) : sm ? (
    <SwipeableContextDrawer>
      <Profile />
    </SwipeableContextDrawer>
  ) : (
    <PermanentContextDrawer>
      <Toolbar />
      <Profile />
      <ChampionStats />
    </PermanentContextDrawer>
  );
};
