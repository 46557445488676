import { useItemIcon } from 'hooks';

interface Props extends React.SVGProps<SVGGElement> {
  itemId?: string;
  version?: string;
  size?: number;
  href?: string;
}

export const SVGItemIcon: React.FC<Props> = ({
  itemId,
  version,
  size,
  href,
  ...rest
}) => {
  const icon = useItemIcon(itemId, version);
  size ??= 40;
  const r = size / 2;
  return (
    <g {...rest}>
      <a href={href} target='_blank' rel='noreferrer'>
        <image
          width={size}
          height={size}
          x={-r}
          y={-r}
          href={icon}
          clipPath='inset(0% round 10%)'
        />
      </a>
    </g>
  );
};
