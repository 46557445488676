import {
  Skeleton,
  Stack,
  StackProps,
  Typography,
  TypographyProps,
} from '@mui/material';

interface ValueProps extends StackProps {
  TypographyProps?: TypographyProps;
  children?: React.ReactNode;
}

const Value: React.FC<ValueProps> = ({
  TypographyProps,
  children,
  ...rest
}) => (
  <Stack direction='row' width='2ch' justifyContent='center' {...rest}>
    <Typography {...TypographyProps}>{children}</Typography>
  </Stack>
);

const Separator: React.FC<TypographyProps> = (props) => (
  <Stack direction='row' width='1ch' justifyContent='center'>
    <Typography color='text.secondary' {...props}>
      /
    </Typography>
  </Stack>
);

interface Props extends StackProps {
  kills: number;
  deaths: number;
  assists: number;
  loading?: boolean;
  TypographyProps?: TypographyProps;
}

export const KdaTypography: React.FC<Props> = ({
  kills,
  deaths,
  assists,
  loading,
  TypographyProps,
  ...rest
}) => {
  return loading ? (
    <Typography {...TypographyProps}>
      <Skeleton width={100} sx={{ maxWidth: '100px' }} />
    </Typography>
  ) : (
    <Stack
      width='100%'
      maxWidth='100px'
      direction='row'
      textAlign='center'
      justifyContent='space-evenly'
      {...rest}
    >
      <Value TypographyProps={TypographyProps}>{kills}</Value>
      <Separator {...TypographyProps} />
      <Value
        width={deaths < 10 ? '1ch' : '2ch'}
        TypographyProps={TypographyProps}
      >
        {deaths}
      </Value>
      <Separator {...TypographyProps} />
      <Value TypographyProps={TypographyProps}>{assists}</Value>
    </Stack>
  );
};
