import React from 'react';

import { Avatar } from '@mui/material';

import { Chips, ChipsProps } from './Chips';

const tags = [
  'Assassin',
  'Fighter',
  'Mage',
  'Marksman',
  'Support',
  'Tank',
] as const;

export const ChampionTagFilter: React.FC<
  Omit<ChipsProps<string>, 'values' | 'labels' | 'icons'>
> = ({ ...rest }) => {
  return (
    <Chips
      values={tags}
      icons={tags.map((tag) => (
        <Avatar
          variant='square'
          alt={tag}
          src={`https://raw.communitydragon.org/13.4/plugins/rcp-fe-lol-champion-details/global/default/role-icon-${tag.toLowerCase()}.png`}
          color='primary'
        />
      ))}
      {...rest}
    />
  );
};
