/**
 * StatBud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { StatbudV1Rank } from '../models/StatbudV1Rank';
import { StatbudV1Tier } from '../models/StatbudV1Tier';
import { HttpFile } from '../http/http';

/**
* Statbud match object with optional league entry information.
*/
export class StatbudV1LeagueEntryWithMatchIdDto {
    'matchId': string;
    'entryTimestamp': number;
    'tier': StatbudV1Tier;
    'rank': StatbudV1Rank;
    'leaguePoints': number;
    'wins': number;
    'losses': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "matchId",
            "baseName": "matchId",
            "type": "string",
            "format": ""
        },
        {
            "name": "entryTimestamp",
            "baseName": "entryTimestamp",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "tier",
            "baseName": "tier",
            "type": "StatbudV1Tier",
            "format": ""
        },
        {
            "name": "rank",
            "baseName": "rank",
            "type": "StatbudV1Rank",
            "format": ""
        },
        {
            "name": "leaguePoints",
            "baseName": "leaguePoints",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "wins",
            "baseName": "wins",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "losses",
            "baseName": "losses",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return StatbudV1LeagueEntryWithMatchIdDto.attributeTypeMap;
    }

    public constructor() {
    }
}



