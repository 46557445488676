import React, { useRef } from 'react';

import { CardMedia, CardMediaProps } from '@mui/material';
import { useIntersectionObserver } from 'usehooks-ts';

export const LazyCardMedia: React.FC<CardMediaProps> = ({ image, ...rest }) => {
  const ref = useRef<HTMLDivElement | null>(null),
    isVisible = !!useIntersectionObserver(ref, { freezeOnceVisible: true })
      ?.isIntersecting;
  return (
    <CardMedia ref={ref} image={isVisible ? image : undefined} {...rest} />
  );
};
