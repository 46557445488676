import { createContext, useContext } from 'react';

import * as d3 from 'd3';

import { Scale } from './types';

// TODO: move types to types.ts

export type KeysOfValueType<T, U> = {
  [K in keyof T]: T[K] extends U ? K : never;
}[keyof T];

export type ExtractKeysOfValueType<T, U> = {
  [K in KeysOfValueType<T, U>]: T[K] extends U ? T[K] : never;
};
export type ExtractDatumKeys<T> = ExtractKeysOfValueType<T, DatumValue>;

export type StringValue = string | { toString(): string };
export type DatumValue = StringValue | d3.NumberValue | undefined;
export type Datum = { [k: string]: DatumValue };

export type ContextValue<
  D extends Datum,
  Domain extends StringValue = string,
> = {
  ref?: React.RefObject<HTMLDivElement>;
  svg?: React.RefObject<SVGSVGElement>;
  width: number;
  height: number;
  padding: [number, number, number, number];
  data: D[];
  y: d3.ScaleLinear<number, number>;
  x: Scale<Domain>;
  stack?: d3.Series<[DatumValue, d3.InternMap<string, D>], string>[];
};

export const defaultChartContextValue: ContextValue<Datum, StringValue> = {
  width: 0,
  height: 0,
  padding: [8, 8, 24, 48],
  data: [],
  y: d3.scaleLinear(),
  x: d3.scaleLinear(),
};

export const ChartContext = createContext<ContextValue<Datum, StringValue>>(
  defaultChartContextValue,
);

interface Props<D extends Datum, Domain extends StringValue>
  extends React.PropsWithChildren {
  value: ContextValue<D, Domain>;
}

export const ChartContextProvider = <
  D extends Datum,
  Domain extends StringValue = string,
>({
  value,
  children,
}: Props<D, Domain>) => (
  <ChartContext.Provider value={value as any}>{children}</ChartContext.Provider>
);

export const useChart = <
  D extends Datum,
  Domain extends StringValue = string,
>() =>
  useContext<ContextValue<D, Domain>>(
    ChartContext as unknown as React.Context<ContextValue<D, Domain>>,
  );
