export type RankedQueueType = 'RANKED_SOLO_5x5' | 'RANKED_FLEX_SR';

export type QueueType = RankedQueueType | 'CHERRY';

const queueTypeMap: { [k: string]: number } = {
  RANKED_SOLO_5x5: 420,
  RANKED_FLEX_SR: 440,
  CHERRY: 1700,
};

export const queueTypeToId = (queueType?: QueueType) =>
  queueType ? queueTypeMap[queueType] : undefined;

const rankedQueueIdMap: { [k: number]: RankedQueueType } = {
  420: 'RANKED_SOLO_5x5',
  440: 'RANKED_FLEX_SR',
};

const queueIdMap: { [k: number]: QueueType } = {
  ...rankedQueueIdMap,
  1700: 'CHERRY',
};

export const rankedQueueIdToType = (queueId?: number) =>
  queueId ? rankedQueueIdMap[queueId] : undefined;

export const queueIdToType = (queueId?: number) =>
  queueId ? queueIdMap[queueId] : undefined;

export const gameVersionToDDragonVersion = (gameVersion?: string) =>
  gameVersion?.split('.').slice(0, 2).join('.').concat('.1');
