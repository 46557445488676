/**
 * StatBud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MatchV5BanDto } from '../models/MatchV5BanDto';
import { MatchV5ObjectivesDto } from '../models/MatchV5ObjectivesDto';
import { HttpFile } from '../http/http';

export class MatchV5TeamDto {
    'bans': Array<MatchV5BanDto>;
    'objectives': MatchV5ObjectivesDto;
    'teamId': number;
    'win': boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "bans",
            "baseName": "bans",
            "type": "Array<MatchV5BanDto>",
            "format": ""
        },
        {
            "name": "objectives",
            "baseName": "objectives",
            "type": "MatchV5ObjectivesDto",
            "format": ""
        },
        {
            "name": "teamId",
            "baseName": "teamId",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "win",
            "baseName": "win",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return MatchV5TeamDto.attributeTypeMap;
    }

    public constructor() {
    }
}

