import React, { useState } from 'react';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

interface Item {
  text: string;
  href?: string;
  onClick: React.MouseEventHandler<HTMLElement>;
}

interface SubListLinkProps {
  href?: string;
  onClick: React.MouseEventHandler<HTMLElement>;
  children: React.ReactNode;
}

const SubListLink: React.FC<SubListLinkProps> = ({
  href,
  children,
  onClick,
}) => {
  const navigate = useNavigate(),
    resolved = useResolvedPath(href || ''),
    match = useMatch({ path: resolved.pathname, end: true });
  return (
    <ListItemButton
      sx={{ pl: 4 }}
      selected={Boolean(match) && href !== undefined}
      disabled={href === undefined}
      href={href ?? ''}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        navigate(href!);
      }}
    >
      {children}
    </ListItemButton>
  );
};

interface Props {
  text: string;
  icon?: React.ReactNode;
  items: Item[];
}

export const SubList: React.FC<Props> = ({ text, icon, items }) => {
  const [open, setOpen] = useState(true);
  return (
    <>
      <ListItemButton onClick={() => setOpen(!open)}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : <></>}

        <ListItemText primary={text} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open}>
        <List disablePadding>
          {items.map(({ text, href, onClick }, i) => (
            <SubListLink key={i} href={href} onClick={onClick}>
              <ListItemText primary={text} />
            </SubListLink>
          ))}
        </List>
      </Collapse>
    </>
  );
};
