import { useRef } from 'react';

import { ChartContextProvider, Datum, useChart } from './context';

interface Props extends React.PropsWithChildren {
  style?: React.CSSProperties;
}

export const Chart = <D extends Datum>({ style, children }: Props) => {
  const { width, height, ...rest } = useChart<D>(),
    svg = useRef<SVGSVGElement>(null);
  return (
    <ChartContextProvider value={{ ...rest, width, height, svg }}>
      <svg
        ref={svg}
        viewBox={width && height ? `0 0 ${width} ${height}` : undefined}
        style={{
          aspectRatio: '16/9',
          height: 'auto',
          ...style,
        }}
      >
        {children}
      </svg>
    </ChartContextProvider>
  );
};
