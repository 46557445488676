/**
 * StatBud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class MatchV5PerkStyleSelectionDto {
    'perk': number;
    'var1': number;
    'var2': number;
    'var3': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "perk",
            "baseName": "perk",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "var1",
            "baseName": "var1",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "var2",
            "baseName": "var2",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "var3",
            "baseName": "var3",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return MatchV5PerkStyleSelectionDto.attributeTypeMap;
    }

    public constructor() {
    }
}

