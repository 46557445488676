import { useChart } from './context';

interface ReferencePointProps {
  x: number | string;
  y: number;
  r?: number;

  label?: string;

  fill?: string;
  stroke?: string;
  strokeWidth?: string;
}

export const ReferencePoint: React.FC<ReferencePointProps> = ({
  x,
  y,
  r,
  label,
  fill,
  stroke,
  strokeWidth,
}) => {
  const { x: fx, y: fy } = useChart();
  return (
    <g transform={`translate(${fx(x as any)} ${fy(y)})`}>
      <circle
        r={r ?? 3}
        fill={fill ?? 'currentColor'}
        stroke={stroke ?? 'currentColor'}
        strokeWidth={strokeWidth ?? 0.5}
      />
      {label && (
        <text x={2} y={-4} fill='currentColor'>
          {label}
        </text>
      )}
    </g>
  );
};

interface ReferenceLineProps {
  x?: number | string;
  y?: number;

  label?: string | React.ReactElement<React.SVGProps<SVGElement>>;

  stroke?: string;
  strokeWidth?: string;
}

export const ReferenceLine: React.FC<ReferenceLineProps> = ({
  x,
  y,
  label,
  stroke,
  strokeWidth,
}) => {
  const { width, height, padding, data, x: fx, y: fy } = useChart(),
    labelPadding = 2,
    offset =
      typeof window !== 'undefined' && window.devicePixelRatio > 1 ? 0 : 0.5;

  if (width === 0 || height === 0 || data.length === 0) return <></>;

  let x1 = padding[3],
    x2 = width - padding[1];
  if (x !== undefined) x1 = x2 = fx(x as any) ?? 0;

  let y1 = height - padding[2],
    y2 = padding[0];
  if (y !== undefined) y1 = y2 = fy(y) ?? 0;

  if (
    x1 < padding[3] ||
    x2 > width - padding[1] ||
    y1 > height - padding[2] ||
    y2 < padding[0]
  )
    return <></>;

  return (
    <g data-chart-component='reference-line'>
      <line
        x1={x1}
        x2={x2}
        y1={y1}
        y2={y2}
        fill='none'
        stroke={stroke ?? 'currentColor'}
        strokeWidth={strokeWidth ?? 0.5}
      />
      {label && (
        <g
          transform={`translate(${x1 + labelPadding + offset} ${
            y1 - labelPadding - offset
          })`}
        >
          {typeof label === 'string' ? (
            <text fill='currentColor' style={{ userSelect: 'none' }}>
              {label}
            </text>
          ) : (
            label
          )}
        </g>
      )}
    </g>
  );
};

interface ReferenceAreaProps {
  x1?: number | string;
  x2?: number | string;
  y1?: number;
  y2?: number;
  fill?: string;
  fillOpacity?: number;
}

export const ReferenceArea: React.FC<ReferenceAreaProps> = ({
  x1,
  x2,
  y1,
  y2,
  fill,
  fillOpacity,
}) => {
  const { width, height, padding, data, x: fx, y: fy } = useChart();

  if (width === 0 || height === 0 || data.length === 0) return <></>;

  const xp1 = Math.max(
      x1 !== undefined ? fx(x1 as any) ?? 0 : padding[3],
      padding[3],
    ),
    yp1 = Math.max(y2 !== undefined ? fy(y2) ?? 0 : padding[0], padding[0]),
    xp2 = x2 !== undefined ? fx(x2 as any) ?? 0 : width - padding[1],
    yp2 = y1 !== undefined ? fy(y1) ?? 0 : height - padding[2],
    w = xp2 - Math.max(xp1, padding[3]),
    h = Math.min(yp2, height - padding[2]) - yp1;

  if (
    xp1 > width - padding[1] ||
    xp2 < padding[3] ||
    yp1 > height - padding[2] ||
    yp2 < padding[0]
  )
    return <></>;

  return (
    <g
      data-chart-component='reference-area'
      transform={`translate(${xp1} ${yp1})`}
    >
      <rect
        fill={fill ?? 'currentColor'}
        fillOpacity={fillOpacity}
        width={w}
        height={h}
      />
    </g>
  );
};
