import React from 'react';

import { MasteryAvatar } from 'components';

import { Chips, ChipsProps } from './Chips';

const masteries = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;

interface MasteryFilterProps
  extends Omit<ChipsProps<number>, 'values' | 'labels' | 'icons'> {}

export const MasteryFilter: React.FC<MasteryFilterProps> = ({ ...rest }) => {
  return (
    <Chips
      labels={masteries.map((level) => `Level ${level}`)}
      values={masteries}
      icons={masteries.map((level) => (
        <MasteryAvatar level={level} size={20} sx={{ padding: 0.125 }} />
      ))}
      {...rest}
    />
  );
};
