/**
 * StatBud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export enum DataV1Position {
    Top = 'TOP',
    Jungle = 'JUNGLE',
    Middle = 'MIDDLE',
    Bottom = 'BOTTOM',
    Utility = 'UTILITY',
    None = 'NONE'
}
