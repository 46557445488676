import { useTheme } from '@mui/material';
import { useChampion, useDDragonData } from 'hooks';

interface Props extends React.SVGProps<SVGGElement> {
  championId?: number;
  team?: boolean;
  size?: number;
}

export const SVGChampionIcon: React.FC<Props> = ({
  championId,
  team,
  size,
  ...rest
}) => {
  const theme = useTheme(),
    { version } = useDDragonData(),
    champion = useChampion(championId),
    href =
      version && champion
        ? `https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion.image.full}`
        : undefined;
  size ??= 40;
  const r = size / 2;
  return (
    <g {...rest}>
      <circle
        r={r + 1.5}
        fill={
          team === undefined
            ? 'currentColor'
            : team
            ? theme.palette.win.main
            : theme.palette.lose.main
        }
        strokeWidth={0}
      />
      <image
        width={size}
        height={size}
        x={-r}
        y={-r}
        href={href}
        clipPath='inset(0% round 50%)'
      />
    </g>
  );
};
