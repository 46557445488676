import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  DarkMode,
  Info,
  LightMode,
  ListAlt,
  MoreVert,
  Policy,
} from '@mui/icons-material';
import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Modal,
  useColorScheme,
} from '@mui/material';

import { Changelog } from 'components';

export const SettingsMenu: React.FC = () => {
  const { mode, setMode } = useColorScheme(),
    [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined),
    [open, setOpen] = useState<boolean>(false),
    navigate = useNavigate();
  return (
    <>
      <IconButton color='inherit' onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={anchorEl !== undefined}
        onClick={() => setAnchorEl(undefined)}
        onClose={() => setAnchorEl(undefined)}
      >
        <MenuItem onClick={() => setMode(mode !== 'dark' ? 'dark' : 'light')}>
          <ListItemIcon>
            {mode === 'light' ? <DarkMode /> : <LightMode />}
          </ListItemIcon>
          {mode === 'light' ? 'Dark Mode' : 'Light Mode'}
        </MenuItem>
        <MenuItem onClick={() => setOpen(true)}>
          <ListItemIcon>
            <ListAlt />
          </ListItemIcon>
          Changelog
        </MenuItem>
        <Divider />
        <MenuItem
          component='a'
          href='/terms'
          onClick={(e) => {
            e.preventDefault();
            navigate('/terms');
          }}
        >
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          Terms of Service
        </MenuItem>
        <MenuItem
          component='a'
          href='/privacy'
          onClick={(e) => {
            e.preventDefault();
            navigate('/privacy');
          }}
        >
          <ListItemIcon>
            <Policy />
          </ListItemIcon>
          Privacy Policy
        </MenuItem>
      </Menu>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <>
          <Changelog
            onClose={() => {
              setOpen(false);
            }}
          />
        </>
      </Modal>
    </>
  );
};
