import React, { ImgHTMLAttributes } from 'react';

import { LeagueV4LeagueEntryDTO } from '@blakearoberts/riot-api-ts';
import {
  Box,
  Divider,
  List,
  ListItem,
  Skeleton,
  Stack,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material';
import { blue, red } from '@mui/material/colors';

import { RankedQueueType } from 'helpers';
import { useLeagues } from 'hooks';

import { StreakChip } from './StreakChip';

const capitalize = ([first, ...rest]: string) =>
  (first?.toUpperCase() ?? '') + rest.join('');

const Image = styled('img')(() => ({}));

interface SkeletonImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  sx?: SxProps<Theme>;
}

const SkeletonImage: React.FC<SkeletonImageProps> = ({ sx, src, ...rest }) => {
  return src !== undefined ? (
    <Image src={src} sx={sx} {...rest} />
  ) : (
    <Box sx={{ width: 142, height: 142, p: 2 }}>
      <Skeleton variant='rounded' sx={{ height: '100%', ...sx }} />
    </Box>
  );
};

const SkeletonTypography: React.FC<{ alive?: boolean } & TypographyProps> = ({
  alive,
  children,
  ...rest
}) => <Typography {...rest}>{alive ? children : <Skeleton />}</Typography>;

interface QueueProps {
  league?: LeagueV4LeagueEntryDTO;
}

const Queue: React.FC<QueueProps> = ({ league }) => {
  const alive = league !== undefined,
    wins = league?.wins ?? 0,
    losses = league?.losses ?? 0,
    tier = (league?.tier ?? '').toLowerCase().replaceAll('_', '');
  return (
    <ListItem disableGutters disablePadding sx={{ alignItems: 'stretch' }}>
      <Box
        flex={1}
        lineHeight={0}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <SkeletonImage
          src={
            alive && tier
              ? `https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-shared-components/global/default/${tier}.png`
              : undefined
          }
          sx={{
            width: '100%',
            maxWidth: '125px',
            objectFit: 'contain',
          }}
        />
      </Box>
      <Stack flex={1} spacing={0.5} sx={{ p: 1 }} justifyContent='center'>
        <SkeletonTypography
          alive={alive}
          variant='h5'
          sx={{ fontSize: '1.25em' }}
        >
          {league?.queueType
            .split('_')
            .splice(0, 2)
            .join(' ')
            .toLocaleUpperCase()}
        </SkeletonTypography>
        <SkeletonTypography alive={alive} variant='body2'>
          {tier ? capitalize(tier) : 'N/A'} {league?.rank} &#x2022;{' '}
          {league?.leaguePoints} LP
        </SkeletonTypography>
        <SkeletonTypography alive={alive} variant='body2'>
          {league?.wins}W &#x2022; {league?.losses}L{' '}
          <Typography
            variant='caption'
            component='span'
            color={wins - losses >= 0 ? blue[300] : red[300]}
          >
            ({((wins / Math.max(wins + losses, 1)) * 100).toFixed(1)}
            %)
          </Typography>
        </SkeletonTypography>
        <Box>
          {league?.queueType !== undefined ? (
            <StreakChip queueType={league.queueType as RankedQueueType} />
          ) : (
            <></>
          )}
        </Box>
      </Stack>
    </ListItem>
  );
};

export const ProfileQueues: React.FC = () => {
  const { leagues } = useLeagues();
  return (
    <List disablePadding>
      {(leagues
        ? leagues
            .filter(({ queueType }) => queueType !== 'CHERRY')
            .sort(
              ({ wins: aW, losses: aL }, { wins: bW, losses: bL }) =>
                bW + bL - (aW + aL),
            )
        : [undefined, undefined]
      ).map((league, i) => (
        <React.Fragment key={i}>
          <Queue league={league} />
          <Divider variant='inset' component='li' />
        </React.Fragment>
      ))}
    </List>
  );
};
