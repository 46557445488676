/**
 * StatBud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Challenges DTO
*/
export class MatchV5ChallengesDto {
    '_12assistStreakCount'?: number;
    'abilityUses'?: number;
    'acesBefore15Minutes'?: number;
    'alliedJungleMonsterKills'?: number;
    'baronTakedowns'?: number;
    'blastConeOppositeOpponentCount'?: number;
    'bountyGold'?: number;
    'buffsStolen'?: number;
    'completeSupportQuestInTime'?: number;
    'controlWardsPlaced'?: number;
    'damagePerMinute'?: number;
    'damageTakenOnTeamPercentage'?: number;
    'dancedWithRiftHerald'?: number;
    'deathsByEnemyChamps'?: number;
    'dodgeSkillShotsSmallWindow'?: number;
    'doubleAces'?: number;
    'dragonTakedowns'?: number;
    'legendaryItemUsed'?: Array<number>;
    'effectiveHealAndShielding'?: number;
    'elderDragonKillsWithOpposingSoul'?: number;
    'elderDragonMultikills'?: number;
    'enemyChampionImmobilizations'?: number;
    'enemyJungleMonsterKills'?: number;
    'epicMonsterKillsNearEnemyJungler'?: number;
    'epicMonsterKillsWithin30SecondsOfSpawn'?: number;
    'epicMonsterSteals'?: number;
    'epicMonsterStolenWithoutSmite'?: number;
    'firstTurretKilled'?: number;
    'firstTurretKilledTime'?: number;
    'flawlessAces'?: number;
    'fullTeamTakedown'?: number;
    'gameLength'?: number;
    'getTakedownsInAllLanesEarlyJungleAsLaner'?: number;
    'goldPerMinute'?: number;
    'hadOpenNexus'?: number;
    'immobilizeAndKillWithAlly'?: number;
    'initialBuffCount'?: number;
    'initialCrabCount'?: number;
    'jungleCsBefore10Minutes'?: number;
    'junglerTakedownsNearDamagedEpicMonster'?: number;
    'kda'?: number;
    'killAfterHiddenWithAlly'?: number;
    'killedChampTookFullTeamDamageSurvived'?: number;
    'killingSprees'?: number;
    'killParticipation'?: number;
    'killsNearEnemyTurret'?: number;
    'killsOnOtherLanesEarlyJungleAsLaner'?: number;
    'killsOnRecentlyHealedByAramPack'?: number;
    'killsUnderOwnTurret'?: number;
    'killsWithHelpFromEpicMonster'?: number;
    'knockEnemyIntoTeamAndKill'?: number;
    'kTurretsDestroyedBeforePlatesFall'?: number;
    'landSkillShotsEarlyGame'?: number;
    'laneMinionsFirst10Minutes'?: number;
    'lostAnInhibitor'?: number;
    'maxKillDeficit'?: number;
    'mejaisFullStackInTime'?: number;
    'moreEnemyJungleThanOpponent'?: number;
    'multiKillOneSpell'?: number;
    'multikills'?: number;
    'multikillsAfterAggressiveFlash'?: number;
    'multiTurretRiftHeraldCount'?: number;
    'outerTurretExecutesBefore10Minutes'?: number;
    'outnumberedKills'?: number;
    'outnumberedNexusKill'?: number;
    'perfectDragonSoulsTaken'?: number;
    'perfectGame'?: number;
    'pickKillWithAlly'?: number;
    'poroExplosions'?: number;
    'quickCleanse'?: number;
    'quickFirstTurret'?: number;
    'quickSoloKills'?: number;
    'riftHeraldTakedowns'?: number;
    'saveAllyFromDeath'?: number;
    'scuttleCrabKills'?: number;
    'shortestTimeToAceFromFirstTakedown'?: number;
    'skillshotsDodged'?: number;
    'skillshotsHit'?: number;
    'snowballsHit'?: number;
    'soloBaronKills'?: number;
    'soloKills'?: number;
    'stealthWardsPlaced'?: number;
    'survivedSingleDigitHpCount'?: number;
    'survivedThreeImmobilizesInFight'?: number;
    'takedownOnFirstTurret'?: number;
    'takedowns'?: number;
    'takedownsAfterGainingLevelAdvantage'?: number;
    'takedownsBeforeJungleMinionSpawn'?: number;
    'takedownsFirstXMinutes'?: number;
    'takedownsInAlcove'?: number;
    'takedownsInEnemyFountain'?: number;
    'teamBaronKills'?: number;
    'teamDamagePercentage'?: number;
    'teamElderDragonKills'?: number;
    'teamRiftHeraldKills'?: number;
    'tookLargeDamageSurvived'?: number;
    'turretPlatesTaken'?: number;
    'turretsTakenWithRiftHerald'?: number;
    'turretTakedowns'?: number;
    'twentyMinionsIn3SecondsCount'?: number;
    'twoWardsOneSweeperCount'?: number;
    'unseenRecalls'?: number;
    'visionScorePerMinute'?: number;
    'wardsGuarded'?: number;
    'wardTakedowns'?: number;
    'wardTakedownsBefore20M'?: number;
    'baronBuffGoldAdvantageOverThreshold'?: number;
    'controlWardTimeCoverageInRiverOrEnemyHalf'?: number;
    'earliestBaron'?: number;
    'earliestDragonTakedown'?: number;
    'earliestElderDragon'?: number;
    'earlyLaningPhaseGoldExpAdvantage'?: number;
    'fasterSupportQuestCompletion'?: number;
    'fastestLegendary'?: number;
    'hadAfkTeammate'?: number;
    'highestChampionDamage'?: number;
    'highestCrowdControlScore'?: number;
    'highestWardKills'?: number;
    'junglerKillsEarlyJungle'?: number;
    'killsOnLanersEarlyJungleAsJungler'?: number;
    'laningPhaseGoldExpAdvantage'?: number;
    'legendaryCount'?: number;
    'maxCsAdvantageOnLaneOpponent'?: number;
    'maxLevelLeadLaneOpponent'?: number;
    'mostWardsDestroyedOneSweeper'?: number;
    'mythicItemUsed'?: number;
    'playedChampSelectPosition'?: number;
    'soloTurretsLategame'?: number;
    'takedownsFirst25Minutes'?: number;
    'teleportTakedowns'?: number;
    'thirdInhibitorDestroyedTime'?: number;
    'threeWardsOneSweeperCount'?: number;
    'visionScoreAdvantageLaneOpponent'?: number;
    'infernalScalePickup'?: number;
    'fistBumpParticipation'?: number;
    'voidMonsterKill'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "_12assistStreakCount",
            "baseName": "12AssistStreakCount",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "abilityUses",
            "baseName": "abilityUses",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "acesBefore15Minutes",
            "baseName": "acesBefore15Minutes",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "alliedJungleMonsterKills",
            "baseName": "alliedJungleMonsterKills",
            "type": "number",
            "format": "double"
        },
        {
            "name": "baronTakedowns",
            "baseName": "baronTakedowns",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "blastConeOppositeOpponentCount",
            "baseName": "blastConeOppositeOpponentCount",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "bountyGold",
            "baseName": "bountyGold",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "buffsStolen",
            "baseName": "buffsStolen",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "completeSupportQuestInTime",
            "baseName": "completeSupportQuestInTime",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "controlWardsPlaced",
            "baseName": "controlWardsPlaced",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "damagePerMinute",
            "baseName": "damagePerMinute",
            "type": "number",
            "format": "double"
        },
        {
            "name": "damageTakenOnTeamPercentage",
            "baseName": "damageTakenOnTeamPercentage",
            "type": "number",
            "format": "double"
        },
        {
            "name": "dancedWithRiftHerald",
            "baseName": "dancedWithRiftHerald",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "deathsByEnemyChamps",
            "baseName": "deathsByEnemyChamps",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "dodgeSkillShotsSmallWindow",
            "baseName": "dodgeSkillShotsSmallWindow",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "doubleAces",
            "baseName": "doubleAces",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "dragonTakedowns",
            "baseName": "dragonTakedowns",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "legendaryItemUsed",
            "baseName": "legendaryItemUsed",
            "type": "Array<number>",
            "format": "int32"
        },
        {
            "name": "effectiveHealAndShielding",
            "baseName": "effectiveHealAndShielding",
            "type": "number",
            "format": "float"
        },
        {
            "name": "elderDragonKillsWithOpposingSoul",
            "baseName": "elderDragonKillsWithOpposingSoul",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "elderDragonMultikills",
            "baseName": "elderDragonMultikills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "enemyChampionImmobilizations",
            "baseName": "enemyChampionImmobilizations",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "enemyJungleMonsterKills",
            "baseName": "enemyJungleMonsterKills",
            "type": "number",
            "format": "double"
        },
        {
            "name": "epicMonsterKillsNearEnemyJungler",
            "baseName": "epicMonsterKillsNearEnemyJungler",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "epicMonsterKillsWithin30SecondsOfSpawn",
            "baseName": "epicMonsterKillsWithin30SecondsOfSpawn",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "epicMonsterSteals",
            "baseName": "epicMonsterSteals",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "epicMonsterStolenWithoutSmite",
            "baseName": "epicMonsterStolenWithoutSmite",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "firstTurretKilled",
            "baseName": "firstTurretKilled",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "firstTurretKilledTime",
            "baseName": "firstTurretKilledTime",
            "type": "number",
            "format": "float"
        },
        {
            "name": "flawlessAces",
            "baseName": "flawlessAces",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "fullTeamTakedown",
            "baseName": "fullTeamTakedown",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "gameLength",
            "baseName": "gameLength",
            "type": "number",
            "format": "double"
        },
        {
            "name": "getTakedownsInAllLanesEarlyJungleAsLaner",
            "baseName": "getTakedownsInAllLanesEarlyJungleAsLaner",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "goldPerMinute",
            "baseName": "goldPerMinute",
            "type": "number",
            "format": "double"
        },
        {
            "name": "hadOpenNexus",
            "baseName": "hadOpenNexus",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "immobilizeAndKillWithAlly",
            "baseName": "immobilizeAndKillWithAlly",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "initialBuffCount",
            "baseName": "initialBuffCount",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "initialCrabCount",
            "baseName": "initialCrabCount",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "jungleCsBefore10Minutes",
            "baseName": "jungleCsBefore10Minutes",
            "type": "number",
            "format": "double"
        },
        {
            "name": "junglerTakedownsNearDamagedEpicMonster",
            "baseName": "junglerTakedownsNearDamagedEpicMonster",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "kda",
            "baseName": "kda",
            "type": "number",
            "format": "double"
        },
        {
            "name": "killAfterHiddenWithAlly",
            "baseName": "killAfterHiddenWithAlly",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "killedChampTookFullTeamDamageSurvived",
            "baseName": "killedChampTookFullTeamDamageSurvived",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "killingSprees",
            "baseName": "killingSprees",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "killParticipation",
            "baseName": "killParticipation",
            "type": "number",
            "format": "double"
        },
        {
            "name": "killsNearEnemyTurret",
            "baseName": "killsNearEnemyTurret",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "killsOnOtherLanesEarlyJungleAsLaner",
            "baseName": "killsOnOtherLanesEarlyJungleAsLaner",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "killsOnRecentlyHealedByAramPack",
            "baseName": "killsOnRecentlyHealedByAramPack",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "killsUnderOwnTurret",
            "baseName": "killsUnderOwnTurret",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "killsWithHelpFromEpicMonster",
            "baseName": "killsWithHelpFromEpicMonster",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "knockEnemyIntoTeamAndKill",
            "baseName": "knockEnemyIntoTeamAndKill",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "kTurretsDestroyedBeforePlatesFall",
            "baseName": "kTurretsDestroyedBeforePlatesFall",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "landSkillShotsEarlyGame",
            "baseName": "landSkillShotsEarlyGame",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "laneMinionsFirst10Minutes",
            "baseName": "laneMinionsFirst10Minutes",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "lostAnInhibitor",
            "baseName": "lostAnInhibitor",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "maxKillDeficit",
            "baseName": "maxKillDeficit",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "mejaisFullStackInTime",
            "baseName": "mejaisFullStackInTime",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "moreEnemyJungleThanOpponent",
            "baseName": "moreEnemyJungleThanOpponent",
            "type": "number",
            "format": "double"
        },
        {
            "name": "multiKillOneSpell",
            "baseName": "multiKillOneSpell",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "multikills",
            "baseName": "multikills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "multikillsAfterAggressiveFlash",
            "baseName": "multikillsAfterAggressiveFlash",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "multiTurretRiftHeraldCount",
            "baseName": "multiTurretRiftHeraldCount",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "outerTurretExecutesBefore10Minutes",
            "baseName": "outerTurretExecutesBefore10Minutes",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "outnumberedKills",
            "baseName": "outnumberedKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "outnumberedNexusKill",
            "baseName": "outnumberedNexusKill",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "perfectDragonSoulsTaken",
            "baseName": "perfectDragonSoulsTaken",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "perfectGame",
            "baseName": "perfectGame",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "pickKillWithAlly",
            "baseName": "pickKillWithAlly",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "poroExplosions",
            "baseName": "poroExplosions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "quickCleanse",
            "baseName": "quickCleanse",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "quickFirstTurret",
            "baseName": "quickFirstTurret",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "quickSoloKills",
            "baseName": "quickSoloKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "riftHeraldTakedowns",
            "baseName": "riftHeraldTakedowns",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "saveAllyFromDeath",
            "baseName": "saveAllyFromDeath",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "scuttleCrabKills",
            "baseName": "scuttleCrabKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "shortestTimeToAceFromFirstTakedown",
            "baseName": "shortestTimeToAceFromFirstTakedown",
            "type": "number",
            "format": "float"
        },
        {
            "name": "skillshotsDodged",
            "baseName": "skillshotsDodged",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "skillshotsHit",
            "baseName": "skillshotsHit",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "snowballsHit",
            "baseName": "snowballsHit",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "soloBaronKills",
            "baseName": "soloBaronKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "soloKills",
            "baseName": "soloKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "stealthWardsPlaced",
            "baseName": "stealthWardsPlaced",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "survivedSingleDigitHpCount",
            "baseName": "survivedSingleDigitHpCount",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "survivedThreeImmobilizesInFight",
            "baseName": "survivedThreeImmobilizesInFight",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "takedownOnFirstTurret",
            "baseName": "takedownOnFirstTurret",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "takedowns",
            "baseName": "takedowns",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "takedownsAfterGainingLevelAdvantage",
            "baseName": "takedownsAfterGainingLevelAdvantage",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "takedownsBeforeJungleMinionSpawn",
            "baseName": "takedownsBeforeJungleMinionSpawn",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "takedownsFirstXMinutes",
            "baseName": "takedownsFirstXMinutes",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "takedownsInAlcove",
            "baseName": "takedownsInAlcove",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "takedownsInEnemyFountain",
            "baseName": "takedownsInEnemyFountain",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "teamBaronKills",
            "baseName": "teamBaronKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "teamDamagePercentage",
            "baseName": "teamDamagePercentage",
            "type": "number",
            "format": "double"
        },
        {
            "name": "teamElderDragonKills",
            "baseName": "teamElderDragonKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "teamRiftHeraldKills",
            "baseName": "teamRiftHeraldKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "tookLargeDamageSurvived",
            "baseName": "tookLargeDamageSurvived",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "turretPlatesTaken",
            "baseName": "turretPlatesTaken",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "turretsTakenWithRiftHerald",
            "baseName": "turretsTakenWithRiftHerald",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "turretTakedowns",
            "baseName": "turretTakedowns",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "twentyMinionsIn3SecondsCount",
            "baseName": "twentyMinionsIn3SecondsCount",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "twoWardsOneSweeperCount",
            "baseName": "twoWardsOneSweeperCount",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "unseenRecalls",
            "baseName": "unseenRecalls",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "visionScorePerMinute",
            "baseName": "visionScorePerMinute",
            "type": "number",
            "format": "double"
        },
        {
            "name": "wardsGuarded",
            "baseName": "wardsGuarded",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "wardTakedowns",
            "baseName": "wardTakedowns",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "wardTakedownsBefore20M",
            "baseName": "wardTakedownsBefore20M",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "baronBuffGoldAdvantageOverThreshold",
            "baseName": "baronBuffGoldAdvantageOverThreshold",
            "type": "number",
            "format": "double"
        },
        {
            "name": "controlWardTimeCoverageInRiverOrEnemyHalf",
            "baseName": "controlWardTimeCoverageInRiverOrEnemyHalf",
            "type": "number",
            "format": "double"
        },
        {
            "name": "earliestBaron",
            "baseName": "earliestBaron",
            "type": "number",
            "format": "double"
        },
        {
            "name": "earliestDragonTakedown",
            "baseName": "earliestDragonTakedown",
            "type": "number",
            "format": "double"
        },
        {
            "name": "earliestElderDragon",
            "baseName": "earliestElderDragon",
            "type": "number",
            "format": "double"
        },
        {
            "name": "earlyLaningPhaseGoldExpAdvantage",
            "baseName": "earlyLaningPhaseGoldExpAdvantage",
            "type": "number",
            "format": "double"
        },
        {
            "name": "fasterSupportQuestCompletion",
            "baseName": "fasterSupportQuestCompletion",
            "type": "number",
            "format": "double"
        },
        {
            "name": "fastestLegendary",
            "baseName": "fastestLegendary",
            "type": "number",
            "format": "double"
        },
        {
            "name": "hadAfkTeammate",
            "baseName": "hadAfkTeammate",
            "type": "number",
            "format": "double"
        },
        {
            "name": "highestChampionDamage",
            "baseName": "highestChampionDamage",
            "type": "number",
            "format": "double"
        },
        {
            "name": "highestCrowdControlScore",
            "baseName": "highestCrowdControlScore",
            "type": "number",
            "format": "double"
        },
        {
            "name": "highestWardKills",
            "baseName": "highestWardKills",
            "type": "number",
            "format": "double"
        },
        {
            "name": "junglerKillsEarlyJungle",
            "baseName": "junglerKillsEarlyJungle",
            "type": "number",
            "format": "double"
        },
        {
            "name": "killsOnLanersEarlyJungleAsJungler",
            "baseName": "killsOnLanersEarlyJungleAsJungler",
            "type": "number",
            "format": "double"
        },
        {
            "name": "laningPhaseGoldExpAdvantage",
            "baseName": "laningPhaseGoldExpAdvantage",
            "type": "number",
            "format": "double"
        },
        {
            "name": "legendaryCount",
            "baseName": "legendaryCount",
            "type": "number",
            "format": "double"
        },
        {
            "name": "maxCsAdvantageOnLaneOpponent",
            "baseName": "maxCsAdvantageOnLaneOpponent",
            "type": "number",
            "format": "double"
        },
        {
            "name": "maxLevelLeadLaneOpponent",
            "baseName": "maxLevelLeadLaneOpponent",
            "type": "number",
            "format": "double"
        },
        {
            "name": "mostWardsDestroyedOneSweeper",
            "baseName": "mostWardsDestroyedOneSweeper",
            "type": "number",
            "format": "double"
        },
        {
            "name": "mythicItemUsed",
            "baseName": "mythicItemUsed",
            "type": "number",
            "format": "double"
        },
        {
            "name": "playedChampSelectPosition",
            "baseName": "playedChampSelectPosition",
            "type": "number",
            "format": "double"
        },
        {
            "name": "soloTurretsLategame",
            "baseName": "soloTurretsLategame",
            "type": "number",
            "format": "double"
        },
        {
            "name": "takedownsFirst25Minutes",
            "baseName": "takedownsFirst25Minutes",
            "type": "number",
            "format": "double"
        },
        {
            "name": "teleportTakedowns",
            "baseName": "teleportTakedowns",
            "type": "number",
            "format": "double"
        },
        {
            "name": "thirdInhibitorDestroyedTime",
            "baseName": "thirdInhibitorDestroyedTime",
            "type": "number",
            "format": "double"
        },
        {
            "name": "threeWardsOneSweeperCount",
            "baseName": "threeWardsOneSweeperCount",
            "type": "number",
            "format": "double"
        },
        {
            "name": "visionScoreAdvantageLaneOpponent",
            "baseName": "visionScoreAdvantageLaneOpponent",
            "type": "number",
            "format": "double"
        },
        {
            "name": "infernalScalePickup",
            "baseName": "InfernalScalePickup",
            "type": "number",
            "format": "double"
        },
        {
            "name": "fistBumpParticipation",
            "baseName": "fistBumpParticipation",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "voidMonsterKill",
            "baseName": "voidMonsterKill",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return MatchV5ChallengesDto.attributeTypeMap;
    }

    public constructor() {
    }
}

