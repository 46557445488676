/**
 * StatBud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Missions DTO
*/
export class MatchV5MissionsDto {
    'playerScore0'?: number;
    'playerScore1'?: number;
    'playerScore2'?: number;
    'playerScore3'?: number;
    'playerScore4'?: number;
    'playerScore5'?: number;
    'playerScore6'?: number;
    'playerScore7'?: number;
    'playerScore8'?: number;
    'playerScore9'?: number;
    'playerScore10'?: number;
    'playerScore11'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "playerScore0",
            "baseName": "playerScore0",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore1",
            "baseName": "playerScore1",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore2",
            "baseName": "playerScore2",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore3",
            "baseName": "playerScore3",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore4",
            "baseName": "playerScore4",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore5",
            "baseName": "playerScore5",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore6",
            "baseName": "playerScore6",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore7",
            "baseName": "playerScore7",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore8",
            "baseName": "playerScore8",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore9",
            "baseName": "playerScore9",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore10",
            "baseName": "playerScore10",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore11",
            "baseName": "playerScore11",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return MatchV5MissionsDto.attributeTypeMap;
    }

    public constructor() {
    }
}

