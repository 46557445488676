import { MatchV5ParticipantDto } from '@blakearoberts/riot-api-ts';
import {
  Avatar,
  AvatarProps,
  Badge,
  Box,
  BoxProps,
  Skeleton,
  useTheme,
} from '@mui/material';

import { useRuneStyleIcon, useRuneStylePerkIcon } from 'hooks';
import React from 'react';

interface Props extends AvatarProps {
  runeId?: number;
  perkId?: number;
  version?: string;
}

export const RuneIcon: React.FC<Props> = ({
  runeId,
  perkId,
  version,
  variant,
  sx,
  ...rest
}) => {
  const theme = useTheme(),
    runeSrc = useRuneStyleIcon(runeId, version),
    perkSrc = useRuneStylePerkIcon(runeId, perkId, version);
  return runeId !== 0 && perkSrc === undefined && runeSrc === undefined ? (
    <Skeleton variant='circular' sx={sx} {...rest} />
  ) : (
    <Avatar
      alt='Primary Rune'
      src={perkSrc ?? runeSrc}
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        ...sx,
      }}
      {...rest}
    >
      R
    </Avatar>
  );
};

interface RuneIconGroupProps extends BoxProps {
  participant?: MatchV5ParticipantDto;
  version?: string;
  PrimaryAvatarProps?: AvatarProps;
  SecondaryAvatarProps?: AvatarProps;
}

export const RuneIconGroup: React.FC<RuneIconGroupProps> = ({
  participant,
  version,
  PrimaryAvatarProps,
  SecondaryAvatarProps,
  ...rest
}) => {
  const runeId1 = participant?.perks.styles[0].style,
    runeId2 = participant?.perks.styles[1].style,
    selections = participant?.perks.styles[0].selections ?? [];
  return (
    <Box {...rest}>
      <Badge
        overlap='circular'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ zIndex: 0 }}
        badgeContent={
          <RuneIcon
            runeId={runeId2}
            version={version}
            {...SecondaryAvatarProps}
            sx={{
              width: 22,
              height: 22,
              padding: '2px',
              ...SecondaryAvatarProps?.sx,
            }}
          />
        }
      >
        <RuneIcon
          runeId={runeId1}
          perkId={selections.at(0)?.perk}
          version={version}
          {...PrimaryAvatarProps}
        />
      </Badge>
    </Box>
  );
};
