import React, { useEffect, useRef, useState } from 'react';

import { blue, red } from '@mui/material/colors';
import {
  Avatar,
  Box,
  Popper as MuiPopper,
  Skeleton,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';

import { useDDragonData } from 'hooks';
import { formatPercentage, formatRatio } from 'helpers';

const Popper = styled(MuiPopper)(({ theme }) => ({
  ...theme.typography.caption,
  backgroundColor: 'black',
  color: 'white',
  fontWeight: theme.typography.fontWeightMedium,
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5, 1),
}));

interface OverflowTipProps {
  children: React.ReactNode;
}

const OverflowTip: React.FC<OverflowTipProps> = ({ children }) => {
  const [open, setOpen] = useState(false),
    [isOverflowed, setIsOverflow] = useState(false),
    ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current)
      setIsOverflow(ref.current.scrollWidth > ref.current.clientWidth);
  }, []);

  return (
    <>
      <Typography
        ref={ref}
        onMouseOver={() => setOpen(true)}
        onMouseOut={() => setOpen(false)}
        component='span'
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </Typography>
      <Popper
        open={isOverflowed && open}
        anchorEl={ref.current}
        placement='top'
        disablePortal
      >
        {children}
      </Popper>
    </>
  );
};

interface Props {
  championId?: string;
  wins: number;
  losses: number;
  kills: number;
  assists: number;
  deaths: number;
}

export const SummaryItem: React.FC<Props> = ({
  championId,
  wins,
  losses,
  kills,
  assists,
  deaths,
}) => {
  const theme = useTheme(),
    { version, champions } = useDDragonData(),
    winP = formatPercentage(wins, wins + losses, { minimumFractionDigits: 0 }),
    championName =
      champions?.find(({ id }: { id: string }) => id === championId)?.name ||
      '';

  return (
    <Box display='flex' flex={1} minWidth={0} pb={{ xs: 0.5, sm: 0 }} pl={1}>
      <Box sx={{ mr: 0.5 }}>
        {championId ? (
          <Avatar
            alt={championName}
            src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${championId}.png`}
            sx={{ width: 32, height: 32 }}
          />
        ) : (
          <Skeleton variant='circular' width={32} height={32} />
        )}
      </Box>

      <Box sx={{ flex: 1, minWidth: 0 }}>
        {championId ? (
          <Box display='flex'>
            <OverflowTip>{championName}</OverflowTip>
            <Box display='flex' alignItems='center' sx={{ ml: 0.5 }}>
              <Typography
                color={wins - losses >= 0 ? blue[300] : red[300]}
                fontSize={theme.typography.caption.fontSize}
              >
                ({winP})
              </Typography>
            </Box>
          </Box>
        ) : (
          <Skeleton width='60%' />
        )}

        <Stack
          spacing={{ xs: 1, sm: 0 }}
          direction={{ xs: 'row', sm: 'column' }}
        >
          <Typography variant='body2' component='div' flexShrink={1}>
            {championId ? (
              <>
                {wins}W &#x2022; {losses}L{' '}
              </>
            ) : (
              <Skeleton width={50} />
            )}
          </Typography>

          <Typography variant='body2' component='div' flexShrink={1}>
            {championId ? (
              <>
                {formatRatio(kills + assists, deaths, {
                  maximumFractionDigits: 2,
                })}{' '}
                KDA
              </>
            ) : (
              <Skeleton width={60} />
            )}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};
