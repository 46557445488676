/**
 * StatBud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class MatchV5PerkStatsDto {
    'defense': number;
    'flex': number;
    'offense': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "defense",
            "baseName": "defense",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "flex",
            "baseName": "flex",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "offense",
            "baseName": "offense",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return MatchV5PerkStatsDto.attributeTypeMap;
    }

    public constructor() {
    }
}

