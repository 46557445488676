import { Link, LinkProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Props extends LinkProps {
  championKey: string;
}

export const ChampionLink: React.FC<Props> = ({
  championKey,
  children,
  ...rest
}) => {
  const navigate = useNavigate(),
    href = `/data/champions/${championKey}`;
  return (
    <Link
      color='text.primary'
      underline='hover'
      href={href}
      onClick={(e) => {
        e.preventDefault();
        navigate(href);
      }}
      {...rest}
    >
      {children}
    </Link>
  );
};
