import { ChampionBin, FontConfig } from '@blakearoberts/ldragon';
import useSWRImmutable from 'swr/immutable';

const cDragonEndpoint = 'https://raw.communitydragon.org/latest';

const useCDragonSWR = <T>(path?: string) => {
  return useSWRImmutable(path ? `${cDragonEndpoint}${path}` : undefined, () =>
    fetch(`${cDragonEndpoint}${path}`).then((r) => r.json() as T),
  );
};

const useCDragonFontConfig = () => {
  const { data, isLoading } = useCDragonSWR<FontConfig>(
    `/game/en_us/data/menu/en_us/main.stringtable.json`,
  );
  return {
    fontConfig: data,
    loading: isLoading,
  };
};

const useCDragonChampionBin = (championId?: string) => {
  const id = championId ? championId.toLocaleLowerCase() : undefined,
    { data, isLoading } = useCDragonSWR<ChampionBin>(
      id ? `/game/data/characters/${id}/${id}.bin.json` : undefined,
    );
  return {
    bin: data,
    loading: isLoading,
  };
};

export const useCDragonData = (championId?: string) => {
  const { fontConfig, loading: fcLoading } = useCDragonFontConfig(),
    { bin, loading: binLoading } = useCDragonChampionBin(championId);
  return {
    fontConfig,
    bin,
    loading: fcLoading || binLoading,
  };
};
