import {
  Box,
  LinearProgress,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { formatPercentage, formatRate } from 'helpers';

interface Props {
  value: number;
  duration: number;
  total: number;
  max: number;
}

export const DamageCell: React.FC<Props> = ({
  value,
  duration,
  total,
  max,
}) => {
  const hideRate = useMediaQuery(
      '(max-width: 350px) or ((min-width: 600px) and (max-width: 690px)) or ((min-width: 900px) and (max-width: 1000px)) or ((min-width: 1200px) and (max-width: 1220px))',
    ),
    valueR = formatRate(value, duration),
    progress = (value / max) * 100,
    percentage = formatPercentage(value, total, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  return (
    <Stack flexWrap='nowrap' height={43} justifyContent='center'>
      <Stack
        direction='row'
        justifyContent='space-between'
        sx={{ mr: { xs: 0.5, sm: 1 } }}
      >
        <Box display='flex' alignItems='center'>
          <Typography
            variant='body2'
            noWrap
            textOverflow='clip'
            fontSize={{ xs: '0.8rem', sm: '0.9rem' }}
          >
            {value.toLocaleString()}
            <Typography
              variant='caption'
              fontSize='.75em'
              fontWeight={500}
              color='text.secondary'
              display={{ xs: 'none', sm: 'unset' }}
              noWrap
              textOverflow='clip'
            >
              {' '}
              {percentage}
            </Typography>
          </Typography>
        </Box>

        <Box display={hideRate ? 'none' : 'flex'} alignItems='center'>
          <Typography
            variant='body2'
            noWrap
            textOverflow='clip'
            fontSize={{ xs: '0.8rem', sm: '0.9rem' }}
          >
            {valueR}
            <Typography
              variant='caption'
              fontSize='.75em'
              fontWeight={500}
              color='text.secondary'
            >
              {' '}
              / min
            </Typography>
          </Typography>
        </Box>
      </Stack>

      <LinearProgress
        variant='determinate'
        value={progress}
        sx={{ height: 8, mr: { xs: 0.5, sm: 1 } }}
      />
    </Stack>
  );
};
