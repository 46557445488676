/**
 * StatBud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MatchV5InfoDto } from '../models/MatchV5InfoDto';
import { MatchV5MetadataDto } from '../models/MatchV5MetadataDto';
import { HttpFile } from '../http/http';

export class MatchV5MatchDto {
    'metadata': MatchV5MetadataDto;
    'info': MatchV5InfoDto;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "metadata",
            "baseName": "metadata",
            "type": "MatchV5MetadataDto",
            "format": ""
        },
        {
            "name": "info",
            "baseName": "info",
            "type": "MatchV5InfoDto",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return MatchV5MatchDto.attributeTypeMap;
    }

    public constructor() {
    }
}

