import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Toolbar, useTheme } from '@mui/material';
import { Middleware, SWRConfig } from 'swr';

import { AppBar, ContextDrawer, NavDrawer, ThemeProvider } from 'components';
import { RiotIdProvider, useSummoner } from 'hooks';

const Content = () => {
  const theme = useTheme(),
    { summoner, loading } = useSummoner();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        height: '100%',
      }}
    >
      <Toolbar />
      <Box
        sx={{
          flexGrow: 0,
          flexShrink: 1,
          minHeight: 0,
          display: 'flex',
          justifyContent: 'center',
          ml: { lg: theme.mixins.drawer.nav.maxWidth },
          mr: {
            md:
              summoner !== undefined || loading
                ? theme.mixins.drawer.context.maxWidth
                : 0,
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            flex: 1,
            width: '100%',
            maxWidth: '900px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

// TODO: convert logs to spans and export
// TODO: consider using navigation.sendBeacon() to send span data
const middleware: Middleware = (useSWRNext) => (key, fetcher, config) => {
  const wrappedFetcher: typeof fetcher =
    fetcher !== null
      ? (...args) => {
          const t0 = performance.now(),
            resp = fetcher(...args);
          (resp as Promise<unknown>)
            .then((data) =>
              console.debug('fetch', {
                key,
                time: new Date().toISOString(),
                duration: Math.floor(performance.now() - t0),
                data,
              }),
            )
            .catch((reason) =>
              console.warn('fetch', {
                key,
                time: new Date().toISOString(),
                duration: Math.floor(performance.now() - t0),
                reason,
              }),
            );
          return resp;
        }
      : null;
  return useSWRNext(key, wrappedFetcher, config);
};

export const App = () => {
  const [navDrawerOpen, setNavDrawerOpen] = useState(false);
  return (
    <ThemeProvider>
      <SWRConfig
        value={{
          keepPreviousData: true,
          dedupingInterval: 10000,
          use: [middleware],
        }}
      >
        <RiotIdProvider>
          <AppBar setOpen={setNavDrawerOpen} />
          <NavDrawer open={navDrawerOpen} setOpen={setNavDrawerOpen} />
          <ContextDrawer />
          {/* <Toolbar /> */}
          <Content />
        </RiotIdProvider>
      </SWRConfig>
    </ThemeProvider>
  );
};

export default App;
