import { Box, Stack, Typography } from '@mui/material';

import { GoldAvatar, ItemAvatar } from 'components';
import { useItem } from 'hooks';

import { ItemTagChip } from './ItemTagChip';

interface Props {
  itemId?: string;
  version?: string;
  size?: number;
}

export const Header: React.FC<Props> = ({ itemId, version, size }) => {
  const item = useItem(itemId, version),
    gold = item?.gold;
  size ??= 64;
  return (
    <Box
      sx={{
        flex: 1,
        pb: 1,
        display: 'grid',
        gridTemplateColumns:
          '64px minmax(auto, max-content) minmax(auto, max-content)',
        gridTemplateRows: 'auto',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <ItemAvatar itemId={itemId} version={version} size={size} />
      <Box sx={{ overflow: 'hidden' }}>
        <Typography
          variant='h5'
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {item?.name}
        </Typography>
        <Stack direction='row' spacing={0.5} alignItems='center'>
          <Typography variant='subtitle1' color='gold.main'>
            {gold?.total.toLocaleString()}
          </Typography>
          <GoldAvatar size={16} />
        </Stack>
      </Box>
      <Stack
        sx={{
          display: { xs: 'none', sm: 'flex' },
          flexFlow: 'row wrap',
          columnGap: 1,
          rowGap: 0.5,
          height: size,
          minWidth: 'fit-content',
          alignSelf: 'flex-start',
          alignContent: 'space-evenly',
        }}
      >
        {item &&
          item.tags
            .filter(
              (tag) => !['CooldownReduction', 'MagicResist'].includes(tag),
            )
            .map((tag) => <ItemTagChip key={tag} tag={tag} />)}
      </Stack>
    </Box>
  );
};
