import React, { useMemo, useRef, useState } from 'react';

import { LolChallengesV1ChallengeInfo } from '@blakearoberts/riot-api-ts';
import { matchSorter } from 'match-sorter';
import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  InputAdornment,
  List,
  OutlinedInput,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { LazyAvatar, ScrollTopFab } from 'components';
import { useChallenges, useDDragonChallenges } from 'hooks';
import { Challenge as DDragonChallenge, ChallengeLevel } from 'types';
import { Clear, Search } from '@mui/icons-material';

interface Datum extends DDragonChallenge {
  info?: LolChallengesV1ChallengeInfo;
}

interface Props {
  challenge?: Datum;
}

const Challenge: React.FC<Props> = ({ challenge }) => {
  const theme = useTheme();
  let iconPath =
    challenge?.levelToIconPath[challenge.info?.level as ChallengeLevel];
  if (iconPath === undefined) iconPath = challenge?.levelToIconPath.IRON;
  return (
    <Card
      elevation={0}
      sx={{
        width: '100%',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: `${theme.shape.borderRadius}px`,
        mb: 2,
      }}
    >
      <CardHeader
        avatar={
          challenge && iconPath ? (
            <LazyAvatar
              alt={challenge.name}
              src={`https://ddragon.leagueoflegends.com/cdn/img${iconPath}`}
            />
          ) : (
            <Skeleton variant='circular' width={40} height={40} />
          )
        }
        title={
          challenge ? (
            <Typography variant='body1'>
              {challenge.name}
              <Typography
                color='text.secondary'
                component='span'
                sx={{ pl: 1 }}
              >
                {challenge.info?.percentile.toLocaleString(undefined, {
                  style: 'percent',
                  maximumSignificantDigits: 3,
                })}
              </Typography>
            </Typography>
          ) : (
            <Skeleton width='50%' sx={{ ...theme.typography.body1 }} />
          )
        }
        subheader={
          challenge ? (
            challenge.shortDescription.replace(/<[^>]*>/g, '')
          ) : (
            <Skeleton width='75%' />
          )
        }
      />
    </Card>
  );
};

export const Challenges: React.FC = () => {
  const ref = useRef<HTMLUListElement>(null),
    infos = useChallenges()?.challenges?.challenges,
    challenges = useDDragonChallenges()
      .challenges?.filter(({ id }) => id > 5)
      .map((c) => {
        const info = infos?.find(({ challengeId }) => challengeId === c.id);
        return { info, ...c } as Datum;
      }),
    levels = Object.keys(ChallengeLevel),
    [value, setValue] = useState<string>(''),
    filtered = useMemo(
      () =>
        value.length > 0 && challenges
          ? matchSorter(challenges, value, {
              keys: ['description', 'name', 'shortDescription'],
            })
          : challenges,
      [challenges, value],
    );

  return (
    <>
      <Stack spacing={1} p={2}>
        <Typography variant='h5'>Challenges</Typography>
        <OutlinedInput
          size='small'
          placeholder='Search by name or description...'
          spellCheck={false}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          startAdornment={<Search sx={{ pr: 1 }} />}
          endAdornment={
            value.length > 0 && (
              <InputAdornment position='end'>
                <IconButton onClick={() => setValue('')} edge='end'>
                  <Clear sx={{ width: 15, height: 15 }} />
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </Stack>
      <Divider />
      <List ref={ref} sx={{ p: 2, overflowY: 'auto' }}>
        {(filtered === undefined
          ? (Array.from(new Array(20)) as Datum[])
          : filtered.sort(
              ({ info: a }, { info: b }) =>
                levels.indexOf(b?.level ?? 'IRON') -
                levels.indexOf(a?.level ?? 'IRON'),
            )
        ).map((c, i) => {
          return <Challenge key={i} challenge={c} />;
        })}
      </List>
      <ScrollTopFab target={ref.current} />
    </>
  );
};
