import { Avatar, AvatarProps } from '@mui/material';

interface Props extends AvatarProps {
  level: number;
  size?: number;
}

export const MasteryAvatar: React.FC<Props> = ({
  level,
  size,
  sx,
  ...rest
}) => {
  level = Math.min(10, level);
  // size ??= 32;
  return (
    <Avatar
      alt={`Mastery level ${level}`}
      title={`Mastery Level ${level}`}
      src={require(`assets/mastery/${level}.webp`)}
      sx={{
        width: size,
        height: size,
        ...sx,
      }}
      {...rest}
    />
  );
};
