import { MatchV5ParticipantDto } from '@blakearoberts/riot-api-ts';
import { Stack } from '@mui/material';

import { KdaTypography, MatchHistoryLink } from 'components';

interface Props {
  participant: MatchV5ParticipantDto;
}

export const SummonerCell: React.FC<Props> = ({
  participant: {
    riotIdGameName,
    summonerName,
    riotIdTagline,
    kills,
    deaths,
    assists,
  },
}) => {
  return (
    <Stack textAlign='center'>
      <MatchHistoryLink
        riotIdGameName={riotIdGameName ?? summonerName}
        riotIdTagLine={riotIdTagline}
        fontSize={{ xs: '0.875rem', sm: 'inherit' }}
        noWrap
      />
      <KdaTypography
        kills={kills}
        deaths={deaths}
        assists={assists}
        alignSelf='center'
        TypographyProps={{
          fontSize: { xs: '.8rem', sm: 'inherit' },
          color: 'text.secondary',
        }}
      />
    </Stack>
  );
};
