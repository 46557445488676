import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import {
  ChampionNameFilter,
  ChampionTagFilter,
  LazyCardMedia,
  ScrollTopFab,
} from 'components';
import { useDDragonData } from 'hooks';

export const Champions: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null),
    navigate = useNavigate(),
    { champions } = useDDragonData(),
    [championNameFilter, setChampionNameFilter] = useState<string[]>([]),
    [tagsFilter, setTagsFilter] = useState<string[]>(() => []),
    filterByChampionName = (name?: string) =>
      name === undefined ||
      championNameFilter.length === 0 ||
      championNameFilter.reduce(
        (filter, value) =>
          filter ||
          name.trim().toLowerCase().includes(value.trim().toLowerCase()),
        false,
      ),
    filterByTags = (tags: string[]) =>
      tagsFilter.length === 0
        ? true
        : tags.reduce(
            (include, tag) => include || tagsFilter.includes(tag),
            false,
          );
  return (
    <>
      <Box p={(theme) => theme.spacing(2, 2, 0)}>
        <Stack spacing={1}>
          <Typography variant='h5'>Champions</Typography>
          <ChampionNameFilter
            onChange={(_, value) => setChampionNameFilter(value)}
          />
          <ChampionTagFilter onChange={(_, tags) => setTagsFilter(tags)} />
        </Stack>
      </Box>
      <Divider sx={{ mt: 1 }} />
      <Grid
        ref={ref}
        container
        justifyContent='center'
        alignItems='center'
        spacing={2}
        sx={{ m: 0, p: 0.5, overflowY: 'auto' }}
      >
        {champions === undefined ? (
          <CircularProgress />
        ) : (
          champions.map(({ id, name, title, tags }) => (
            <Grid
              key={id}
              xs={12}
              sm={6}
              md={4}
              display={
                filterByChampionName(name) && filterByTags(tags)
                  ? 'block'
                  : 'none'
              }
            >
              <Card elevation={1}>
                <CardActionArea onClick={() => navigate(`${id}`)}>
                  <LazyCardMedia
                    sx={{
                      height: 400,
                      backgroundPosition: { xs: '50% 15%', md: '50% 35%' },
                      backgroundSize: '110%',
                    }}
                    image={`https://ddragon.leagueoflegends.com/cdn/img/champion/loading/${id}_0.jpg`}
                    title={name}
                    children={<></>}
                  />
                  <CardContent>
                    <Stack direction='row' spacing={1}>
                      <Typography variant='h5' component='div' flexGrow={1}>
                        {name}
                      </Typography>
                      {tags.map((tag, index) => (
                        <Avatar
                          key={index}
                          variant='square'
                          alt={tag}
                          title={tag}
                          src={`https://raw.communitydragon.org/13.4/plugins/rcp-fe-lol-champion-details/global/default/role-icon-${tag.toLowerCase()}.png`}
                          sx={{ width: 24, height: 24 }}
                        />
                      ))}
                    </Stack>
                    <Typography variant='body2' color='text.secondary'>
                      {title.charAt(0).toUpperCase() + title.slice(1)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
      <ScrollTopFab target={ref.current} />
    </>
  );
};
