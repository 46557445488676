import React from 'react';

import { Markdown } from 'components';
import { useFetchText } from 'hooks';
import { Container, Paper, useTheme } from '@mui/material';

export const TermsOfService: React.FC = () => {
  const theme = useTheme(),
    { data } = useFetchText('/TOS.md');
  return (
    <Container maxWidth='md' sx={{ mt: { md: 2 }, p: { xs: 0, sm: 2 } }}>
      <Paper sx={{ p: theme.spacing(0, 2, 2) }}>
        <Markdown content={data} />
      </Paper>
    </Container>
  );
};
