import * as d3 from 'd3';

import { Datum, StringValue, useChart } from './context';
import { isScaleBand, isScaleContinuousNumeric } from './types';

interface XAxisProps<Domain> {
  format?: (v: Domain) => string;
  tick?: (v: Domain) => React.ReactElement<React.SVGProps<SVGElement>>;
  ticks?: number[];
  stroke?: string;
}

export const XAxis = <D extends Datum, Domain = number>({
  format,
  tick,
  ticks,
  stroke,
}: XAxisProps<Domain>) => {
  const { width, height, padding, data, x: fx } = useChart<D>(),
    offset =
      typeof window !== 'undefined' && window.devicePixelRatio > 1 ? 0 : 0.5;

  if (data.length === 0) return <></>;

  return (
    <g
      data-chart-component='x-axis'
      transform={`translate(${offset} ${height - padding[2] + offset})`}
      stroke={stroke ?? 'currentColor'}
    >
      <line x1={padding[3]} x2={width - padding[1]} />
      {(ticks ?? (isScaleContinuousNumeric(fx) ? fx.ticks() : fx.domain())).map(
        (v, i) => (
          // TODO: fix typing of x to avoid "v as any"
          <g key={i} transform={`translate(${(fx(v as any) ?? 0) + offset} 0)`}>
            {(tick && tick(v as Domain)) || (
              <>
                <line y2='4' />
                <text
                  fill={stroke ?? 'currentColor'}
                  fontSize='12px'
                  strokeWidth={0}
                  textAnchor='middle'
                  y='18px'
                  style={{ userSelect: 'none' }}
                >
                  {format
                    ? format(v as Domain)
                    : v.toLocaleString(undefined, { notation: 'compact' })}
                </text>
              </>
            )}
          </g>
        ),
      )}
    </g>
  );
};

interface XAxisBandProps<Domain> {
  tick?: (
    d: Domain,
    i: number,
  ) => React.ReactElement<React.SVGProps<SVGElement>>;
  stroke?: string;
}

export const XAxisBand = <Domain extends StringValue = StringValue>({
  tick,
  stroke,
}: XAxisBandProps<Domain>) => {
  const { width, height, padding, x } = useChart<any, Domain>(),
    offset =
      typeof window !== 'undefined' && window.devicePixelRatio > 1 ? 0 : 0.5;
  return (
    <g
      data-chart-component='x-axis-band'
      transform={`translate(0 ${height - padding[2] + offset})`}
      fill='none'
      fontSize={10}
      textAnchor='end'
      stroke={stroke ?? 'currentColor'}
    >
      <line x1={padding[3]} x2={width - padding[1]} />
      {(isScaleBand<Domain>(x) ? x.domain() : []).map((v, i) => (
        <g
          key={i}
          transform={`translate(${
            (x(v as any) ?? 0) + (isScaleBand(x) ? x.bandwidth() : 0) / 2
          } 0)`}
        >
          {tick === undefined ? (
            <>
              <line stroke='currentColor' y2={6} strokeWidth={0.5} />
              <text
                fill={stroke ?? 'currentColor'}
                strokeWidth={0}
                transform='translate(0 12) rotate(-30)'
              >
                {v.toString()}
              </text>
            </>
          ) : (
            tick(v, i)
          )}
        </g>
      ))}
    </g>
  );
};

interface XAxisTimeProps {
  interval?: d3.TimeInterval | number;
  tick?: (d: Date, i: number) => React.ReactElement<React.SVGProps<SVGElement>>;
  stroke?: string;
}

export const XAxisTime = <
  D extends Datum,
  Domain extends StringValue & d3.NumberValue = number,
>({
  interval,
  tick,
  stroke,
}: XAxisTimeProps) => {
  const { width, height, padding, x } = useChart<D, Domain>(),
    offset =
      typeof window !== 'undefined' && window.devicePixelRatio > 1 ? 0 : 0.5,
    fx = d3.scaleTime().domain(x.domain()).range(x.range());
  interval ??= d3.timeWeek.every(2) ?? undefined;
  return (
    <g
      data-chart-component='x-axis-time'
      transform={`translate(0 ${height - padding[2] + offset})`}
      fill='none'
      fontSize={10}
      textAnchor='end'
      stroke={stroke ?? 'currentColor'}
    >
      <line x1={padding[3]} x2={width - padding[1]} />
      {fx.ticks(interval as any).map((v, i) => (
        <g
          key={i}
          transform={`translate(${
            (x(v as any) ?? 0) + (isScaleBand(x) ? x.bandwidth() : 0) / 2
          } 0)`}
        >
          {tick === undefined ? (
            <>
              <line y2='4' />
              <text
                fill={stroke ?? 'currentColor'}
                fontSize='12px'
                strokeWidth={0}
                textAnchor='middle'
                y='18px'
                style={{ userSelect: 'none' }}
              >
                {fx.tickFormat(5)(v)}
              </text>
            </>
          ) : (
            tick(v, i)
          )}
        </g>
      ))}
    </g>
  );
};

interface YAxisProps {
  format?: (v: number) => string;
  tick?: (v: number) => React.ReactElement<React.SVGProps<SVGElement>>;
  tickCount?: number;
  stroke?: string;
}

export const YAxis = <D extends Datum>({
  format,
  tick,
  tickCount,
  stroke,
}: YAxisProps) => {
  const { height, padding, y } = useChart<D>(),
    ticks = y.ticks(tickCount),
    offset =
      typeof window !== 'undefined' && window.devicePixelRatio > 1 ? 0 : 0.5;
  return (
    <g
      data-chart-component='y-axis'
      transform={`translate(${padding[3] + offset} 0)`}
      stroke={stroke ?? 'currentColor'}
    >
      <line y1={padding[0] - offset} y2={height - padding[2]} />
      {ticks.map((v, i) => (
        <g key={i} transform={`translate(0 ${(y(v) ?? 0) + offset})`}>
          {(tick && tick(v)) || (
            <>
              <line x2='-6' />
              <text
                dy='4.5px'
                fill={stroke ?? 'currentColor'}
                fontSize='12px'
                strokeWidth={0}
                textAnchor='end'
                x='-8'
              >
                {format
                  ? format(v)
                  : v.toLocaleString(undefined, { notation: 'compact' })}
              </text>
            </>
          )}
        </g>
      ))}
    </g>
  );
};
