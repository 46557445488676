import { MatchV5InfoDto, MatchV5TeamDto } from '@blakearoberts/riot-api-ts';
import {
  Typography,
  Stack,
  Box,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import {
  GoldAvatar,
  KdaTypography,
  MatchResult,
  RuneIconGroup,
} from 'components';
import { gameVersionToDDragonVersion } from 'helpers';

import { ChampionCell, DamageCell, KdaCell, SummonerCell } from '../Cell';
import { Items } from './Items';

interface Props {
  team: MatchV5TeamDto;
  info: MatchV5InfoDto;
}

export const Overview: React.FC<Props> = ({ team, info }) => {
  const theme = useTheme(),
    xs = useMediaQuery(theme.breakpoints.down('sm')),
    { participants, gameDuration, gameVersion } = info,
    version = gameVersionToDDragonVersion(gameVersion),
    teammates = participants.filter(({ win }) => team.win === win),
    teamDamage = teammates.reduce(
      (damage, participant) => damage + participant.totalDamageDealtToChampions,
      0,
    ),
    teamGold = teammates.reduce(
      (total, { goldEarned }) => total + goldEarned,
      0,
    ),
    teamKills = teammates.reduce((total, { kills }) => total + kills, 0),
    teamDeaths = teammates.reduce((total, { deaths }) => total + deaths, 0),
    teamAssists = teammates.reduce((total, { assists }) => total + assists, 0),
    maxKp = teammates.reduce(
      (kp, { kills, assists }) => Math.max(kp, kills + assists),
      0,
    ),
    maxDamage = teammates.reduce(
      (damage, { totalDamageDealtToChampions }) =>
        Math.max(damage, totalDamageDealtToChampions),
      0,
    );
  return (
    <>
      <Stack
        direction='row'
        sx={{ flexWrap: 'wrap' }}
        spacing={{ xs: 0, sm: 1 }}
      >
        <MatchResult
          flex={0}
          variant={xs ? 'h6' : 'h4'}
          win={team.win}
          gameDuration={info.gameDuration}
          sx={{ minWidth: { xs: '60px', sm: '116px' } }}
        />

        <Stack width={110} alignItems='center' justifyContent='end'>
          <KdaTypography
            kills={teamKills}
            deaths={teamDeaths}
            assists={teamAssists}
          />
        </Stack>

        <Stack
          width='224px'
          direction='row'
          alignItems='end'
          justifyContent='center'
          spacing={0.5}
          display={{ xs: 'none', sm: 'flex' }}
        >
          <Typography>{teamGold.toLocaleString()}</Typography>
          <Box sx={{ pb: 0.25 }}>
            <GoldAvatar
              size={18}
              color={theme.palette.mode === 'dark' ? 'white' : undefined}
            />
          </Box>
        </Stack>

        <Stack flex={1} minWidth='60px' justifyContent='end'>
          <Typography variant='overline' lineHeight='2em'>
            Damage
          </Typography>
        </Stack>

        <Stack
          flex={1}
          minWidth='80px'
          justifyContent='end'
          display={{ xs: 'none', xl: 'flex' }}
        >
          <Typography variant='overline' lineHeight='2em'>
            KDA
          </Typography>
        </Stack>
      </Stack>
      <Divider sx={{ mb: 1 }} />

      {teammates.map((participant, i) => {
        return (
          <Stack
            key={i}
            direction='row'
            sx={{ flexWrap: 'wrap', pb: 0.25 }}
            spacing={{ xs: 0, sm: 1 }}
          >
            <Stack flex={0} direction='row' spacing={2}>
              <ChampionCell participant={participant} />
              <RuneIconGroup
                participant={participant}
                version={version}
                display={{ xs: 'none', sm: 'block' }}
              />
            </Stack>

            <Box sx={{ width: 110 }}>
              <SummonerCell participant={participant} />
            </Box>

            <Box flex={0} display={{ xs: 'none', sm: 'block' }}>
              <Items version={version} participant={participant} />
            </Box>

            <Box flex={1} minWidth='60px'>
              <DamageCell
                value={participant.totalDamageDealtToChampions}
                duration={gameDuration}
                total={teamDamage}
                max={maxDamage}
              />
            </Box>

            <Box flex={1} minWidth='80px' display={{ xs: 'none', xl: 'block' }}>
              <KdaCell
                participant={participant}
                teamKills={teamKills}
                maxKp={maxKp}
              />
            </Box>
          </Stack>
        );
      })}
    </>
  );
};
