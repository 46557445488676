import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Clear, Search } from '@mui/icons-material';
import {
  Box,
  CardActionArea,
  Divider,
  Typography,
  useTheme,
  Skeleton,
  Avatar,
  Stack,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { matchSorter } from 'match-sorter';
import { useIntersectionObserver } from 'usehooks-ts';

import { ScrollTopFab } from 'components';
import { useDDragonData } from 'hooks';
import { Item, ItemTagKey } from 'types';

import { ItemTagChip, ItemTagLabelsMap } from './Item/ItemTagChip';
import { ItemTagChips } from './Item/ItemTagChips';

interface ItemAvatarProps {
  itemId?: string;
  version?: string;
  size?: number;
}

const ItemAvatar: React.FC<ItemAvatarProps> = ({ itemId, version, size }) => {
  const { items } = useDDragonData(version),
    item = items?.data[itemId ?? ''];
  size ??= 64;
  return item && version ? (
    <Avatar
      variant='rounded'
      alt={item.name}
      src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/item/${itemId}.png`}
      sx={{ width: size, height: size }}
    />
  ) : (
    <Skeleton width={size} height={size} />
  );
};

const Lazy: React.FC<React.PropsWithChildren> = ({ children }) => {
  const ref = useRef(null),
    visible = !!useIntersectionObserver(ref, { freezeOnceVisible: true })
      ?.isIntersecting;
  return <Box ref={ref}>{visible ? children : undefined}</Box>;
};

export const Items: React.FC = () => {
  const theme = useTheme(),
    ref = useRef<HTMLDivElement>(null),
    navigate = useNavigate(),
    { version, items: ddItems } = useDDragonData(),
    [selected, setSelected] = useState<ItemTagKey[]>([]),
    items = Object.entries(ddItems?.data ?? {}).filter(
      ([
        ,
        {
          requiredAlly,
          requiredChampion,
          hideFromAll,
          gold: { purchasable },
          maps,
        },
      ]) =>
        requiredAlly !== 'Ornn' &&
        requiredChampion === undefined &&
        !hideFromAll &&
        purchasable &&
        (maps['11'] || maps['12']),
    ),
    [value, setValue] = useState<string>(''),
    tags = Object.keys(
      items.reduce<Partial<{ [k in ItemTagKey]: boolean }>>(
        (tags, [, item]) => ({
          ...tags,
          ...Object.fromEntries(
            item.tags
              .filter(
                (tag) => !['CooldownReduction', 'MagicResist'].includes(tag),
              )
              .map((tag) => [tag]),
          ),
        }),
        {},
      ),
    ).sort() as ItemTagKey[],
    filtered = useMemo(
      () =>
        (value.length > 0 && items
          ? matchSorter(items, value, {
              keys: [
                ([, { name }]) => name,
                ([, { plaintext }]) => plaintext,
                ([, { tags }]) => tags.map((tag) => ItemTagLabelsMap[tag]),
              ],
            })
          : items
        ).filter(([, { tags }]) =>
          tags.reduce(
            (s, tag) => s || selected.length === 0 || selected.includes(tag),
            false,
          ),
        ),
      [items, value, selected],
    );

  return (
    <>
      <Stack spacing={1} p={theme.spacing(2, 2, 0)}>
        <Typography variant='h5'>Items</Typography>
        <OutlinedInput
          size='small'
          placeholder='Search by name, tag, or description...'
          spellCheck={false}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          startAdornment={<Search sx={{ pr: 1 }} />}
          endAdornment={
            value.length > 0 && (
              <InputAdornment position='end'>
                <IconButton onClick={() => setValue('')} edge='end'>
                  <Clear sx={{ width: 15, height: 15 }} />
                </IconButton>
              </InputAdornment>
            )
          }
        />
        <Box sx={{ pt: 0.5 }}>
          <ItemTagChips
            tags={tags}
            selected={selected ?? []}
            onChange={(tag: ItemTagKey) =>
              setSelected((selected) =>
                selected.find((t) => t === tag)
                  ? selected.filter((t) => t !== tag)
                  : selected.concat(tag),
              )
            }
          />
        </Box>
      </Stack>
      <Divider sx={{ mt: 2 }} />
      <Stack
        ref={ref}
        spacing={1.5}
        p={theme.spacing(2, 2, 0)}
        sx={{ overflowY: 'auto' }}
      >
        {(items.length > 0
          ? filtered
          : Array.from(
              new Array(20),
              () =>
                ['', { name: '', tags: [] as ItemTagKey[] }] as [string, Item],
            )
        )
          .sort(([_a, { name: a }], [_b, { name: b }]) => a.localeCompare(b))
          .map(([id, { name, plaintext, tags }], i) => (
            <Lazy key={i}>
              <Stack
                direction='row'
                p={1}
                justifyContent='left'
                component={CardActionArea}
                onClick={() => navigate(`${id}`)}
                gap={1}
                sx={{
                  p: 1,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: theme.palette.divider,
                  borderRadius: `${theme.shape.borderRadius}px`,
                  height: { xs: 60, sm: 64 },
                }}
              >
                <ItemAvatar itemId={id} version={version} size={40} />
                <Box
                  sx={{
                    flex: 2,
                    minWidth: 0,
                    display: { xs: 'none', sm: 'block' },
                  }}
                >
                  <Typography variant='h5' fontSize={18}>
                    {name}
                  </Typography>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {plaintext}
                  </Typography>
                </Box>

                <Stack direction='row' flex={3} flexWrap='wrap' gap={1}>
                  {tags
                    .filter(
                      (tag) =>
                        !['CooldownReduction', 'MagicResist'].includes(tag),
                    )
                    .map((tag) => (
                      <ItemTagChip key={tag} tag={tag} />
                    ))}
                </Stack>
              </Stack>
            </Lazy>
          ))
          .concat([<Box key='list-end-space' height={40} />])}
      </Stack>
      <ScrollTopFab target={ref.current} />
    </>
  );
};
