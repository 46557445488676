import React, { useRef, useState } from 'react';

import {
  Divider,
  List,
  Typography,
  Stack,
  useTheme,
  Box,
  ListItem,
} from '@mui/material';

import {
  MatchItem,
  PositionFilter,
  QueueFilter,
  ScrollTopFab,
  Summary,
} from 'components';
import { useAccount, useStatbudMatchesByMatchIds } from 'hooks';
import { useLocalStorage } from 'usehooks-ts';

export const MatchHistory: React.FC = () => {
  const theme = useTheme(),
    { account } = useAccount(),
    ref = useRef<HTMLUListElement>(null),
    [queues, setQueues] = useLocalStorage<number[]>(
      'match-history-queue-filter',
      [],
    ),
    queueType =
      queues.every((id) => id === 420 || id === 440) && queues.length === 2
        ? 'ranked'
        : undefined,
    // TODO: support loading additional matches through automatic pagination using cursor
    { matches, loading } = useStatbudMatchesByMatchIds(
      queueType ? undefined : queues,
      queueType,
    ),
    [positions, setPositions] = useState<string[]>(() => []),
    filteredMatches = Array.from(matches ?? []).filter(
      ({ info: { queueId, participants } }) => {
        const participant = participants?.find(
          (p) => p.puuid === account?.puuid,
        );
        if (participant === undefined) return false;
        // if queue is ARAM or URF, assume position is NONE
        if (queueId === 450 || queueId === 900)
          participant.teamPosition = 'NONE';
        return (
          (queues.length === 0 || queues.includes(queueId)) &&
          (positions.length === 0 ||
            positions
              .map((position) => position.toLowerCase())
              .includes(participant.teamPosition.toLowerCase()))
        );
      },
    );

  return (
    <>
      <Box>
        <Typography variant='h5' flex={1} p={theme.spacing(2, 2, 0)}>
          Match History
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          p={theme.spacing(0, 2)}
        >
          <Box flex={1} display='flex' alignItems='center'>
            <QueueFilter
              value={queues}
              onChange={(queues) => setQueues(queues)}
            />
          </Box>
          <PositionFilter
            onChange={(_, positions) => {
              setPositions(positions);
            }}
            alignItems='center'
            justifyContent='space-evenly'
            hideNone
            flex={1}
          />
        </Stack>
        <Divider sx={{ m: theme.spacing(1, 0) }} />
        <Summary matches={filteredMatches.map(({ info }) => info)} />
        <Divider sx={{ m: theme.spacing(1, 0, 0) }} />
      </Box>
      <List
        ref={ref}
        disablePadding
        sx={{
          flexDirection: 'column',
          flex: 1,
          overflowY: 'auto',
        }}
      >
        {!loading && filteredMatches.length === 0 ? (
          <ListItem sx={{ display: 'flex' }} alignItems='center'>
            <Typography>No Maidens</Typography>
          </ListItem>
        ) : filteredMatches.length === 0 ? (
          Array.from(new Array(10)).map((_, i) => (
            <React.Fragment key={i}>
              <MatchItem />
              <Divider component='li' />
            </React.Fragment>
          ))
        ) : (
          filteredMatches
            .sort(
              (
                { info: { gameStartTimestamp: a } },
                { info: { gameStartTimestamp: b } },
              ) => b - a,
            )
            .map(({ metadata: { matchId } }, i) => (
              <React.Fragment key={i}>
                <MatchItem matchId={matchId} />
                <Divider component='li' />
              </React.Fragment>
            ))
            .concat([
              <Box key={filteredMatches.length} component='li' height={56} />,
            ])
        )}
      </List>
      <ScrollTopFab target={ref.current} />
    </>
  );
};
