import {
  AccountV1AccountDto,
  MatchV5InfoDto,
  MatchV5ParticipantDto,
} from '@blakearoberts/riot-api-ts';
import {
  Box,
  Stack,
  Typography,
  alpha,
  useTheme,
  Skeleton,
  TypographyProps,
} from '@mui/material';

import { GoldAvatar, MatchHistoryLink, MatchResult } from 'components';

const SkeletonTypography: React.FC<{ alive?: boolean } & TypographyProps> = ({
  alive,
  children,
  ...rest
}) => <Typography {...rest}>{alive ? children : <Skeleton />}</Typography>;

interface SplashProps {
  src?: string;
}

const Splash: React.FC<SplashProps> = ({ src }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundImage: src ? `url(${src})` : '',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'overlay',
        backgroundPosition: 'center',
        backgroundColor: alpha(theme.palette.background.paper, 0.4),
        boxShadow: `0 0 10px 10px inset ${theme.palette.background.paper}, \
                    0 0 34px 40px inset ${theme.palette.background.paper}`,
        // TODO: template theme.vars.overlays[elevation] as first box shadow layer
        [theme.getColorSchemeSelector('dark')]: {
          boxShadow: `0 0 0   999px inset rgba(255 255 255 / 0.05), \
                      0 0 45px 15px inset ${theme.palette.background.paper}, \
                      0 0 45px 50px inset ${theme.palette.background.paper}`,
        },
      }}
    />
  );
};

interface SplashesProps {
  championId1?: string;
  championId2?: string;
}

const Splashes: React.FC<SplashesProps> = ({ championId1, championId2 }) => {
  const url = (id?: string) =>
      id !== undefined
        ? `https://ddragon.leagueoflegends.com/cdn/img/champion/splash/${id}_0.jpg`
        : '',
    championSplashUrl1 = url(championId1),
    championSplashUrl2 = url(championId2);
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        borderBottomLeftRadius: (theme) => theme.shape.borderRadius,
        borderBottomRightRadius: (theme) => theme.shape.borderRadius,
      }}
    >
      <Splash src={championSplashUrl1} />
      <Splash src={championSplashUrl2} />
    </Box>
  );
};

interface LaneResultProps {
  win?: boolean;
  gameDuration?: number;
  remake?: boolean;
}

const LaneResult: React.FC<LaneResultProps> = ({
  win,
  gameDuration,
  remake,
}) => {
  return win === undefined || gameDuration === undefined ? (
    <></>
  ) : (
    <Stack flexGrow={0} flexShrink={0} zIndex='inherit'>
      <MatchResult
        win={win}
        gameDuration={gameDuration}
        remake={remake}
        variant='h3'
      />
      <Typography variant='h4' color='text.secondary' flexGrow={1}>
        VS
      </Typography>
    </Stack>
  );
};

interface SummonerSummaryProps {
  info?: MatchV5InfoDto;
  participant?: MatchV5ParticipantDto;
}

export const SummonerSummary: React.FC<SummonerSummaryProps> = ({
  info,
  participant,
}) => {
  const theme = useTheme(),
    alive = Boolean(info) && Boolean(participant),
    kills = participant?.kills ?? 0,
    deaths = participant?.deaths ?? 0,
    assists = participant?.assists ?? 0,
    totalTeamKills = info?.participants
      ?.filter(({ teamId }) => teamId === participant?.teamId)
      .reduce((kills, participant) => kills + participant.kills, 0),
    kp = ((kills + assists) / Math.max(totalTeamKills ?? 1, 1)).toLocaleString(
      undefined,
      {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      },
    );

  return (
    <Box flex={1} width={0} zIndex='inherit'>
      <Stack spacing={1} sx={{ m: 1 }}>
        <SkeletonTypography
          alive={alive}
          variant='h5'
          noWrap
          textOverflow='ellipsis'
        >
          <MatchHistoryLink
            riotIdGameName={
              participant?.riotIdGameName ?? participant?.summonerName
            }
            riotIdTagLine={participant?.riotIdTagline}
          />
        </SkeletonTypography>
        <SkeletonTypography
          alive={alive}
          variant='h6'
        >{`${kills}/${deaths}/${assists}`}</SkeletonTypography>
        <Stack direction='row' justifyContent='center'>
          <SkeletonTypography alive={alive} variant='subtitle2' flexGrow={1}>
            {kp} KP
          </SkeletonTypography>
          <Stack
            direction='row'
            justifyContent='center'
            flexGrow={1}
            spacing={0.25}
          >
            <SkeletonTypography alive={alive} variant='subtitle2'>
              {participant?.goldEarned.toLocaleString()}
            </SkeletonTypography>
            <GoldAvatar
              size={20}
              color={theme.palette.mode === 'dark' ? 'white' : undefined}
            />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

interface Props {
  account?: AccountV1AccountDto;
  info?: MatchV5InfoDto;
}

export const LaneSummary: React.FC<Props> = ({ account, info }) => {
  const summoner1 = info?.participants.find(
      ({ puuid }) => puuid === account?.puuid,
    ),
    summoner2 = info?.participants.find(
      ({ teamId, teamPosition }) =>
        teamPosition === summoner1?.teamPosition &&
        teamId !== summoner1?.teamId,
    );
  if (
    account === undefined ||
    info === undefined ||
    (account !== undefined && summoner1 === undefined)
  )
    return <></>;
  return (
    <Box position='relative' overflow='hidden'>
      <Stack
        direction='row'
        alignItems='center'
        textAlign='center'
        p={({ spacing }) => spacing(4, 2, 3)}
        zIndex={1}
      >
        <SummonerSummary info={info} participant={summoner1} />
        <LaneResult
          win={summoner1?.win}
          gameDuration={info?.gameDuration}
          remake={summoner1?.gameEndedInEarlySurrender}
        />
        <SummonerSummary info={info} participant={summoner2} />
      </Stack>
      <Splashes
        championId1={summoner1?.championName}
        championId2={summoner2?.championName}
      />
    </Box>
  );
};
