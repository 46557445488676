import { Datum, useChart } from './context';
import { isScaleContinuousNumeric } from './types';

interface Props {
  xs?: number[];
  ys?: number[];

  xTickCount?: number;
  yTickCount?: number;

  stroke?: string;
  strokeWidth?: string | number;
}

export const Grid = <D extends Datum>({
  xs,
  ys,

  xTickCount,
  yTickCount,

  stroke,
  strokeWidth,
}: Props) => {
  const { width, height, padding, data, x: fx, y: fy } = useChart<D>(),
    offset =
      typeof window !== 'undefined' && window.devicePixelRatio > 1 ? 0 : 0.5;

  if (width === 0 || height === 0 || data.length === 0) return <></>;

  return (
    <g data-chart-component='grid'>
      {isScaleContinuousNumeric(fx) &&
        (xs ?? fx.ticks(xTickCount)).map((v, i) => {
          const x = (fx(v) ?? 0) + offset;
          return (
            <line
              key={i}
              x1={x}
              x2={x}
              y1={padding[0]}
              y2={height - padding[2]}
              fill='none'
              stroke={stroke ?? 'currentColor'}
              strokeWidth={strokeWidth ?? 0.5}
            />
          );
        })}
      {isScaleContinuousNumeric(fy) &&
        (ys ?? fy.ticks(yTickCount)).map((v, i) => {
          const y = (fy(v) ?? 0) + offset;
          return (
            <line
              key={i}
              x1={padding[3]}
              x2={width - padding[1]}
              y1={y}
              y2={y}
              fill='none'
              stroke={stroke ?? 'currentColor'}
              strokeWidth={strokeWidth ?? 0.5}
            />
          );
        })}
    </g>
  );
};
