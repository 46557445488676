import React, { useState } from 'react';

import {
  ButtonGroup as MuiButtonGroup,
  InputBase,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  SelectProps,
  styled,
  ToggleButton,
} from '@mui/material';

import { MatchQueues } from 'types';

const ButtonGroup = styled(MuiButtonGroup)(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 1,
  overflow: 'hidden',
  '& .MuiButtonBase-root': {
    borderColor: theme.palette.divider,
    borderRadius: 0,
    borderWidth: '1px 1px 1px 0px',
    fontSize: theme.typography.caption.fontSize,
    padding: theme.spacing(1),
    minWidth: 'max-content',
    '&.Mui-selected': {
      backgroundColor: `rgba(${theme.vars.palette.primary.mainChannel} / ${theme.vars.palette.action.selectedOpacity})`,
    },
    '&:hover': {
      backgroundColor: theme.vars.palette.action.hover,
    },
    '&:first-of-type': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: 0,
      borderLeftWidth: 1,
    },
  },
}));

const Select = styled(MuiSelect<number[]>, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<SelectProps<number[]> & { selected: boolean }>(({ theme, selected }) => ({
  flex: 1,
  width: 0,
  overflow: 'hidden',
  borderTopLeftRadius: 0,
  borderTopRightRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: theme.shape.borderRadius,
  borderStyle: 'solid',
  borderWidth: '1px 1px 1px 0px',
  borderColor: theme.vars.palette.divider,
  cursor: 'pointer',
  color: theme.vars.palette.text.primary,
  backgroundColor: selected
    ? `rgba(${theme.vars.palette.primary.mainChannel} / ${theme.vars.palette.action.selectedOpacity})`
    : 'unset',
  '&:hover': {
    backgroundColor: theme.vars.palette.action.hover,
  },
  '& .MuiSelect-select': {
    paddingLeft: theme.spacing(1),
  },
}));

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  ...theme.typography.button,
  color: theme.palette.text.secondary,
  fontSize: theme.typography.caption.fontSize,
}));

const RenderValueItem = styled('span')(({ theme }) => ({
  ...theme.typography.button,
  fontSize: theme.typography.caption.fontSize,
}));

const queueIds = [400, 420, 440, 450, 490, 900, 1020, 1300, 1700];

const rankedQueues = [
  [420, 1],
  [440, 2],
];

const normalQueues = [
  [400, 0],
  [450, 3],
  [490, 4],
  [900, 5],
  [1020, 6],
  [1300, 7],
  [1700, 8],
];

interface Props {
  value?: number[];
  onChange?: (queues: number[]) => void;
}

export const QueueFilter: React.FC<Props> = ({ value, onChange }) => {
  const [queues, setQueues] = useState<boolean[]>(
      queueIds.map((id) => value?.includes(id) ?? false),
    ),
    handleToggle = (queueId: number) => {
      const queueIndex = queueIds.indexOf(queueId),
        curQueues = queues.map((value, i) =>
          i === queueIndex ? !value : value,
        );
      if (onChange !== undefined)
        onChange(queueIds.filter((_, i) => curQueues[i]));
      setQueues(curQueues);
    };
  return (
    <ButtonGroup>
      {rankedQueues.map(([id, i]) => (
        <ToggleButton
          key={id}
          value={id}
          selected={queues.at(i)}
          onClick={() => handleToggle(id)}
        >
          {MatchQueues.get(id)}
        </ToggleButton>
      ))}
      <Select
        selected={
          normalQueues.filter(([id]) => queues[queueIds.indexOf(id)]).length > 0
        }
        multiple
        value={normalQueues
          .filter(([id]) => queues[queueIds.indexOf(id)])
          .map(([id]) => id)}
        displayEmpty
        input={<InputBase name='lol-queues-extended' />}
        renderValue={(values) => (
          <RenderValueItem>
            {values.map((value) => MatchQueues.get(value)).join(', ')}
          </RenderValueItem>
        )}
      >
        {normalQueues.map(([id, i]) => (
          <MenuItem
            key={id}
            value={id}
            selected={queues.at(i)}
            onClick={() => handleToggle(id)}
          >
            {MatchQueues.get(id)}
          </MenuItem>
        ))}
      </Select>
    </ButtonGroup>
  );
};
