import { Skeleton, Typography, TypographyProps } from '@mui/material';
import { blue, grey, red } from '@mui/material/colors';

interface Props extends TypographyProps {
  win?: boolean;
  gameDuration?: number;
  remake?: boolean;
}

export const MatchResult: React.FC<Props> = ({
  win,
  gameDuration,
  remake,
  ...rest
}) => {
  const result =
    win === undefined || gameDuration === undefined
      ? ['Unknown', grey[500]]
      : remake
      ? ['Remake', grey[500]]
      : win
      ? ['Victory', blue[300]]
      : ['Defeat', red[300]];
  return (
    <Typography
      justifyContent='center'
      display={win === undefined ? 'flex' : undefined}
      variant='h5'
      color={result[1]}
      {...rest}
    >
      {win !== undefined ? result[0] : <Skeleton width={100} />}
    </Typography>
  );
};
