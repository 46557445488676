import React from 'react';

import { Parser, Node } from 'commonmark';
import {
  CircularProgress,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Circle } from '@mui/icons-material';

interface Props {
  content?: string;
}

export const Markdown: React.FC<Props> = ({ content }) => {
  const theme = useTheme(),
    visit: (node: Node | null) => React.ReactNode = (n) => {
      if (n === null) return null;
      if (n.type === 'text') return n.literal;

      const children: React.ReactNode[] = [];
      let child = n.firstChild;
      do {
        children.push(visit(child));
        if (child === null || child === n.lastChild) break;
      } while ((child = child.next));

      switch (n.type) {
        case 'document':
          return <>{children}</>;
        case 'emph':
          return (
            <Typography
              key={n.parent!.sourcepos.toString()}
              fontWeight={600}
              component='span'
            >
              {children}
            </Typography>
          );
        case 'heading':
          return (
            <Typography
              key={n.sourcepos.toString()}
              variant={`h${n.level + 2}` as any}
              sx={{ pt: 2, pb: 1 }}
            >
              {children}
            </Typography>
          );
        case 'item':
          return (
            <ListItem
              key={n.sourcepos.toString()}
              disablePadding
              sx={{ pl: 3 }}
            >
              <ListItemIcon>
                <Circle sx={{ width: 10 }} />
              </ListItemIcon>
              {children}
            </ListItem>
          );
        case 'link':
          return (
            <Link
              key={n.parent?.sourcepos.toString()}
              href={n.destination ?? '#'}
            >
              {children}
            </Link>
          );
        case 'list':
          return <List key={n.sourcepos.toString()}>{children}</List>;
        case 'paragraph':
          return (
            <Typography key={n.sourcepos.toString()}>{children}</Typography>
          );
        case 'thematic_break':
          return (
            <Divider
              key={n.sourcepos.toString()}
              sx={{ m: theme.spacing(2, 0) }}
            />
          );
        default:
          console.warn('unknown type', n.type, n);
          return (
            <React.Fragment
              key={n.sourcepos?.toString() ?? n.parent?.sourcepos?.toString()}
            >
              {children}
            </React.Fragment>
          );
      }
    };

  return (
    <>
      {content === undefined ? (
        <Stack justifyContent='center' alignItems='center'>
          <CircularProgress />
        </Stack>
      ) : (
        <>{visit(new Parser().parse(content).walker().next().node)}</>
      )}
    </>
  );
};
