import { Avatar } from '@mui/material';

interface Props {
  size?: number;
  color?: 'black' | 'white';
}

export const GoldAvatar: React.FC<Props> = ({ size, color }) => {
  size ??= 32;
  return (
    <Avatar
      variant='rounded'
      alt='gold'
      src={require('assets/icon_gold.png')}
      sx={{
        width: size,
        height: size,
        filter:
          color === undefined
            ? 'unset'
            : color === 'black'
            ? 'brightness(0)'
            : 'brightness(0) invert(1)',
      }}
    />
  );
};
