import { useNavigate, useParams } from 'react-router-dom';

import { MatchV5MatchDto } from '@blakearoberts/riot-api-ts';
import { LastPage, NavigateBefore, NavigateNext } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Paper,
  Stack,
  Tab,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { blue, grey, red } from '@mui/material/colors';

import { formatMatchDuration } from 'helpers';
import { useAccount, useLpDelta, useMatch, useMatchIds } from 'hooks';
import { MatchQueues } from 'types';

import { LaneSummary } from './LaneSummary';
import { Overview } from './Overview';
import { Stats } from './Stats';
import { Timeline } from './Timeline';

const DirButton = styled(Button)(() => ({
  '& .MuiButton-startIcon': {
    marginRight: 0,
  },
  '& .MuiButton-endIcon': {
    marginLeft: 0,
  },
}));

const Pre = styled('pre')(({ theme }) => ({
  ...theme.typography.overline,
  display: 'inline',
  fontWeight: 600,
  [theme.breakpoints.down(400)]: {
    display: 'none',
  },
}));

interface HeaderProps {
  match?: MatchV5MatchDto;
}

const Header: React.FC<HeaderProps> = ({ match }) => {
  const { queueId, gameDuration, gameStartTimestamp } = match?.info || {},
    duration = formatMatchDuration(gameDuration ?? 0),
    date = new Date(gameStartTimestamp ?? 0),
    lpDelta = useLpDelta(match?.metadata.matchId);

  return (
    <Stack direction='row' spacing={1} flexGrow={1}>
      {match === undefined ? (
        <Typography variant='overline'>&nbsp;</Typography>
      ) : (
        <>
          <Typography variant='overline' fontWeight={600}>
            {MatchQueues.get(queueId ?? 0)}
            <Pre>
              {' \u2022 '}
              <Typography
                variant='overline'
                fontWeight={600}
                textTransform='none'
              >
                {duration}
              </Typography>
            </Pre>
            {lpDelta !== undefined ? (
              <Pre>
                {' \u2022 '}
                <Typography
                  variant='overline'
                  fontWeight={600}
                  textTransform='none'
                  color={
                    lpDelta > 0 ? blue[300] : lpDelta < 0 ? red[300] : grey[500]
                  }
                >
                  {lpDelta.toLocaleString(undefined, {
                    signDisplay: 'exceptZero',
                  })}{' '}
                  LP
                </Typography>
              </Pre>
            ) : undefined}
          </Typography>
          <Box flexGrow={1} />
          <Typography variant='overline' fontWeight={600}>
            {date.toDateString()}
            <Pre>
              {' \u2022 '}
              {date.toLocaleString(undefined, {
                hour: 'numeric',
                hour12: true,
                minute: 'numeric',
              })}
            </Pre>
          </Typography>
        </>
      )}
    </Stack>
  );
};

export const MatchSummary: React.FC = () => {
  const theme = useTheme(),
    xs = useMediaQuery(theme.breakpoints.down('sm')),
    { matchId, tab } = useParams(),
    { account } = useAccount(),
    { match, loading } = useMatch(matchId),
    navigate = useNavigate(),
    participant = match?.info.participants.find(
      ({ puuid }) => puuid === account?.puuid,
    ),
    ids = useMatchIds(match?.info.queueId).matchIds?.sort().reverse(),
    i = ids?.indexOf(matchId ?? '') ?? -1,
    prevId = ids?.at(i + 1),
    nextId = i === 0 ? matchId : ids?.at(i - 1),
    lastId = ids?.at(0);

  return (
    <>
      <Paper elevation={xs ? 0 : 1} sx={{ m: 2 }}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
          sx={{ p: { xs: 1, sm: 2 } }}
        >
          <Typography variant='h5' flex={1}>
            Match Summary
          </Typography>
          <ButtonGroup size='small' color='neutral' disabled={loading}>
            <DirButton
              startIcon={<NavigateBefore />}
              disabled={prevId === undefined || prevId === matchId}
              onClick={() =>
                navigate(
                  (tab === undefined ? '' : '../') +
                    `../${prevId}` +
                    (tab === undefined ? '' : `/${tab}`),
                  {
                    relative: 'path',
                  },
                )
              }
            >
              {xs ? <>&#8203;</> : 'Previous'}
            </DirButton>
            <DirButton
              endIcon={<NavigateNext />}
              disabled={nextId === undefined || nextId === matchId}
              onClick={() =>
                navigate(
                  (tab === undefined ? '' : '../') +
                    `../${nextId}` +
                    (tab === undefined ? '' : `/${tab}`),
                  {
                    relative: 'path',
                  },
                )
              }
            >
              {xs ? <>&#8203;</> : 'Next'}
            </DirButton>
            <DirButton
              endIcon={<LastPage />}
              disabled={lastId === undefined || lastId === matchId}
              onClick={() =>
                navigate(
                  (tab === undefined ? '' : '../') +
                    `../${lastId}` +
                    (tab === undefined ? '' : `/${tab}`),
                  {
                    relative: 'path',
                  },
                )
              }
            >
              {xs ? <>&#8203;</> : 'Latest'}
            </DirButton>
          </ButtonGroup>
        </Stack>
        <Divider />

        <Box sx={{ p: { xs: theme.spacing(0, 1), sm: theme.spacing(0, 2) } }}>
          <Header match={match} />
          <Divider />
        </Box>

        <Box sx={{ display: xs ? 'none' : 'block' }}>
          <LaneSummary account={account} info={match?.info} />
        </Box>
      </Paper>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0,
        }}
      >
        <TabContext value={tab ?? 'overview'}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={(_, next) => {
                navigate((tab === undefined ? '' : '../') + next, {
                  relative: 'path',
                  replace: true,
                });
              }}
            >
              <Tab label='Overview' value='overview' />
              <Tab label='Stats' value='stats' />
              <Tab label='Timeline' value='timeline' />
            </TabList>
          </Box>

          <TabPanel
            value='overview'
            sx={{
              // flex: 1,
              // flexDirection: 'column',
              height: '100%',
              overflowY: 'auto',
              p: {
                xs: theme.spacing(0.5, 0.5, 1),
                sm: theme.spacing(0.5, 2, 2),
              },
            }}
          >
            {match?.info.teams
              .sort((a) => (a.teamId === participant?.teamId ? -1 : 1))
              .map((team, i) => (
                <Box key={i} sx={{ pt: { xs: 0.5, sm: 1.5 } }}>
                  <Overview team={team} info={match.info} />
                </Box>
              ))}
          </TabPanel>
          <TabPanel
            value='stats'
            sx={{
              flex: 1,
              flexDirection: 'column',
              overflowY: 'auto',
              p: {
                xs: theme.spacing(1, 0.5, 1),
                sm: theme.spacing(2, 2, 2),
              },
            }}
          >
            <Stats />
          </TabPanel>
          <TabPanel
            value='timeline'
            sx={{
              flex: 1,
              flexDirection: 'column',
              overflowY: 'auto',
              p: {
                xs: theme.spacing(1, 0.5, 1),
                sm: theme.spacing(2, 2, 2),
              },
            }}
          >
            <Timeline matchId={matchId} />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};
