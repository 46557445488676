import useSWRImmutable from 'swr/immutable';

import {
  Champions,
  SummonerSpells,
  RuneStyle,
  Challenge,
  Champion,
  Items,
} from 'types';

const dDragonEndpoint = 'https://ddragon.leagueoflegends.com';

const useDDragonVersion = (version?: string) => {
  const url =
      version === undefined
        ? `${dDragonEndpoint}/api/versions.json`
        : undefined,
    { data: versions } = useSWRImmutable(url, () =>
      fetch(url!).then((r) => r.json() as Promise<string[]>),
    );
  version ??= versions?.[0];
  return { version };
};

const useDDragonSWR = <T>(path?: string, version?: string) => {
  const { version: latest } = useDDragonVersion(version);
  version ??= latest;
  return useSWRImmutable(
    version && path
      ? `${dDragonEndpoint}/cdn/${version}/data/en_US${path}`
      : undefined,
    () =>
      fetch(`${dDragonEndpoint}/cdn/${version}/data/en_US${path}`).then(
        (r) => r.json() as T,
      ),
  );
};

export const useDDragonChallenges = (version?: string) => {
  const { data, isLoading } = useDDragonSWR<Challenge[]>(
    '/challenges.json',
    version,
  );
  return {
    challenges: data,
    loading: isLoading,
  };
};

export const useDDragonChampions = (version?: string) => {
  const { data, isLoading } = useDDragonSWR<Champions>(
    '/champion.json',
    version,
  );
  return {
    champions: Object.values(data?.data ?? {}),
    loading: isLoading,
  };
};

export const useDDragonItems = (version?: string) => {
  const { data, isLoading } = useDDragonSWR<Items>('/item.json', version);
  return {
    items: data,
    loading: isLoading,
  };
};

export const useDDragonRunes = (version?: string) => {
  const { data, isLoading } = useDDragonSWR<RuneStyle[]>(
    '/runesReforged.json',
    version,
  );
  return {
    runes: data,
    loading: isLoading,
  };
};

export const useDDragonSpells = (version?: string) => {
  const { data, isLoading } = useDDragonSWR<SummonerSpells>(
    '/summoner.json',
    version,
  );
  return {
    spells: data,
    loading: isLoading,
  };
};

export const useDDragonData = (version?: string) => {
  const { version: latest } = useDDragonVersion(version),
    { challenges } = useDDragonChallenges(version),
    { champions } = useDDragonChampions(version),
    { items } = useDDragonItems(version),
    { runes } = useDDragonRunes(version),
    { spells } = useDDragonSpells(version);
  version ??= latest;
  return {
    version,
    challenges,
    champions,
    items,
    runes,
    spells,
  };
};

export const useDDragonChampion = (championId?: string, version?: string) => {
  const { data, isLoading, isValidating } = useDDragonSWR<Champion>(
    championId ? `/champion/${championId}.json` : undefined,
    version,
  );
  return {
    champion: championId ? data?.data[championId] : undefined,
    loading: isLoading || isValidating,
  };
};

export const useChampion = (championId?: number, version?: string) => {
  const { champions } = useDDragonChampions(version);
  return champions?.find(({ key }) => key === championId?.toString());
};

export const useChampionIcon = (championId?: number, version?: string) => {
  const { version: latest } = useDDragonVersion(),
    { champions } = useDDragonChampions(),
    champion = champions?.find(({ key }) => key === championId?.toString());
  version ??= latest;
  return version && champion
    ? `https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion.image.full}`
    : undefined;
};

export const useItem = (itemId?: string, version?: string) => {
  const { items } = useDDragonItems(version);
  return itemId ? items?.data[itemId] : undefined;
};

export const useItemIcon = (itemId?: string, version?: string) => {
  const { version: latest } = useDDragonVersion();
  version ??= latest;
  return itemId && version
    ? `https://ddragon.leagueoflegends.com/cdn/${version}/img/item/${itemId}.png`
    : undefined;
};

export const useSpellIcon = (spellId?: number, version?: string) => {
  const { version: latest } = useDDragonVersion(version),
    { spells } = useDDragonSpells(version),
    spell = Object.values(spells?.data || {}).find(
      (spell) => spell.key === spellId?.toString(),
    );
  version ??= latest;
  return version && spell
    ? `https://ddragon.leagueoflegends.com/cdn/${version}/img/spell/${spell.image.full}`
    : undefined;
};

export const useRuneStyleIcon = (runeId?: number, version?: string) => {
  const { runes } = useDDragonRunes(version),
    runeStyle = runes?.find(({ id }) => id === runeId);
  return runeStyle
    ? 'https://ddragon.leagueoflegends.com/cdn/img/' + runeStyle.icon
    : undefined;
};

export const useRuneStylePerkIcon = (
  runeId?: number,
  perkId?: number,
  version?: string,
) => {
  const { runes } = useDDragonRunes(version),
    icon = runes
      ?.find(({ id }) => id === runeId)
      ?.slots[0].runes.find(({ id }) => id === perkId)?.icon;
  return icon
    ? 'https://ddragon.leagueoflegends.com/cdn/img/' + icon
    : undefined;
};
