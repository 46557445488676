// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile, HttpInfo} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { MatchV5MatchDto } from '../models/MatchV5MatchDto';

/**
 * no description
 */
export class MatchV5ApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * Get a match by match id
     * Get matches by match IDs
     * @param matchIds 
     */
    public async matchV5GetMatches(matchIds: Array<string>, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'matchIds' is not null or undefined
        if (matchIds === null || matchIds === undefined) {
            throw new RequiredError("MatchV5Api", "matchV5GetMatches", "matchIds");
        }


        // Path Params
        const localVarPath = '/lol/match/v5/matches';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (matchIds !== undefined) {
            requestContext.setQueryParam("matchIds", ObjectSerializer.serialize(matchIds, "Array<string>", ""));
        }


        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class MatchV5ApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to matchV5GetMatches
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async matchV5GetMatchesWithHttpInfo(response: ResponseContext): Promise<HttpInfo<Array<MatchV5MatchDto> >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<MatchV5MatchDto> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<MatchV5MatchDto>", ""
            ) as Array<MatchV5MatchDto>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<MatchV5MatchDto> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<MatchV5MatchDto>", ""
            ) as Array<MatchV5MatchDto>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
