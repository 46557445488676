import { useMemo } from 'react';

import {
  AccountV1AccountDto,
  MatchV5ParticipantFrameDto,
} from '@blakearoberts/riot-api-ts';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

import { Riot } from 'api';

import { useAccount, useSummoner } from './summoner';

const useRiotSWR = <T>(
  path: string | undefined,
  fetcher: (account: AccountV1AccountDto) => Promise<T>,
) => {
  const { account } = useAccount(),
    { data, isLoading } = useSWR(account ? path : undefined, async () => {
      return await fetcher(account!);
    });
  return { data, isLoading };
};

export const useLeagues = () => {
  const { summoner } = useSummoner(),
    { data: leagues } = useSWR(
      summoner
        ? `/lol/leagues/v4/entries/by-summoner/${summoner.id}`
        : undefined,
      () =>
        Riot.LeagueV4.leagueV4GetLeagueEntriesForSummoner({
          encryptedSummonerId: summoner!.id,
        }),
    );
  return { leagues };
};

export const useChallenges = () => {
  const { account } = useAccount(),
    { data: challenges } = useRiotSWR(
      account ? `/lol/challenges/v1/player-data/${account.puuid}` : undefined,
      ({ puuid }) =>
        Riot.LolChallengesV1.lolChallengesV1GetPlayerData({
          puuid: puuid,
        }),
    );
  return { challenges };
};

export const useMasteries = () => {
  const { account } = useAccount(),
    { data: masteries } = useRiotSWR(
      account
        ? `/lol/champion-mastery/v4/champion-masteries/by-puuid/${account.puuid}`
        : undefined,
      ({ puuid }) =>
        Riot.ChampionMasteryV4.championMasteryV4GetAllChampionMasteriesByPUUID({
          encryptedPUUID: puuid,
        }),
    );
  return { masteries };
};

const matchIdsKey = (
  puuid?: string,
  queue?: number,
  type?: 'ranked' | 'normal',
  count: number = 20,
) => {
  if (puuid === undefined && queue === undefined) return undefined;
  let key = `/lol/match/v5/matches/by-puuid/${puuid}/ids?count=${count}`;
  if (queue !== undefined) key += `&queue=${queue}`;
  if (type !== undefined) key += `&type=${type}`;
  return key;
};

export const useMatchIds = (
  queue?: number,
  type?: 'ranked' | 'normal',
  count: number = 20,
) => {
  const { account } = useAccount(),
    { data: matchIds, isLoading } = useRiotSWR(
      matchIdsKey(account?.puuid, queue, type, count),
      ({ puuid }) =>
        Riot.MatchV5.matchV5GetMatchIdsByPUUID({
          puuid,
          queue,
          type,
          count,
        }),
    );
  return { matchIds, loading: isLoading };
};

export const useMatch = (matchId?: string) => {
  const { data: match, isLoading } = useSWRImmutable(
    matchId ? `/lol/match/v5/matches/${matchId}` : undefined,
    async () => {
      const match = await Riot.MatchV5.matchV5GetMatch({ matchId: matchId! });
      // if queue is ARAM, URF, One for All, or Nexus Blitz, set position to JUNGLE if smite
      // was taken, else NONE
      if ([450, 900, 1020, 1300].includes(match.info.queueId)) {
        match.info.participants = match.info.participants.map((p) => ({
          ...p,
          teamPosition:
            p?.summoner1Id === 11 || p?.summoner2Id === 11 ? 'JUNGLE' : 'NONE',
          individualPosition:
            p?.summoner1Id === 11 || p?.summoner2Id === 11 ? 'JUNGLE' : 'NONE',
        }));
      }
      return match;
    },
  );
  return { match, loading: isLoading };
};

export const useMatchTimeline = (matchId?: string) => {
  const { data: timeline } = useSWRImmutable(
    matchId ? `/lol/match/v5/matches/${matchId}/timeline` : undefined,
    () => Riot.MatchV5.matchV5GetTimeline({ matchId: matchId! }),
    // TODO: turn on keep previous data, return loading status, add visual queue that new data is loading
    // TODO: with the above, estimate the number of data points for chart using match duration
    { keepPreviousData: false },
  );
  return { timeline };
};

export type MatchTimelineGoldFrame = {
  timestamp: number;
  participantId: number;
  teamId: number;
  totalGold: number;
  kill: number;
  assist: number;
  shutdown: number;
  cs: number;
  plate: number;
  tower: number;
  inhibitor: number;
  drake: number;
  rift: number;
  baron: number;
  horde: number;
  ward: number;
  passive: number;
};

export const useMatchGoldTimeline = (
  matchId?: string,
): MatchTimelineGoldFrame[] | undefined => {
  const { match } = useMatch(matchId),
    { timeline } = useMatchTimeline(matchId);

  return useMemo(() => {
    const ts15 = 15 * 60 * 1000,
      ts25 = 25 * 60 * 1000,
      csMeleeGold = 21,
      csCasterGold = 14,
      csCannonGold = (ts: number) =>
        Math.min(
          90,
          57 + 3 * (Math.round(Math.max(0, ts / 1000 - 215) / 90) + 1),
        ),
      csGoldPerWave = (ts: number) =>
        3 * csMeleeGold +
        3 * csCasterGold +
        csCannonGold(ts) / (ts <= ts15 ? 3 : ts <= ts25 ? 2 : 1),
      csGoldPerMinute = (ts: number) => csGoldPerWave(ts) * 2,
      csPerMinute = (ts: number) =>
        6 + 6 + (ts <= ts15 ? 2 / 3 : ts <= ts25 ? 1 : 2),
      avgGoldPerCs = (ts: number) => csGoldPerMinute(ts) / csPerMinute(ts),
      jungleCamps = {
        wolves: [4, 81],
        raptors: [4, 75],
        krugs: [4, 109],
        gromp: [4, 80],
        red: [4, 90],
        blue: [4, 90],
      },
      [totalJungleCS, totalJungleGold] = Object.values(jungleCamps).reduce(
        (total, [cs, g]) => [cs + total[0], g + total[1]],
        [0, 0],
      ),
      avgGoldPerJungleCS = totalJungleGold / totalJungleCS;

    return timeline?.info.frames.flatMap(
      ({ timestamp, events, participantFrames }, i, timeline) => {
        const prevFrames = (
            i > 0
              ? Object.values(timeline.at(i - 1)?.participantFrames ?? {})
              : []
          ) as MatchV5ParticipantFrameDto[],
          frames: { [k: string]: MatchTimelineGoldFrame } = Object.fromEntries(
            (
              Object.values(
                participantFrames ?? {},
              ) as MatchV5ParticipantFrameDto[]
            ).map(
              ({
                participantId,
                totalGold,
                minionsKilled,
                jungleMinionsKilled,
              }) => {
                const prevFrame = prevFrames.find(
                  ({ participantId: id }) => id === participantId,
                );
                let minionCs = minionsKilled;
                if (prevFrame) minionCs -= prevFrame.minionsKilled;
                const minionGold = minionCs * avgGoldPerCs(timestamp);

                let jungleCs = jungleMinionsKilled;
                if (prevFrame) jungleCs -= prevFrame.jungleMinionsKilled;
                const jungleGold = jungleCs * avgGoldPerJungleCS;

                return [
                  participantId,
                  {
                    timestamp,
                    participantId,
                    teamId: participantId < 6 ? 100 : 200,
                    totalGold,
                    kill: 0,
                    assist: 0,
                    shutdown: 0,
                    cs: Math.trunc(minionGold + jungleGold),
                    plate: 0,
                    tower: 0,
                    inhibitor: 0,
                    drake: 0,
                    rift: 0,
                    baron: 0,
                    horde: 0,
                    ward: 0,
                    passive:
                      timestamp < 120000
                        ? 0 // passive gold starts at 1:50 (every 10 seconds)
                        : Math.floor(
                            20.4 * // 20.4 gold per 10 seconds
                              // timestamp should be greater than the expected
                              // value unless it is the last frame (match end)
                              (timestamp > i * 60000
                                ? 6 // number of 10 seconds in 1 minute
                                : // final frame, or match end
                                  // estimate number of 10 seconds
                                  (i * 60000 - timestamp) / 10000),
                          ),
                  },
                ];
              },
            ),
          );

        for (const e of events) {
          const {
            assistingParticipantIds,
            bounty,
            buildingType,
            killerId,
            laneType,
            monsterSubType,
            monsterType,
            shutdownBounty,
            towerType,
            type,
            wardType,
          } = e;
          const killerTeamId =
              e.killerTeamId ??
              ((killerId ?? -1) > 0 ? frames[killerId!].teamId : -1),
            killerTeammateIds =
              match?.info.participants
                .filter(({ teamId }) => teamId === killerTeamId)
                .map(({ participantId }) => participantId) ?? [];

          switch (type) {
            case 'BUILDING_KILL':
              switch (buildingType) {
                case 'TOWER_BUILDING':
                  switch (towerType) {
                    case 'OUTER_TURRET':
                      if (killerId! > 0) frames[killerId!].tower += 250;
                      killerTeammateIds.forEach(
                        (id) => (frames[id].tower += 50),
                      );
                      break;
                    case 'INNER_TURRET':
                      if (killerId! > 0)
                        frames[killerId!].tower +=
                          laneType === 'MID_LANE' ? 425 : 675;
                      killerTeammateIds.forEach(
                        (id) => (frames[id].tower += 25),
                      );
                      break;
                    case 'BASE_TURRET':
                      if (killerId! > 0) frames[killerId!].tower += 375;
                      killerTeammateIds.forEach(
                        (id) => (frames[id].tower += 25),
                      );
                      break;
                    case 'NEXUS_TURRET':
                      killerTeammateIds.forEach(
                        (id) => (frames[id].tower += 50),
                      );
                      break;
                    default:
                      console.warn('unknown tower type', e);
                      break;
                  }
                  killerTeammateIds.forEach(
                    (id) => (frames[id].tower += bounty!),
                  );
                  break;
                case 'INHIBITOR_BUILDING':
                  if (killerId! > 0) frames[killerId!].tower += 50;
                  killerTeammateIds.forEach(
                    (id) => (frames[id].tower += bounty!),
                  );
                  break;
                default:
                  console.warn('unknown building type', e);
                  break;
              }
              break;
            case 'CAPTURE_POINT':
              console.log(e);
              break;
            case 'CHAMPION_KILL':
              if ((killerId ?? 0) > 0) {
                frames[killerId!].kill += bounty!;
                frames[killerId!].shutdown += shutdownBounty!;
              }
              assistingParticipantIds?.forEach(
                (id, _, ids) =>
                  (frames[id].assist += Math.round(bounty! / 2 / ids.length)),
              );
              break;
            case 'ELITE_MONSTER_KILL':
              switch (monsterType) {
                case 'BARON_NASHOR':
                  frames[killerId!].baron += 25;
                  killerTeammateIds.forEach(
                    (id, _, ids) =>
                      (frames[id].baron +=
                        300 + Math.round(bounty! / ids.length)),
                  );
                  break;
                case 'DRAGON':
                  frames[killerId!].drake +=
                    monsterSubType === 'ELDER_DRAGON' ? 100 : 25;
                  killerTeammateIds.forEach(
                    (id, _, ids) =>
                      (frames[id].drake +=
                        monsterSubType === 'ELDER_DRAGON'
                          ? 250
                          : 0 + Math.round(bounty! / ids.length)),
                  );
                  break;
                case 'RIFTHERALD':
                  if (killerId === 0) break;
                  const assistIds =
                    assistingParticipantIds?.filter(
                      (id) => frames[id].teamId === frames[killerId!].teamId,
                    ) ?? [];
                  frames[killerId!].rift +=
                    100 + Math.round(100 / (assistIds.length + 1));
                  assistIds.forEach(
                    (id, _, ids) =>
                      (frames[id].rift += Math.round(100 / (ids.length + 1))),
                  );
                  break;
                case 'HORDE':
                  if (killerId === 0) break;
                  frames[killerId!].horde += 20 + 10;
                  assistingParticipantIds?.forEach(
                    (id) => (frames[id].horde += 10),
                  );
                  break;
                default:
                  console.warn('unknown monster event', e);
                  break;
              }
              break;
            case 'TURRET_PLATE_DESTROYED':
              if (killerId! > 0) frames[killerId!].plate += 125;
              break;
            case 'WARD_KILL':
              switch (wardType) {
                case 'BLUE_TRINKET':
                  if (killerId! > 0) frames[killerId!].ward += 15;
                  break;
                case 'CONTROL_WARD':
                  if (killerId! > 0) frames[killerId!].ward += 30;
                  break;
                case 'YELLOW_TRINKET':
                  if (killerId! > 0) frames[killerId!].ward += 10;
                  break;
                case 'SIGHT_WARD':
                  // stealth wards
                  if (killerId! > 0) frames[killerId!].ward += 30;
                  break;
                case 'TEEMO_MUSHROOM':
                  break;
                case 'UNDEFINED':
                  // TODO: what are these? Jhin flowers or similar?
                  break;
                default:
                  console.log(e);
                  break;
              }
              break;
          }
        }
        return Object.values(frames);
      },
    );
  }, [match?.info.participants, timeline?.info.frames]);
};
