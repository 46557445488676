import {
  createConfiguration,
  DataV1Api,
  MatchV5Api,
  server1,
  ServerConfiguration,
  StatbudV1Api,
} from 'api/statbud-api';

export class StatBud {
  private static APiConfig = createConfiguration({
    baseServer: new ServerConfiguration(
      window.location.origin,
      server1.getConfiguration(),
    ),
  });

  static MatchV5 = new MatchV5Api(StatBud.APiConfig);

  static DataV1 = new DataV1Api(StatBud.APiConfig);

  static StatbudV1 = new StatbudV1Api(StatBud.APiConfig);
}
