import { useParams } from 'react-router-dom';

import {
  Avatar,
  Badge,
  Box,
  CardMedia,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
  Skeleton,
} from '@mui/material';

import {
  Ability,
  useCDragonData,
  useDDragonChampion,
  useDDragonData,
} from 'hooks';

import { SpellTooltip } from './SpellTooltip';

interface SpellProps {
  championId: string;
  name: string;
  ability: Ability;
  src: string;
  id: string;
}

export const Spell: React.FC<SpellProps> = ({
  championId,
  name,
  ability,
  src,
  id,
}) => {
  const { bin, fontConfig } = useCDragonData(championId);
  return (
    <Stack direction='row' spacing={3}>
      <Box>
        <Badge
          badgeContent={id}
          sx={{
            '& .MuiBadge-badge': {
              p: 0,
              width: '1.4em',
              height: '1.4em',
              fontSize: '1em',
              backgroundColor: 'background.paper',
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Avatar variant='rounded' alt={name} src={src} />
        </Badge>
      </Box>
      <Box flex={1}>
        <Typography variant='h5' gutterBottom>
          {name}
        </Typography>
        {championId === undefined ||
        bin === undefined ||
        fontConfig === undefined ? (
          <Skeleton sx={{ width: '100%', height: 80 }} />
        ) : (
          <Typography variant='subtitle1' color='text.secondary'>
            <SpellTooltip
              championId={championId}
              ability={ability}
              bin={bin}
              fontConfig={fontConfig}
            />
          </Typography>
        )}
      </Box>
    </Stack>
  );
};

export const Champion: React.FC = () => {
  const { championId } = useParams(),
    { version } = useDDragonData(),
    { champion } = useDDragonChampion(championId, version);

  if (championId === undefined) throw new Error('Unknown champion.');

  const { name, title, tags, lore, passive, spells } = champion ?? {};
  return (
    <Box sx={{ p: 2, overflowY: 'auto' }}>
      <CardMedia
        sx={{ height: { xs: 300, sm: 300, md: 400 } }}
        image={`https://ddragon.leagueoflegends.com/cdn/img/champion/splash/${championId}_0.jpg`}
        title={name}
      />
      <Stack
        direction='row'
        spacing={2}
        alignItems='center'
        sx={{ p: (theme) => theme.spacing(2, 1, 1) }}
      >
        <Typography variant='h5' component='div'>
          {name}
          <Typography variant='subtitle1' component='span' sx={{ pl: 2 }}>
            {title && title.charAt(0).toUpperCase() + title.slice(1)}
          </Typography>
        </Typography>
        <Box flex={1} />
        {tags &&
          tags.map((tag) => (
            <Stack key={tag} alignItems='center'>
              <Avatar
                variant='square'
                alt={tag}
                title={tag}
                src={`https://raw.communitydragon.org/13.4/plugins/rcp-fe-lol-champion-details/global/default/role-icon-${tag.toLowerCase()}.png`}
                sx={{ width: 24, height: 24 }}
              />
              <Typography variant='caption' color='text.secondary'>
                {tag}
              </Typography>
            </Stack>
          ))}
      </Stack>
      <Typography variant='body1' color='text.secondary'>
        {lore}
      </Typography>
      <Grid container spacing={2} p={2}>
        {championId && passive && spells ? (
          <>
            <Grid xs={12}>
              <Spell
                championId={championId}
                name={passive.name}
                ability={Ability.P}
                src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/passive/${passive.image.full}`}
                id='P'
              />
            </Grid>
            {spells.map(({ name, image: { full } }, i) => (
              <Grid xs={12} key={name}>
                <Spell
                  championId={championId}
                  name={name}
                  ability={i}
                  src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/spell/${full}`}
                  id={['Q', 'W', 'E', 'R'][i]}
                />
              </Grid>
            ))}
          </>
        ) : (
          Array.from(new Array(5)).map((_, i) => (
            <Grid xs={12} key={i}>
              <Skeleton sx={{ width: '100%', height: 80 }} />
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
};
