/**
 * StatBud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MatchV5ChallengesDto } from '../models/MatchV5ChallengesDto';
import { MatchV5MissionsDto } from '../models/MatchV5MissionsDto';
import { MatchV5PerksDto } from '../models/MatchV5PerksDto';
import { HttpFile } from '../http/http';

export class MatchV5ParticipantDto {
    'allInPings'?: number;
    'assistMePings'?: number;
    'assists': number;
    'baronKills': number;
    'bountyLevel': number;
    'champExperience': number;
    'champLevel': number;
    /**
    * Prior to patch 11.4, on Feb 18th, 2021, this field returned invalid championIds. We recommend determining the champion based on the championName field for matches played prior to patch 11.4.
    */
    'championId': number;
    'championName': string;
    'commandPings'?: number;
    /**
    * This field is currently only utilized for Kayn\'s transformations. (Legal values: 0 - None, 1 - Slayer, 2 - Assassin)
    */
    'championTransform': number;
    'consumablesPurchased': number;
    'challenges'?: MatchV5ChallengesDto;
    'damageDealtToBuildings'?: number;
    'damageDealtToObjectives': number;
    'damageDealtToTurrets': number;
    'dangerPings'?: number;
    'damageSelfMitigated': number;
    'deaths': number;
    'detectorWardsPlaced': number;
    'doubleKills': number;
    'dragonKills': number;
    'eligibleForProgression'?: boolean;
    'enemyMissingPings'?: number;
    'enemyVisionPings'?: number;
    'firstBloodAssist': boolean;
    'firstBloodKill': boolean;
    'firstTowerAssist': boolean;
    'firstTowerKill': boolean;
    'gameEndedInEarlySurrender': boolean;
    'gameEndedInSurrender': boolean;
    'holdPings'?: number;
    'getBackPings'?: number;
    'goldEarned': number;
    'goldSpent': number;
    /**
    * Both individualPosition and teamPosition are computed by the game server and are different versions of the most likely position played by a player. The individualPosition is the best guess for which position the player actually played in isolation of anything else. The teamPosition is the best guess for which position the player actually played if we add the constraint that each team must have one top player, one jungle, one middle, etc. Generally the recommendation is to use the teamPosition field over the individualPosition field.
    */
    'individualPosition': string;
    'inhibitorKills': number;
    'inhibitorTakedowns'?: number;
    'inhibitorsLost'?: number;
    'item0': number;
    'item1': number;
    'item2': number;
    'item3': number;
    'item4': number;
    'item5': number;
    'item6': number;
    'itemsPurchased': number;
    'killingSprees': number;
    'kills': number;
    'lane': string;
    'largestCriticalStrike': number;
    'largestKillingSpree': number;
    'largestMultiKill': number;
    'longestTimeSpentLiving': number;
    'magicDamageDealt': number;
    'magicDamageDealtToChampions': number;
    'magicDamageTaken': number;
    'missions'?: MatchV5MissionsDto;
    'neutralMinionsKilled': number;
    'needVisionPings'?: number;
    'nexusKills': number;
    'nexusTakedowns'?: number;
    'nexusLost'?: number;
    'objectivesStolen': number;
    'objectivesStolenAssists': number;
    'onMyWayPings'?: number;
    'participantId': number;
    'pentaKills': number;
    'perks': MatchV5PerksDto;
    'physicalDamageDealt': number;
    'physicalDamageDealtToChampions': number;
    'physicalDamageTaken': number;
    'placement'?: number;
    'playerAugment1'?: number;
    'playerAugment2'?: number;
    'playerAugment3'?: number;
    'playerAugment4'?: number;
    'playerSubteamId'?: number;
    'pushPings'?: number;
    'profileIcon': number;
    'puuid': string;
    'quadraKills': number;
    'riotIdGameName'?: string;
    'riotIdName'?: string;
    'riotIdTagline': string;
    'role': string;
    'sightWardsBoughtInGame': number;
    'spell1Casts': number;
    'spell2Casts': number;
    'spell3Casts': number;
    'spell4Casts': number;
    'subteamPlacement'?: number;
    'summoner1Casts': number;
    'summoner1Id': number;
    'summoner2Casts': number;
    'summoner2Id': number;
    'summonerId': string;
    'summonerLevel': number;
    'summonerName': string;
    'teamEarlySurrendered': boolean;
    'teamId': number;
    /**
    * Both individualPosition and teamPosition are computed by the game server and are different versions of the most likely position played by a player. The individualPosition is the best guess for which position the player actually played in isolation of anything else. The teamPosition is the best guess for which position the player actually played if we add the constraint that each team must have one top player, one jungle, one middle, etc. Generally the recommendation is to use the teamPosition field over the individualPosition field.
    */
    'teamPosition': string;
    'timeCCingOthers': number;
    'timePlayed': number;
    'totalAllyJungleMinionsKilled'?: number;
    'totalDamageDealt': number;
    'totalDamageDealtToChampions': number;
    'totalDamageShieldedOnTeammates': number;
    'totalDamageTaken': number;
    'totalEnemyJungleMinionsKilled'?: number;
    'totalHeal': number;
    'totalHealsOnTeammates': number;
    'totalMinionsKilled': number;
    'totalTimeCCDealt': number;
    'totalTimeSpentDead': number;
    'totalUnitsHealed': number;
    'tripleKills': number;
    'trueDamageDealt': number;
    'trueDamageDealtToChampions': number;
    'trueDamageTaken': number;
    'turretKills': number;
    'turretTakedowns'?: number;
    'turretsLost'?: number;
    'unrealKills': number;
    'visionScore': number;
    'visionClearedPings'?: number;
    'visionWardsBoughtInGame': number;
    'wardsKilled': number;
    'wardsPlaced': number;
    'win': boolean;
    'baitPings'?: number;
    'basicPings'?: number;
    'playerScore0'?: number;
    'playerScore1'?: number;
    'playerScore10'?: number;
    'playerScore11'?: number;
    'playerScore2'?: number;
    'playerScore3'?: number;
    'playerScore4'?: number;
    'playerScore5'?: number;
    'playerScore6'?: number;
    'playerScore7'?: number;
    'playerScore8'?: number;
    'playerScore9'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "allInPings",
            "baseName": "allInPings",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "assistMePings",
            "baseName": "assistMePings",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "assists",
            "baseName": "assists",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "baronKills",
            "baseName": "baronKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "bountyLevel",
            "baseName": "bountyLevel",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "champExperience",
            "baseName": "champExperience",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "champLevel",
            "baseName": "champLevel",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "championId",
            "baseName": "championId",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "championName",
            "baseName": "championName",
            "type": "string",
            "format": ""
        },
        {
            "name": "commandPings",
            "baseName": "commandPings",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "championTransform",
            "baseName": "championTransform",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "consumablesPurchased",
            "baseName": "consumablesPurchased",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "challenges",
            "baseName": "challenges",
            "type": "MatchV5ChallengesDto",
            "format": ""
        },
        {
            "name": "damageDealtToBuildings",
            "baseName": "damageDealtToBuildings",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "damageDealtToObjectives",
            "baseName": "damageDealtToObjectives",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "damageDealtToTurrets",
            "baseName": "damageDealtToTurrets",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "dangerPings",
            "baseName": "dangerPings",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "damageSelfMitigated",
            "baseName": "damageSelfMitigated",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "deaths",
            "baseName": "deaths",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "detectorWardsPlaced",
            "baseName": "detectorWardsPlaced",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "doubleKills",
            "baseName": "doubleKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "dragonKills",
            "baseName": "dragonKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "eligibleForProgression",
            "baseName": "eligibleForProgression",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "enemyMissingPings",
            "baseName": "enemyMissingPings",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "enemyVisionPings",
            "baseName": "enemyVisionPings",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "firstBloodAssist",
            "baseName": "firstBloodAssist",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "firstBloodKill",
            "baseName": "firstBloodKill",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "firstTowerAssist",
            "baseName": "firstTowerAssist",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "firstTowerKill",
            "baseName": "firstTowerKill",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "gameEndedInEarlySurrender",
            "baseName": "gameEndedInEarlySurrender",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "gameEndedInSurrender",
            "baseName": "gameEndedInSurrender",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "holdPings",
            "baseName": "holdPings",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "getBackPings",
            "baseName": "getBackPings",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "goldEarned",
            "baseName": "goldEarned",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "goldSpent",
            "baseName": "goldSpent",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "individualPosition",
            "baseName": "individualPosition",
            "type": "string",
            "format": ""
        },
        {
            "name": "inhibitorKills",
            "baseName": "inhibitorKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "inhibitorTakedowns",
            "baseName": "inhibitorTakedowns",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "inhibitorsLost",
            "baseName": "inhibitorsLost",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "item0",
            "baseName": "item0",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "item1",
            "baseName": "item1",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "item2",
            "baseName": "item2",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "item3",
            "baseName": "item3",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "item4",
            "baseName": "item4",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "item5",
            "baseName": "item5",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "item6",
            "baseName": "item6",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "itemsPurchased",
            "baseName": "itemsPurchased",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "killingSprees",
            "baseName": "killingSprees",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "kills",
            "baseName": "kills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "lane",
            "baseName": "lane",
            "type": "string",
            "format": ""
        },
        {
            "name": "largestCriticalStrike",
            "baseName": "largestCriticalStrike",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "largestKillingSpree",
            "baseName": "largestKillingSpree",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "largestMultiKill",
            "baseName": "largestMultiKill",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "longestTimeSpentLiving",
            "baseName": "longestTimeSpentLiving",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "magicDamageDealt",
            "baseName": "magicDamageDealt",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "magicDamageDealtToChampions",
            "baseName": "magicDamageDealtToChampions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "magicDamageTaken",
            "baseName": "magicDamageTaken",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "missions",
            "baseName": "missions",
            "type": "MatchV5MissionsDto",
            "format": ""
        },
        {
            "name": "neutralMinionsKilled",
            "baseName": "neutralMinionsKilled",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "needVisionPings",
            "baseName": "needVisionPings",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "nexusKills",
            "baseName": "nexusKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "nexusTakedowns",
            "baseName": "nexusTakedowns",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "nexusLost",
            "baseName": "nexusLost",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "objectivesStolen",
            "baseName": "objectivesStolen",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "objectivesStolenAssists",
            "baseName": "objectivesStolenAssists",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "onMyWayPings",
            "baseName": "onMyWayPings",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "participantId",
            "baseName": "participantId",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "pentaKills",
            "baseName": "pentaKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "perks",
            "baseName": "perks",
            "type": "MatchV5PerksDto",
            "format": ""
        },
        {
            "name": "physicalDamageDealt",
            "baseName": "physicalDamageDealt",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "physicalDamageDealtToChampions",
            "baseName": "physicalDamageDealtToChampions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "physicalDamageTaken",
            "baseName": "physicalDamageTaken",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "placement",
            "baseName": "placement",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerAugment1",
            "baseName": "playerAugment1",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerAugment2",
            "baseName": "playerAugment2",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerAugment3",
            "baseName": "playerAugment3",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerAugment4",
            "baseName": "playerAugment4",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerSubteamId",
            "baseName": "playerSubteamId",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "pushPings",
            "baseName": "pushPings",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "profileIcon",
            "baseName": "profileIcon",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "puuid",
            "baseName": "puuid",
            "type": "string",
            "format": ""
        },
        {
            "name": "quadraKills",
            "baseName": "quadraKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "riotIdGameName",
            "baseName": "riotIdGameName",
            "type": "string",
            "format": ""
        },
        {
            "name": "riotIdName",
            "baseName": "riotIdName",
            "type": "string",
            "format": ""
        },
        {
            "name": "riotIdTagline",
            "baseName": "riotIdTagline",
            "type": "string",
            "format": ""
        },
        {
            "name": "role",
            "baseName": "role",
            "type": "string",
            "format": ""
        },
        {
            "name": "sightWardsBoughtInGame",
            "baseName": "sightWardsBoughtInGame",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "spell1Casts",
            "baseName": "spell1Casts",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "spell2Casts",
            "baseName": "spell2Casts",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "spell3Casts",
            "baseName": "spell3Casts",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "spell4Casts",
            "baseName": "spell4Casts",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "subteamPlacement",
            "baseName": "subteamPlacement",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "summoner1Casts",
            "baseName": "summoner1Casts",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "summoner1Id",
            "baseName": "summoner1Id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "summoner2Casts",
            "baseName": "summoner2Casts",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "summoner2Id",
            "baseName": "summoner2Id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "summonerId",
            "baseName": "summonerId",
            "type": "string",
            "format": ""
        },
        {
            "name": "summonerLevel",
            "baseName": "summonerLevel",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "summonerName",
            "baseName": "summonerName",
            "type": "string",
            "format": ""
        },
        {
            "name": "teamEarlySurrendered",
            "baseName": "teamEarlySurrendered",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "teamId",
            "baseName": "teamId",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "teamPosition",
            "baseName": "teamPosition",
            "type": "string",
            "format": ""
        },
        {
            "name": "timeCCingOthers",
            "baseName": "timeCCingOthers",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "timePlayed",
            "baseName": "timePlayed",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "totalAllyJungleMinionsKilled",
            "baseName": "totalAllyJungleMinionsKilled",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "totalDamageDealt",
            "baseName": "totalDamageDealt",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "totalDamageDealtToChampions",
            "baseName": "totalDamageDealtToChampions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "totalDamageShieldedOnTeammates",
            "baseName": "totalDamageShieldedOnTeammates",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "totalDamageTaken",
            "baseName": "totalDamageTaken",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "totalEnemyJungleMinionsKilled",
            "baseName": "totalEnemyJungleMinionsKilled",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "totalHeal",
            "baseName": "totalHeal",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "totalHealsOnTeammates",
            "baseName": "totalHealsOnTeammates",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "totalMinionsKilled",
            "baseName": "totalMinionsKilled",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "totalTimeCCDealt",
            "baseName": "totalTimeCCDealt",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "totalTimeSpentDead",
            "baseName": "totalTimeSpentDead",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "totalUnitsHealed",
            "baseName": "totalUnitsHealed",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "tripleKills",
            "baseName": "tripleKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "trueDamageDealt",
            "baseName": "trueDamageDealt",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "trueDamageDealtToChampions",
            "baseName": "trueDamageDealtToChampions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "trueDamageTaken",
            "baseName": "trueDamageTaken",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "turretKills",
            "baseName": "turretKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "turretTakedowns",
            "baseName": "turretTakedowns",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "turretsLost",
            "baseName": "turretsLost",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "unrealKills",
            "baseName": "unrealKills",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "visionScore",
            "baseName": "visionScore",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "visionClearedPings",
            "baseName": "visionClearedPings",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "visionWardsBoughtInGame",
            "baseName": "visionWardsBoughtInGame",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "wardsKilled",
            "baseName": "wardsKilled",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "wardsPlaced",
            "baseName": "wardsPlaced",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "win",
            "baseName": "win",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "baitPings",
            "baseName": "baitPings",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "basicPings",
            "baseName": "basicPings",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore0",
            "baseName": "playerScore0",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore1",
            "baseName": "playerScore1",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore10",
            "baseName": "playerScore10",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore11",
            "baseName": "playerScore11",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore2",
            "baseName": "playerScore2",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore3",
            "baseName": "playerScore3",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore4",
            "baseName": "playerScore4",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore5",
            "baseName": "playerScore5",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore6",
            "baseName": "playerScore6",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore7",
            "baseName": "playerScore7",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore8",
            "baseName": "playerScore8",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "playerScore9",
            "baseName": "playerScore9",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return MatchV5ParticipantDto.attributeTypeMap;
    }

    public constructor() {
    }
}

